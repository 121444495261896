import moment from "moment";
import { addRecord, updateRecord, deleteRecord } from "./CommonFn";

const initialState = {
  fetchingSpinner: false,
  addRecordSuccess: false,
  addRecordSpin: false,
  editRecordSpin: false,
  editRecordSuccess: false,
  deleteRecordSuccess: false,
  deleteRecordSpin: false,
  updateSettingSpin: false,
  View_Auction_Detail_initialValue: null,
  formData: [],
  formChange: false,
  confirmationModal: false,
  menuClick: "",
  FormTemplateLoader: false,
  formDataInitialValue: null,
  refreshDashboard: 0,
  newAuctions: 0,
  closeAuctionCounter: 0,
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case "RestSomeAuctionsClosed": {
      return {
        ...state,
        closeAuctionCounter: 0,
      };
    }
    case "SomeAuctionsClosed_SUCCESS": {
      return {
        ...state,
        closeAuctionCounter: state.closeAuctionCounter + action.payload.Record,
      };
    }
    case "RestApprovalCounter": {
      return {
        ...state,
        newAuctions: 0,
      };
    }
    case "NewAuctionCreated_SUCCESS": {
      return {
        ...state,
        newAuctions: state.newAuctions + 1,
      };
    }
    case "RefreshDashboard_SUCCESS": {
      return {
        ...state,
        refreshDashboard: state.refreshDashboard + 1,
      };
    }

    case "Close_Confirmation_modal_cancel": {
      return {
        ...state,
        confirmationModal: false,
        menuClick: "",
      };
    }
    case "Close_Confirmation_modal": {
      return {
        ...state,
        confirmationModal: false,
        formChange: false,
        formData: [],
        menuClick: "",
      };
    }
    case "Open_Confirmation_modal": {
      return {
        ...state,
        confirmationModal: true,
        menuClick: action.menuClick,
      };
    }

    case "Edit_Form_Template": {
      return {
        ...state,
        formDataInitialValue: action.payload,
        formData: action.payload.formStructure,
      };
    }

    case "Clear_Form_data": {
      return {
        ...state,
        formData: [],
        formChange: false,
        formDataInitialValue: null,
      };
    }
    case "Save_Form_Data": {
      return {
        ...state,
        formData: action.payload.task_data,
        formChange: true,
      };
    }

    case "Hide_Modal": {
      return {
        ...state,
        [action.modalName]: false,
        initialValue: null,
      };
    }
    case "Show_Modal": {
      return {
        ...state,
        [action.modalName]: true,
        initialValue: action.payload,
      };
    }
    case "View_Auction_Detail_Show": {
      return {
        ...state,
        [action.modalName]: true,
        View_Auction_Detail_initialValue: action.payload,
      };
    }
    case "View_Auction_Detail_Hide": {
      return {
        ...state,
        [action.modalName]: false,
        View_Auction_Detail_initialValue: null,
      };
    }

    case "formTemplateLoader_START": {
      return {
        ...state,
        FormTemplateLoader: true,
      };
    }
    case "FetchRecord_SUCCESS": {
      return {
        ...state,
        [action.recordName]: action.payload.Record,
        fetchingSpinner: false,
        FormTemplateLoader: false,
      };
    }
    case "FetchRecord_ERROR": {
      return {
        ...state,
        fetchingSpinner: false,
      };
    }
    case "TableSpinner_START": {
      return {
        ...state,
        fetchingSpinner: true,
      };
    }
    case "AddRecord_SUCCESS": {
      let Record = addRecord(state[action.recordName], action.payload.Record);
      return {
        ...state,
        [action.recordName]: [...Record],
        addRecordSpin: false,
        addRecordSuccess: true,
      };
    }
    case "AddRecord_ERROR": {
      return {
        ...state,
        addRecordSpin: false,
      };
    }

    case "updateSetting_SUCCESS": {
      return {
        ...state,
        updateSettingSuccess: true,
        updateSettingSpin: false,
      };
    }
    case "updateSetting_Error": {
      return {
        ...state,
        updateSettingSpin: false,
      };
    }
    case "updateSettingSpin_START": {
      return {
        ...state,
        updateSettingSpin: true,
      };
    }
    case "AddFormModalSpin_START": {
      return {
        ...state,
        addRecordSpin: true,
      };
    }
    case "EditRecord_SUCCESS": {
      let Record = updateRecord(state[action.recordName], action.payload.Record);
      return {
        ...state,
        [action.recordName]: [...Record],
        editRecordSpin: false,
        editRecordSuccess: true,
      };
    }
    case "EditRecord_ERROR": {
      return {
        ...state,
        editRecordSpin: false,
      };
    }
    case "EditFormModalSpin_START": {
      return {
        ...state,
        editRecordSpin: true,
      };
    }
    case "DeleteRecord_SUCCESS": {
      let Record = deleteRecord(state[action.recordName], action.payload.Record);
      return {
        ...state,
        [action.recordName]: [...Record],
        deleteRecordSuccess: true,
        deleteRecordSpin: false,
        fetchingSpinner: false,
      };
    }
    case "DeleteRecord_Error": {
      return {
        ...state,
        deleteRecordSpin: false,
      };
    }
    case "DeleteFormModalSpin_START": {
      return {
        ...state,
        deleteRecordSpin: true,
      };
    }
    case "RESET_CRUD_STATE": {
      return {
        ...state,
        addRecordSuccess: false,
        editRecordSuccess: false,
        deleteRecordSuccess: false,
        updateSettingSuccess: false,
      };
    }
    default: {
      return state;
    }
  }
};
