import React from "react";
import { Col, Form, Input, Row, Button, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
const { Option } = Select;
const SubCategoryForm = ({ defaultFileList = [] }) => {
  const { language } = useSelector((state) => state.Language);
  const { categoryDropDown, formTemplates, FormTemplateLoader } = useSelector((state) => state.CrudR);
  const form = Form.useFormInstance();

  const renderOptions = (data) => {
    if (data && data.length > 0) {
      return data.map((Item) => {
        return <Option value={Item._id}>{Item.name}</Option>;
      });
    } else if (FormTemplateLoader) {
      return <Option value="">Loading....</Option>;
    } else {
      return <Option value="">No Template Found</Option>;
    }
  };

  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="md:pe-3">
        <Form.Item
          name={`parentId`}
          label={language["Category_title"]}
          rules={[
            {
              required: true,
              message: "Select something!",
            },
          ]}
        >
          <Select defaultValue={form.getFieldValue("parentId")}>{renderOptions(categoryDropDown)}</Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="md:pe-3">
        <Form.Item
          name={`templateId`}
          label={language["Assign_Template"]}
          rules={[
            {
              required: true,
              message: "Select something!",
            },
          ]}
        >
          <Select defaultValue={form.getFieldValue("templateId")}>{renderOptions(formTemplates)}</Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="name" label={language["Name"]} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SubCategoryForm;
