import React, { useEffect, useState } from "react";
import DetailModal from "../../../components/Modal/DetailModal";
import { Button, Form, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGetAction,
  DataRequestAction,
} from "../../../redux/actions/CommonHttp";
import ProfileImageUpload from "../../../components/Uploads/ProfileImageUpload";

const EditUserForm = ({ setPhotoState, photoList }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { Edit_User_Modal, initialValue } = useSelector((state) => state.CrudR);
  const { updateProfile, updateProfileSpin } = useSelector(
    (state) => state.AuthR
  );

  const onFinish = (values) => {
    values["profileImageUrl"] = photoList.imageUrl;
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProfile",
        values,
        "Profile_Update",
        "",
        "Profile_Spin"
      )
    );
  };
  if (updateProfile) {
    dispatch({ type: "Hide_Modal", modalName: "Edit_User_Modal" });
    dispatch({ type: "RESET_AUTH_STATE" });
  }

  useEffect(() => {
    if (initialValue) {
      setPhotoState({ imageUrl: initialValue.profileImageUrl });
    }
  }, [initialValue, Edit_User_Modal]);
  return (
    <DetailModal
      visible={Edit_User_Modal}
      modalName="Edit_User_Modal"
      modalTitle={language["Edit_Profile"]}
    >
      <hr className="text-[#D9D9D9] mt-[-2px] mb-4" />

      <Form onFinish={onFinish} initialValues={initialValue} layout="vertical">
        <div className="flex items-center gap-8">
          <div className="md:w-[800px]">
            <Form.Item
              label={language["first_name_label"]}
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={language["last_name_label"]}
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <ProfileImageUpload
            setPhotoState={setPhotoState}
            photoList={photoList}
          />
        </div>
        <div className="mt-3 flex justify-end">
          <Button
            loading={updateProfileSpin}
            disabled={updateProfileSpin}
            type="primary"
            className="font-roboto-medium"
            htmlType="submit"
          >
            {language["update"]}
          </Button>
        </div>
      </Form>
    </DetailModal>
  );
};

export default EditUserForm;
