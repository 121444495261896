import React from "react";
import { ReactFormBuilder } from "react-form-builder2";
import * as variables from "./variables";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import "react-form-builder2/dist/app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Preview from "./preview";

const FormBuilder = () => {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.CrudR);
  const handleChangeData = (data) => {
    dispatch({
      type: "Save_Form_Data",
      payload: data,
    });
  };

  let items = [
    {
      key: "Header",
    },
    {
      key: "Label",
    },
    {
      key: "Paragraph",
    },
    {
      key: "LineBreak",
    },
    {
      key: "Dropdown",
    },
    {
      key: "Checkboxes",
    },
    {
      key: "RadioButtons",
    },
    {
      key: "TextInput",
    },
    {
      key: "EmailInput",
    },
    {
      key: "NumberInput",
    },
    {
      key: "PhoneNumber",
    },
    {
      key: "TextArea",
    },
    {
      key: "Rating",
    },
    {
      key: "DatePicker",
      dateFormat: "DD.MMM.yyyy",
    },
    {
      key: "Range",
      min_value: 1,
      max_value: 10,
    },
    {
      key: "FileUpload",
    },
  ];
  console.log("==== formData ======", formData);
  return (
    <ReactFormBuilder
      data={formData}
      toolbarItems={items}
      editMode={true}
      onPost={(data) => handleChangeData(data)}
      locale="en"
    />
  );
};

export default FormBuilder;
