import React from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../components/Table";
import { useSelector } from "react-redux";
import moment from "moment";
import Action from "../../components/Action";
import ShowModal from "../../components/Modal/ShowModal";
import Widget from "../../components/Widget/index.js";
import EditEmail from "./components/EditEmail.js";
import { dateFormate } from "../../utils/commonFunction.js";

const Index = () => {
  const { language } = useSelector((state) => state.Language);
  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal
          buttonName="Edit"
          modalName="Edit_Email_Temp"
          record={record}
        />
      </div>
    );
  };

  const columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record.emailId}</span>,
      key: "userId",
    },
    {
      title: language["email_email_type"],
      render: (record) => (
        <span className="capitalize">{record.emailType}</span>
      ),
      key: "fullName",
    },
    {
      title: language["email_subject"],
      render: (record) => <span>{record.subject}</span>,
      key: "email",
    },
    {
      title: language["email_description"],
      render: (record) => (
        <p dangerouslySetInnerHTML={{ __html: record.description }} />
      ),
      key: "description",
    },
    {
      title: language["email_createdAt"],
      render: (record) => (
        <span>{dateFormate(record.createdAt)}</span>
      ),
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
    },
  ];
  return (
    <Widget>
      <TableHeader />
      <ListTable
        apiName="getEmailSetting"
        recordName="emailList"
        columns={columns}
      />
      <EditEmail />
    </Widget>
  );
};

export default Index;
