import React from "react";
const CategoryCard = ({
  imgUrl,
  text,
  className,
  onClick = null,
  background,
}) => {
  return (
    <div
      style={{
        border: "1px solid #D9D9D9",
        borderRadius: "10px",
        background: background,
      }}
      onClick={onClick}
      className={`p-2 w-[160px] capitalize`}
    >
      <div className="h-[50px] flex items-center">
        <img src={imgUrl} alt="Vector" className="mx-auto  w-[40px]" />
      </div>
      <h4 className="font-roboto-bold text-14 text-center w-[156px]">{text}</h4>
    </div>
  );
};

export default CategoryCard;
