import React from "react";
import Title from "../../../components/Title";
import Filters from "../../../components/Filters";
import { useSelector } from "react-redux";
import AddCategory from "./AddSubCategory";

const TableHeader = () => {
  const { language } = useSelector((state) => state.Language);

  return (
    <div className="mb-3 flex items-center justify-between flex-wrap">
      <Title text={language["Sub_Category_title"]} />
      <div className="flex items-center flex-wrap gap-3">
        <Filters
          placeholder={language["category_search_placeholder"]}
          dateWidth={"220px"}
          searchboxWidth={"250px"}
          apiName="getCategory"
          recordName="categoryList"
        />
        <AddCategory />
      </div>
    </div>
  );
};

export default TableHeader;
