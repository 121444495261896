import React, { useEffect, useState } from "react";
import DetailModal from "../../../components/Modal/DetailModal";
import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";
const AddAdministration = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { Add_Administration_Fee, updateSettingSpin, initialValue } = useSelector((state) => state.CrudR);

  const [form] = Form.useForm();
  const onFinish = (values) => {

    values["_id"] = initialValue && initialValue._id;
    dispatch(DataRequestAction("PUT", "updateSetting", values, "updateSetting", "updateSettingSpin", ""));
  };
  // useEffect(() => {
  //   if (!Add_Administration_Fee) {
  //     form.resetFields();
  //   }
  // }, [Add_Administration_Fee, initialValue]);

  return (
    <DetailModal
      modalName="Add_Administration_Fee"
      modalTitle={language["Add_Administration_Fee"]}
      visible={Add_Administration_Fee}
      width={"1000px"}
    >
      <hr className="text-[#D9D9D9] mt-[-2px] mb-4" />

      {!initialValue && (
        <Form onFinish={onFinish} form={form} initialValues={initialValue} layout="vertical">
          <Form.List name="administrationFee">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "min"]}
                      rules={[
                        {
                          required: true,
                          message: language["missing_min_value"],
                        },
                      ]}
                    >
                      <InputNumber className="w-full" placeholder={language["enter_min_value"]} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "max"]}
                      dependencies={[name, "min"]}
                      rules={[
                        {
                          required: true,
                          message: language["missing_max_value"],
                        },
                      ]}
                    >
                      <InputNumber className="w-full" placeholder={language["enter_max_value"]} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "tax"]}
                      rules={[
                        {
                          required: true,
                          message: language["missing_tax_value"],
                        },
                      ]}
                    >
                      <InputNumber className="w-full" placeholder={language["enter_tax_value"]} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "feeType"]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select>
                        <Select.Option value="percentage">Percentage</Select.Option>;
                        <Select.Option value="fixed">Fixed</Select.Option>;
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    className="font-roboto-medium"
                  >
                    {language["Add_field"]}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className="mt-3 flex justify-end">
            <Button
              loading={updateSettingSpin}
              disabled={updateSettingSpin}
              type="primary"
              className="font-roboto-medium"
              htmlType="submit"
            >
              {language["submit"]}
            </Button>
          </div>
        </Form>
      )}
      {initialValue && (
        <Form onFinish={onFinish} form={form} initialValues={initialValue} layout="vertical">
          <Form.List name="administrationFee">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                        flexWrap: "wrap",
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        label="Min"
                        name={[name, "min"]}
                        rules={[
                          {
                            required: true,
                            message: language["missing_min_value"],
                          },
                        ]}
                      >
                        <InputNumber className="w-full" placeholder={language["enter_min_value"]} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "max"]}
                        label="Max"
                        dependencies={[name, "min"]}
                        rules={[
                          {
                            required: true,
                            message: language["missing_max_value"],
                          },
                        ]}
                      >
                        <InputNumber className="w-full" placeholder={language["enter_max_value"]} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Fee (% / NOK)"
                        name={[name, "tax"]}
                        rules={[
                          {
                            required: true,
                            message: language["missing_tax_value"],
                          },
                        ]}
                      >
                        <InputNumber className="w-full" placeholder={language["enter_tax_value"]} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Type"
                        name={[name, "feeType"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          options={[
                            {
                              value: "percentage",
                              label: "Percentage",
                            },
                            {
                              value: "fixed",
                              label: "Fixed",
                            },
                          ]}
                          onChange={(e) => {
                            console.log("===== evalue =====", e);
                            form.setFieldValue([name, "feeType"], e);
                          }}
                          style={{
                            width: "200px",
                          }}
                        />
                      </Form.Item>
                      <span className="mt-10">
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </span>
                    </Space>
                    <hr />
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    className="font-roboto-medium"
                  >
                    {language["Add_field"]}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <div className="mt-3 flex justify-end">
            <Button
              loading={updateSettingSpin}
              disabled={updateSettingSpin}
              type="primary"
              className="font-roboto-medium"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </DetailModal>
  );
};

export default AddAdministration;
