import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

import {
  Form,
  Select,
  Typography,
  Checkbox,
  Radio,
  Input,
  InputNumber,
  Rate,
  DatePicker,
  Slider,
  Upload,
  Button,
} from "antd";

const { Title, Paragraph, Text, Link } = Typography;
const { TextArea } = Input;

export const renderStatus = (status) => {
  if (status === "inProcess") {
    return <span className="text-green-400 font-roboto-regular">In Progress</span>;
  }
  if (status === "invoiceSent") {
    return <span className="text-[#FF6F00] font-roboto-regular">Invoice Sent</span>;
  }
  if (status === "invoicePaid") {
    return <span className="text-[#9100FF] font-roboto-regular">Invoice Paid</span>;
  }
  if (status === "readyToDeliver") {
    return <span className="text-[#9100FF] font-roboto-regular">Ready To Deliver</span>;
  }
  if (status === "dealClosed") {
    return <span className="text-[#52C41A] font-roboto-regular">Ready To Deliver</span>;
  }
  if (status === "block") {
    return <span className="text-[#FF6F00] font-roboto-regular">Block</span>;
  }
  if (status === "live") {
    return <span className="text-[#52C41A] font-roboto-regular">Live</span>;
  }
  if (status === "live") {
    return <span className="text-[#52C41A] font-roboto-regular">Live</span>;
  }
};
export const manualFormReset = (initialValue, form) => {
  if (initialValue) {
    if (initialValue["title"]) {
      form.setFieldValue("title", initialValue["title"]);
    } else {
      form.setFieldValue("title", "");
    }
    if (initialValue["modelYear"]) {
      form.setFieldValue("modelYear", initialValue["modelYear"]);
    } else {
      form.setFieldValue("modelYear", "");
    }
    if (initialValue["brand"]) {
      form.setFieldValue("brand", initialValue["brand"]);
    } else {
      form.setFieldValue("brand", "");
    }
    if (initialValue["model"]) {
      form.setFieldValue("model", initialValue["model"]);
    } else {
      form.setFieldValue("model", "");
    }
    if (initialValue["cylinderVolumeInLiter"]) {
      form.setFieldValue("cylinderVolumeInLiter", initialValue["cylinderVolumeInLiter"]);
    } else {
      form.setFieldValue("cylinderVolumeInLiter", "");
    }
    if (initialValue["HorsePower"]) {
      form.setFieldValue("HorsePower", initialValue["HorsePower"]);
    } else {
      form.setFieldValue("HorsePower", "");
    }
    if (initialValue["weight"]) {
      form.setFieldValue("weight", initialValue["weight"]);
    } else {
      form.setFieldValue("weight", "");
    }
    if (initialValue["workingHours"]) {
      form.setFieldValue("workingHours", initialValue["workingHours"]);
    } else {
      form.setFieldValue("workingHours", "");
    }
    if (initialValue["frontTire"]) {
      form.setFieldValue("frontTire", initialValue["frontTire"]);
    } else {
      form.setFieldValue("frontTire", "");
    }
    if (initialValue["rearTire"]) {
      form.setFieldValue("rearTire", initialValue["rearTire"]);
    } else {
      form.setFieldValue("rearTire", "");
    }
    if (initialValue["videoUrl"]) {
      form.setFieldValue("videoUrl", initialValue["videoUrl"]);
    } else {
      form.setFieldValue("videoUrl", "");
    }
    if (initialValue["price"]) {
      form.setFieldValue("price", initialValue["price"]);
    } else {
      form.setFieldValue("price", "");
    }
    if (initialValue["description"]) {
      form.setFieldValue("description", initialValue["description"]);
    } else {
      form.setFieldValue("description", "");
    }
  }
};

export const formatAsNOK = (price) => {
  if (price) {
    // Convert the price to a string and split the integer and decimal parts
    let priceStr = price?.toString();
    let parts = priceStr?.split(".");
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? parts[1] : "00";

    // Format the integer part with spaces for thousands separator
    let formattedIntegerPart = parseFloat(integerPart).toLocaleString("nb-NO").replace(",", " ");

    // Combine the formatted integer part with the decimal part and add the NOK currency symbol
    let formattedPrice = formattedIntegerPart + "," + decimalPart;

    return formattedPrice;
  }
};

export const dateFormate = (date) => {
  return moment(date).format("DD.MMM.YYYY");
};

export const capitalize = (str) => {
  // Split the string into an array of words
  if (str) {
    let words = str.split(" ");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back into a string and return
    return words.join(" ");
  }
};

export const countryList = () => {
  return [
    "pk", //pakistan
    "no", // Norway
    "se", // Sweden
    "dk", // Denmark
    "fi", // Finland
    "pl", // Poland
    "lt", // Lithuania
    "lv", // Latvia
    "ee", // Estonia
    "is", // Iceland
    "gb", // United Kingdom
    "al", // Albania
    "ad", // Andorra
    "am", // Armenia
    "at", // Austria
    "az", // Azerbaijan
    "by", // Belarus
    "be", // Belgium
    "ba", // Bosnia and Herzegovina
    "bg", // Bulgaria
    "hr", // Croatia
    "cy", // Cyprus
    "cz", // Czech Republic
    "fr", // France
    "ge", // Georgia
    "de", // Germany
    "gr", // Greece
    "hu", // Hungary
    "ie", // Ireland
    "it", // Italy
    "li", // Liechtenstein
    "lu", // Luxembourg
    "mt", // Malta
    "md", // Moldova
    "mc", // Monaco
    "me", // Montenegro
    "nl", // Netherlands
    "pt", // Portugal
    "ro", // Romania
    "ru", // Russia
    "rs", // Serbia
    "sk", // Slovakia
    "si", // Slovenia
    "es", // Spain
    "ch", // Switzerland
    "tr", // Turkey
    "ua", // Ukraine
  ];
};

const changeTheOption = (data) => {
  let option = [];
  data.map((Item) => {
    option.push({
      label: Item.text,
      value: Item.value,
    });
  });
  return option;
};

const radioButton = (data) => {
  return data.map((Item) => {
    return <Radio value={Item.value}>{Item.text}</Radio>;
  });
};
const token = localStorage.getItem("token");

export const formElement = (data, ImageProps = { listType: "picture" }, initialValue = null, disabled = false) => {
  console.log("======= data FileUpload= switch===", data.element);
  switch (data.element) {
    case "LineBreak":
      return <hr />;
      break;
    case "Header":
      return (
        <Title level={3}>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </Title>
      );
      break;
    case "Label":
      return (
        <Title level={5}>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </Title>
      );
      break;
    case "Paragraph":
      return (
        <Paragraph>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </Paragraph>
      );
      break;
    case "Checkboxes":
      let options = changeTheOption(data.options);
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Checkbox.Group options={options} disabled={disabled} />
        </Form.Item>
      );

      break;
    case "EmailInput":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Input placeholder={data.text} type={data.element} />
        </Form.Item>
      );

      break;
    case "TextInput":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Input placeholder={data.text} disabled={disabled} />
        </Form.Item>
      );

      break;
    case "NumberInput":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <InputNumber
            min={1}
            disabled={disabled}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      );

      break;
    case "PhoneNumber":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Input placeholder={data.text} disabled={disabled} />
        </Form.Item>
      );

      break;
    case "TextArea":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <TextArea rows={6} disabled={disabled} />
        </Form.Item>
      );

      break;
    case "Ratting":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Rate disabled={disabled} />
        </Form.Item>
      );

      break;
    case "DatePicker":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <DatePicker
            format={"DD.MMM.YYYY"}
            disabled={disabled}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      );

      break;
    case "RadioButtons":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Radio.Group disabled={disabled}>{radioButton(data.options)}</Radio.Group>
        </Form.Item>
      );
      break;
    case "Range":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Slider defaultValue={data.default_value} max={data.max_value} tooltip={{ open: true }} disabled={disabled} />
        </Form.Item>
      );
      break;
    case "Dropdown":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Select className="h-[40px]" placeholder={data.label} disabled={disabled}>
            {data.options.map((Item) => {
              return <Select.Option value={Item.value}>{Item.text}</Select.Option>;
            })}
          </Select>
        </Form.Item>
      );
      break;
    case "FileUpload":
      return (
        <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
          <Upload {...ImageProps} data={{ fieldName: data.field_name }}>
            <Button icon={<UploadOutlined />} disabled={disabled}>Upload </Button>
          </Upload>
        </Form.Item>
      );
      break;

    default:
      break;
  }
};

export const convertToObjectStructure = (originalObject) => {
  const { userId, subCategoryId, categoryId, vatEnable, ...formStructure } = originalObject;

  return {
    userId,
    subCategoryId,
    categoryId,
    vatEnable,
    formStructure,
  };
};
