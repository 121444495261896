import React from "react";
import { Form } from "antd";
import EmailForm from "./EmailForm";
import { EditFormModal } from "../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";

const EditEmail = () => {
  const { language } = useSelector((state) => state.Language);
  const { initialValue } = useSelector((state) => state.CrudR);
  const [form] = Form.useForm();
  return (
    <EditFormModal
      form={form}
      modalTitle={language["email_edit_email"]}
      modalName="Edit_Email_Temp"
      apiName="editEmailSetting"
      recordName="emailList"
      initialData={initialValue}
    >
      <EmailForm />
    </EditFormModal>
  );
};

export default EditEmail;
