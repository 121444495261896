import React, { useEffect, useState } from "react";
import { ReactFormGenerator } from "react-form-builder2";

import { Button, Modal, Col, Row, message, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";
import { useNavigate } from "react-router";

const Preview = ({ form, editMode = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { formData, addRecordSuccess, confirmationModal, menuClick, formDataInitialValue, editRecordSuccess } = useSelector(
    (state) => state.CrudR
  );
  const { language } = useSelector((state) => state.Language);
  const [messageApi, contextHolder] = message.useMessage();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    let title = form.getFieldValue("title");
    if (!title) {
      form.validateFields();
    }

    if (formData && formData.length === 0) {
      messageApi.open({
        type: "error",
        content: "Please make some template before saving data",
      });
      return;
    }

    let requireField = ["Minimumspris", "Bilder", "Hvor lang auksjonstid ønsker du?", "Dokumenter", "Startpris"];

    formData.forEach(function (element) {
      // Check if label is "Minimumspris"
      if (element && element.label && element.label.trim() === "Minimumspris") {
        // Change field_name to "price"
        element.field_name = "price";
        requireField = requireField.filter((element) => element !== "Minimumspris");
      }

      // Check if label is "Bilder"
      if (element && element.label && element.label.trim() === "Bilder") {
        // Change field_name to "auctionImages"
        element.field_name = "auctionImages";
        requireField = requireField.filter((element) => element !== "Bilder");
      }
      // Check if label is "Bilder"
      if (element && element.label && element.label.trim() === "Hvor lang auksjonstid ønsker du?") {
        // Change field_name to "auctionImages"
        element.field_name = "duration";
        requireField = requireField.filter((element) => element !== "Hvor lang auksjonstid ønsker du?");
      }
      // Check if label is "Bilder"
      if (element && element.label && element.label.trim() === "Dokumenter") {
        // Change field_name to "auctionImages"
        element.field_name = "auctionDocument";
        requireField = requireField.filter((element) => element !== "Dokumenter");
      }
      if (element && element.label && element.label.trim() === "Startpris") {
        // Change field_name to "auctionImages"
        element.field_name = "startPrice";
        requireField = requireField.filter((element) => element !== "Startpris");
      }
    });

    if (requireField && requireField.length > 0) {
      console.log("==== requireField =====", requireField);
      messageApi.open({
        type: "error",
        content: `Please make sure you add all required field before saving data, ${requireField.toString()} filed's missing`,
      });
      return;
    }

    if (editMode) {
       dispatch(
        DataRequestAction(
          "PUT",
          "editFormTemplate",
          { formStructure: formData, name: title, _id: formDataInitialValue._id },
          "EditRecord",
          "templateList",
          "AddingRecord"
        )
      );
    } else {
      dispatch(
        DataRequestAction(
          "POST",
          "addFormTemplate",
          { formStructure: formData, name: title },
          "AddRecord",
          "templateList",
          "AddingRecord"
        )
      );
    }
  };

  if (addRecordSuccess || editRecordSuccess) {
    dispatch({
      type: "RESET_CRUD_STATE",
    });
    dispatch({
      type: "Clear_Form_data",
    });
    navigate("/formTemplate");
  }

  const okayClick = () => {
    navigate(menuClick);
    dispatch({
      type: "Close_Confirmation_modal",
    });
  };
  const cancelClick = () => {
    dispatch({
      type: "Close_Confirmation_modal_cancel",
    });
  };

  return (
    <Row>
      {contextHolder}
      <Col xl={24} xs={24} lg={24} md={24}>
        <Button
          type="primary"
          className="float-right"
          style={{ marginRight: "10px" }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>

        <Button
          type="primary"
          className="float-right"
          style={{ marginRight: "10px" }}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Preview
        </Button>
      </Col>

      <Modal title="Template Preview" open={isModalOpen} onCancel={handleOk} closable={true} footer={null}>
        <ReactFormGenerator hide_actions={true} data={formData} />
      </Modal>
      <Modal
        title="Confirmation"
        open={confirmationModal}
        onOk={okayClick}
        onCancel={cancelClick}
        okText="Okay"
        cancelText="Cancel"
      >
        <p>Change are not saved. if you proceed then changes will be lost!</p>
      </Modal>
    </Row>
  );
};

export default Preview;
