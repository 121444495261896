import React from "react";
import AuthLogo from "../../assets/images/Logo.png";
const AuthLayout = ({ children, heading, content }) => {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div
        style={{ boxShadow: "0px 4px 50px 0px #0000001A" }}
        className="md:w-[400px] bg-[#F8F8FA] p-10 rounded-lg"
      >
        <div className="flex justify-center flex-col items-center">
          <img src={AuthLogo} alt="Auth_logo" className="mb-3" />
          <h4 className="my-0 text-[30px] ">{heading}</h4>
          <p className="mt-2 text-zinc-600 text-center">{content}</p>
        </div>
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
