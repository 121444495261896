import { Col, DatePicker, Form, Input, Radio, Row, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import CategoryCard from "../categoryCard/categoryCard";
import { capitalize, formElement } from "../../utils/commonFunction";

const SingleApprovalAuctionForm = ({ readOnly = false, setCategoryValue, CategoryValue, setFormTemplate }) => {
  const form = Form.useFormInstance();
  const token = localStorage.getItem("token");

  const [FormDataValue, setFormData] = useState([]);

  const [ImageProps, setImageProps] = useState({
    defaultProps: {
      action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
      method: "POST",
      listType: "picture",
      data: {
        fieldName: "auctionImages",
      },
      headers: {
        Authorization: token,
      },
    },
  });

  const { language } = useSelector((state) => state.Language);
  const { categoryList, subCategory, dropDownUserList, View_Pending_Auction_Modal, initialValue } = useSelector(
    (state) => state.CrudR
  );

  const setInitialValue = () => {
    console.log("===== === Function Calling === ==", View_Pending_Auction_Modal, initialValue);
    if (View_Pending_Auction_Modal && initialValue) {
      const { formStructure, formTemplate } = initialValue;
      const filterUpload = formTemplate.filter((x) => x.element === "FileUpload");

      if (filterUpload && filterUpload.length > 0) {
        let Obj = null;
        filterUpload.map((Item) => {
          if (Obj) {
            Obj[Item.field_name] = {
              ...ImageProps.defaultProps,
              defaultFileList: formStructure[Item.field_name],
            };
          } else {
            Obj = {
              [Item.field_name]: {
                ...ImageProps.defaultProps,
                defaultFileList: formStructure[Item.field_name],
              },
            };
          }
        });
        console.log("======== Item.field_name obj==", Obj);

        setImageProps({ defaultProps: ImageProps.defaultProps, ...Obj });
      }

      setFormData(initialValue?.formTemplate);
      form.setFieldsValue(initialValue?.formStructure);
      form.setFieldValue("categoryId", initialValue?.categoryId);
      form.setFieldValue("subCategoryId", initialValue?.subCategoryId);
      form.setFieldValue("userId", initialValue?.userId);
      form.setFieldValue("vatEnable", initialValue?.vatEnable);
    }
  };

  useEffect(setInitialValue, [View_Pending_Auction_Modal]);

  const renderCategory = () => {
    if (categoryList && categoryList.length > 0) {
      return categoryList.map((item, index) => {
        return (
          <CategoryCard
            imgUrl={item.categoryImage}
            background={CategoryValue === item._id ? "#14526270" : ""}
            text={item.name}
          />
        );
      });
    }
  };
  const renderOption = () => {
    if (dropDownUserList && dropDownUserList.length > 0) {
      return dropDownUserList.map((item) => {
        // Truncate the name and company name to a maximum of 15 characters
        const truncatedName = item?.name?.length > 15 ? item?.name?.slice(0, 15) + "..." : item?.name;
        const truncatedCompanyName =
          item?.companyName?.length > 15 ? item?.companyName?.slice(0, 15) + "..." : item?.companyName;

        return (
          <Select.Option key={item._id} value={item._id}>
            <span className="flex flex-row gap-10 items-start">
              <span>{item.userId}</span>
              <span className="text-ellipsis">{capitalize(truncatedName)}</span>
              <span className="flex flex-row gap-10">
                <span className="text-ellipsis">{item.email}</span>
                {item.companyName && (
                  <span className="flex text-ellipsis overflow-hidden">{capitalize(truncatedCompanyName)}</span>
                )}
              </span>
            </span>
            <span className="hidden">
              {item.userId}-{item.name}-{item.email}-{item.companyName}
            </span>
          </Select.Option>
        );
      });
    }
  };

  const renderSubCategory = () => {
    if (subCategory && subCategory.length > 0) {
      return subCategory.map((Item) => {
        return <Select.Option value={Item._id}>{capitalize(Item.name)}</Select.Option>;
      });
    }
  };

  const renderForm = () => {
    if (FormDataValue && FormDataValue.length > 0) {
      let ImageObj = { ...ImageProps.defaultProps };
      return FormDataValue.map((Item) => {
        console.log("===== Data Field ===", Item.field_name, Item.label);
        if (ImageProps?.[Item.field_name] && Item.element === "FileUpload") {
          ImageObj = { ...ImageProps[Item.field_name] };
        }

        return formElement(Item, { ...ImageObj }, null, true);
      });
    }
  };

  const filterOption = (input, option) => {
    let filterValue = "";
    if (option.children && option.children.length > 0) {
      filterValue = option.children[1].props.children.join();
    }
    return filterValue.toLowerCase().includes(input.toLowerCase());
  };
  return (
    <Row className="my-3 gap-y-4">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="userId"
          label={readOnly ? language["auction_auction_User"] : language["auction_auction_select_User"]}
          rules={[{ required: true }]}
        >
          <Select
            className="h-[40px]"
            disabled={readOnly}
            placeholder={language["auction_auction_select_User"]}
            allowClear={true}
            showSearch={true}
            filterOption={filterOption}
          >
            {renderOption()}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <div className="flex items-center flex-wrap gap-3">{renderCategory()}</div>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="subCategoryId" label={language["Sub_Category_title"]} rules={[{ required: true }]}>
          <Select
            className="h-[40px]"
            disabled={readOnly}
            placeholder={language["sub_category_dropdown_placeholder"]}
            onChange={(data) => {
              let formData = subCategory.filter((x) => x._id === data);
              setFormData(formData[0].formStructure);
              setFormTemplate(formData[0].formStructure);
            }}
          >
            {renderSubCategory()}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        {renderForm()}
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="vatEnable" label={language["auction_Vat_Enable"]} valuePropName="checked">
          <Switch disabled={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="showInProfileAdd" label={language["showInProfileAdd"]} valuePropName="checked">
          <Switch disabled={readOnly} />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default SingleApprovalAuctionForm;
