import React, { useEffect, useRef, useState } from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../../components/Table";
import { useSelector } from "react-redux";
import moment from "moment";
import Action from "../../../components/Action";
import ShowModal from "../../../components/Modal/ShowModal";
import DeleteModal from "../../../components/Modal/DeleteModal";
import EditUser from "./components/EditUser";
import ResetPassword from "./components/ResetPassword";
import ViewUser from "./components/ViewUser";
import Widget from "../../../components/Widget";
import ShowAuctions from "./components/ShowAuctions";
import AuctionApprovel from "./components/AuctionApprovel";
import { dateFormate } from "../../../utils/commonFunction";

const Index = () => {
  const [open, setOpen] = useState(false);

  const targetRef = useRef();
  const { language } = useSelector((state) => state.Language);
  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal
          modalName="View_Active_User_Modal"
          record={record}
          buttonName={language["user_active_view"]}
        />
        <ShowModal
          modalName={"Edit_User_Modal"}
          record={record}
          buttonName={language["user_active_edit"]}
        />
        <DeleteModal
          title={language["user_active_blockModel_title"]}
          content={language["user_active_blockModel_content"]}
          apiName="updateStatus"
          method="PUT"
          recordName="userList"
          buttonName={language["user_active_block_button"]}
          selectedItem={{ _id: record._id, status: "block" }}
        />
        <ShowModal
          record={record}
          modalName={"Reset_Active_User_Modal"}
          buttonName={language["user_active_resetPassword"]}
        />
      </div>
    );
  };

  const columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record.userId}</span>,
      key: "Id",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.userId - b.userId,
    },
    {
      title: language["user_active_fullName"],
      render: (record) => (
        <span className="capitalize">{record?.firstName && `${record?.firstName} ${record.lastName}` || "----"}</span>
      ),
      key: "fullName",
    },
    {
      title: language["user_active_email"],
      render: (record) => <span>{record?.email||"-----"}</span>,
      key: "email",
    },
    {
      title: language["user_active_phone"],
      render: (record) => <span>{record.phoneNumber}</span>,
      key: "phone",
    },
    {
      title: language["user_active_noOfAuctions"],
      render: (record) =>
        record.totalAuctions ? (
          <ShowModal modalName="View_User_Auction" record={record}>
            <span className="cursor-pointer underline text-primary-text">
              {record.totalAuctions}
            </span>
          </ShowModal>
        ) : (
          0
        ),
      key: "noOfAuctions",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.totalAuctions - b.totalAuctions,
    },
    {
      title: language["user_acceptedAt"],
      render: (record) => (
        <span>{record.acceptedAt ? dateFormate(record.acceptedAt):'---'}</span>
      ),
      key: "acceptedAt",
    },
    {
      title: language["user_active_createdAt"],
      render: (record) => (
        <span>{dateFormate(record.createdAt)}</span>
      ),
      key: "createdAt",
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      title: language["user_active_lastLogin"],
      render: (record) => (
        <span>
          {record.lastLogin
            ? moment(record.lastLogin).format("DD MMM, YYYY")
            : "----"}
        </span>
      ),
      key: "lastLogin",
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.lastLogin) - new Date(b.lastLogin)
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
    },
  ];
  const allColumnKeys = [
    "Id",
    "fullName",
    "email",
    "phone",
    "noOfAuctions",
    "createdAt",
    "lastLogin",
    "actions",
    "acceptedAt"
  ];
  const [selectedColumnKeys, setSelectedColumnKeys] = useState(allColumnKeys);
  const [checkedList, setCheckedList] = useState(allColumnKeys);
  const [tableColumns, setTableColumns] = useState(columns);
  function isChecked(value) {
    return checkedList.includes(value);
  }
  function handleSelection(key) {
    const tempList = [...checkedList];
    if (tempList.includes(key)) {
      const index = tempList.indexOf(key);
      tempList.splice(index, 1);
    } else {
      tempList.push(key);
    }
    setCheckedList(tempList);
  }
  function handleConfirmation() {
    setSelectedColumnKeys(checkedList);
  }
  useEffect(() => {
    if (selectedColumnKeys) {
      if (selectedColumnKeys.length) {
        const temp = columns.filter((f) => selectedColumnKeys.includes(f.key));
        setTableColumns(temp);
      } else {
        setTableColumns(columns);
      }
    }
    return () => {};
  }, [selectedColumnKeys]);
  const checkBoxData = columns.map((m) => ({ key: m.key, title: m.title }));
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <Widget height="h-100%">
      <TableHeader
        targetRef={targetRef}
        handleOpenChange={handleOpenChange}
        hide={hide}
        checkBoxData={checkBoxData}
        open={open}
        isChecked={isChecked}
        handleSelection={handleSelection}
        handleConfirmation={handleConfirmation}
      />
      <div ref={targetRef}>
        <ListTable
          customQuery={{ status: "active" }}
          apiName="getUsers"
          recordName="userList"
          columns={tableColumns}
        />
      </div>
      <EditUser />
      <ResetPassword />
      <ViewUser />
      <ShowAuctions />
      <AuctionApprovel />
    </Widget>
  );
};

export default Index;
