import React, { useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Popover } from "antd";
const Action = ({ children }) => {
  const [open, setOpen] = useState(false);
  const content = <div onClick={() => setOpen(false)}>{children}</div>;
  return (
    <Popover
      content={content}
      style={{ border: "1px solid #000" }}
      trigger="click"
      placement="left"
      open={open}
      onOpenChange={(e) => setOpen(e)}
    >
      <MoreOutlined className="cursor-pointer" style={{ fontSize: "30px" }} />
    </Popover>
  );
};

export default Action;
