import React, { useEffect, useState } from "react";
import ApprovalAuctionForm from "./ApprovalAuctionForm";
import { Form } from "antd";
import { useSelector } from "react-redux";
import DynamicFormModal from "../../../../components/Modal/DynamicFormModal";

const AddAuction = () => {
  const { language } = useSelector((state) => state.Language);
  const [form] = Form.useForm();
  const { Add_ForApproval_Modal } = useSelector((state) => state.CrudR);
  const [CategoryValue, setCategoryValue] = useState(null);
  const [taxRate, setTaxRate] = useState(false);
  const [formTemplate, setFormTemplate] = useState([]);
  useEffect(() => {
    if (!Add_ForApproval_Modal) {
      setCategoryValue(null);
      setTaxRate(false);
    }

  }, [Add_ForApproval_Modal]);
 



  return (
    <DynamicFormModal
      width={"1000px"}
      form={form}
      addButtonName={language["auction_view_add_auction"]}
      modalTitle={language["auction_view_add_auction"]}
      modalName={"Add_ForApproval_Modal"}
      apiName={"addAuction"}
      recordName={"forApprovalAuctionList"}
      extraObj={{ categoryId: CategoryValue, formTemplate: formTemplate }}
      requestType="DeleteRecord"
      loaderCaseName="DeleteFormModalSpin"
      spinnerName="deleteRecordSpin"
      showNotification={true}
    >
      <ApprovalAuctionForm
        setFormTemplate={setFormTemplate}
        setCategoryValue={setCategoryValue}
        CategoryValue={CategoryValue}
        setTaxRate={setTaxRate}
        taxRate={taxRate}
      />
    </DynamicFormModal>
  );
};

export default AddAuction;
