import { Form, Input, Select } from "antd";
import React from "react";
import ReactQuil from "../../../components/reactQuil";
import { useSelector } from "react-redux";

const EmailForm = () => {
  const { language } = useSelector((state) => state.Language);

  return (
    <>
      <Form.Item
        name="emailType"
        label={language["email_form_email_type_label"]}
        rules={[{ required: true }]}
      >
        <Select placeholder={language["email_form_email_type_placeholder"]}>
          <Select.Option value="welcome email">{language['Welcome_Email']}</Select.Option>
          <Select.Option value="forgot password">{language['Forgot_Password']}</Select.Option>
          <Select.Option value="reset password">{language['Reset_Password']}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="subject"
        label={language["email_form_email_subject_label"]}
        rules={[{ required: true }]}
      >
        <Input placeholder={language["email_form_email_subject_placeholder"]} />
      </Form.Item>
      <ReactQuil
        Form={Form}
        labelName={language["email_form_email_body_label"]}
        name="description"
      />
    </>
  );
};

export default EmailForm;
