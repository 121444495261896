import EngLanguage from "../languages/eng.json";
const initialState = {
  language: EngLanguage,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "Language_Change": {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};
