import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Input } from "antd";
import { EditFormModal } from "../../../../components/Modal/EditFormModal";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const { initialValue, Reset_Active_User_Modal } = useSelector(
    (state) => state.CrudR
  );
  useEffect(() => {
    if (Reset_Active_User_Modal) {
      form.resetFields();
    }
  }, [Reset_Active_User_Modal]);
  return (
    <EditFormModal
      initialData={initialValue}
      form={form}
      modalTitle={language["user_active_reset_modaltitle"]}
      modalName="Reset_Active_User_Modal"
      apiName="resetPassword"
      recordName="userList"
    >
      <Form.Item
        label={language["user_active_reset_password"]}
        name="password"
        rules={[{ required: true }]}
      >
        <Input.Password
          placeholder={language["user_active_reset_password_placeholder"]}
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label={language["user_active_reset_confirm_password"]}
        dependencies={["password"]}
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  language["user_active_reset_confirm_password_rematch_error"]
                )
              );
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={
            language["user_active_reset_confirm_password_placeholder"]
          }
        />
      </Form.Item>
    </EditFormModal>
  );
};

export default ResetPassword;
