import React, { useEffect, useState } from "react";
import DetailModal from "../../../components/Modal/DetailModal";
import { Button, Form, Input, InputNumber, Space, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";
const AddVATFee = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { Add_Default_Fee_Modal, updateSettingSpin, initialValue } = useSelector((state) => state.CrudR);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    let obj = {
      defaultFee: {
        ...values,
      },
    };

    if (initialValue && initialValue?._id) {
      obj["_id"] = initialValue?._id;
    }
    dispatch(
      DataRequestAction("PUT", "updateSetting", obj, "updateSetting", "updateSettingSpin", "", "Add_Default_Fee_Modal")
    );
  };

  useEffect(() => {
    if (Add_Default_Fee_Modal && initialValue?.defaultFee) {
      form.setFieldsValue(initialValue?.defaultFee);
    }
  }, [Add_Default_Fee_Modal]);

  return (
    <DetailModal
      width={"800px"}
      modalName="Add_Default_Fee_Modal"
      modalTitle={language["default_fee"]}
      visible={Add_Default_Fee_Modal}
    >
      <hr className="text-[#D9D9D9] mt-[-2px] mb-4" />

      <Form onFinish={onFinish} form={form} initialValues={initialValue} layout="vertical">
        <Form.Item
          label={`${language["default_Increment"]}`}
          name={"defaultIncrement"}
          rules={[
            {
              required: true,
              // message: language["missing_min_value"],
            },
            {
              validator: async (_, value) => {
                if (value <= 0) {
                  return Promise.reject(`${language["default_Increment"]} must be greater than 0`);
                }
              },
            },
          ]}
        >
          <InputNumber className="w-full" placeholder={language["default_Increment"]} />
        </Form.Item>

        <Form.Item
          name={"defaultAuctionFee"}
          label={`${language["default_AuctionFee"]}`}
          rules={[
            {
              required: true,
              // message: language["missing_max_value"],
            },
          ]}
        >
          <InputNumber className="w-full" placeholder={language["default_AuctionFee"]} />
        </Form.Item>

        <Form.Item
          name={"defaultAuctionCommission"}
          label={`${language["default_AuctionCommission"]}`}
          rules={[
            {
              required: true,
              // message: language["missing_tax_value"],
            },
          ]}
        >
          <InputNumber className="w-full" placeholder={language["default_AuctionCommission"]} />
        </Form.Item>

        <div className="mt-3 flex justify-end">
          <Button
            loading={updateSettingSpin}
            disabled={updateSettingSpin}
            type="primary"
            className="font-roboto-medium"
            htmlType="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </DetailModal>
  );
};

export default AddVATFee;
