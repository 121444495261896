import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { EditFormModal } from "../../../components/Modal/EditFormModal";
import CategoryForm from "./CategoryForm";

const EditCategory = () => {
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const { initialValue, Edit_Category_Modal } = useSelector((state) => state.CrudR);

  console.log("===== initialValue", initialValue);
  useEffect(() => {
    if (!Edit_Category_Modal) {
      form.resetFields();
    }
  }, [Edit_Category_Modal, initialValue]);

  const getImageName = () => {
    if (initialValue) {
      const lastIndex = initialValue.categoryImage.lastIndexOf("/");
      const imageName = initialValue.categoryImage.substring(lastIndex + 1);
      return imageName;
    }
  };

  return (
    <EditFormModal
      initialData={initialValue}
      form={form}
      modalTitle={language["category_edit_title"]}
      modalName="Edit_Category_Modal"
      apiName="editCategory"
      recordName="categoryList"
      width={"700px"}
    >
      <CategoryForm
        defaultFileList={[
          {
            uid: "1",
            name: getImageName(),
            status: "done",
            url: initialValue && initialValue.categoryImage,
          },
        ]}
      />
    </EditFormModal>
  );
};

export default EditCategory;
