import React from "react";
import { Col, Form, Input, Row, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";

const CategoryForm = ({ defaultFileList = [] }) => {
  const { language } = useSelector((state) => state.Language);
  const form = Form.useFormInstance();
  const token = localStorage.getItem("token");

  const handleChange = (info) => {
    if (info.file.status === "done") {
      form.setFieldValue("categoryImage", info.file.response.url);
    }
  };

  const ImageProps = {
    action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
    method: "POST",
    headers: {
      Authorization: token,
    },
    onChange: handleChange,
  };

  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="name" label={language["Name"]} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item name="categoryImage" label={language["CategoryImage"]} rules={[{ required: true }]}>
          <Upload listType="picture" maxCount={1} {...ImageProps} defaultFileList={defaultFileList}>
            <Button icon={<UploadOutlined />}>{language["CategoryImage"]}</Button>
          </Upload>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CategoryForm;
