import React, { useEffect, useState } from "react";
import DetailModal from "./Modal/DetailModal";
import { useDispatch, useSelector } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  FileTextOutlined,
  SendOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  CarOutlined,
} from "@ant-design/icons";
import { Empty, Input, Spin } from "antd";
import { DataGetAction, DataRequestAction } from "../redux/actions/CommonHttp";
import moment from "moment";
const ViewNotes = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);

  const [notesMessage, setNoteMessage] = useState("");
  const { initialValue, View_Common_Notes, notesList, fetchingSpinner, addRecordSuccess } = useSelector(
    (state) => state.CrudR
  );

  const renderStatusIcon = (status) => {
    if (status === "dealClosed") {
      return <CheckOutlined className="mt-2" />;
    }
    if (status === "readyToDeliver") {
      return <CarOutlined className="mt-2" />;
    }
    if (status === "invoicePaid") {
      return <DollarOutlined className="mt-2" />;
    }
    if (status === "invoiceSent") {
      return <FileTextOutlined className="mt-2" />;
    }
    if (status === "inProcess") {
      return <ClockCircleOutlined className="mt-2" />;
    }
    if (status === "general") {
      return <FileTextOutlined className="mt-2" />;
    }
  };
  const renderStatusIconBg = (status) => {
    if (status === "dealClosed") {
      return "#52C41A";
    }
    if (status === "readyToDeliver") {
      return "#4458DA";
    }
    if (status === "invoicePaid") {
      return "#9100FF";
    }
    if (status === "invoiceSent") {
      return "#FF6F00";
    }
    if (status === "inProcess") {
      return "#03C25E";
    }
    if (status === "general") {
      return "#03C25E";
    }
  };
  const renderStatusContainerBg = (status) => {
    if (status === "dealClosed") {
      return "#E5FFD8";
    }
    if (status === "readyToDeliver") {
      return "#E6E9FF";
    }
    if (status === "invoicePaid") {
      return "#F1E1FE";
    }
    if (status === "invoiceSent") {
      return "#FFEAD9";
    }
    if (status === "inProcess") {
      return "#E5FFD8";
    }
    if (status === "general") {
      return "#E5FFD8";
    }
  };
  const renderHeading = (status) => {
    if (status === "dealClosed") {
      return "Deal Closed";
    }
    if (status === "readyToDeliver") {
      return "Ready To Deliver";
    }
    if (status === "invoicePaid") {
      return "Invoice Paid";
    }
    if (status === "invoiceSent") {
      return "Invoice Sent";
    }
    if (status === "inProcess") {
      return "In Process";
    }
    if (status === "general") {
      return "General";
    }
  };
  const renderNotes = () => {
    if (notesList && notesList.length > 0) {
      return notesList.map((item) => {
        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: renderStatusContainerBg(item.type),
              color: renderStatusIconBg(item.type),
            }}
            contentArrowStyle={{ borderRight: "7px solid  #D8FEEA" }}
            date={
              <span className="text-[#676767] mx-3 font-roboto-medium">
                {moment(item.createdAt).format("DD MMM, YYYY")}
              </span>
            }
            iconStyle={{
              background: renderStatusIconBg(item.type),
              color: "#fff",
            }}
            icon={<div className="h-full w-full flex items-center justify-center">{renderStatusIcon(item.type)}</div>}
          >
            <p className="text-black  font-roboto-medium" style={{ marginTop: "-10px" }}>
              {renderHeading(item.type)}
            
            </p>
            <p className="text-black mt-0 font-roboto-regular">{item.text}</p>
            {item.createdBy && <p className="flex flex-row justify-end text-[#676767]">{item.createdBy}</p>}
          </VerticalTimelineElement>
        );
      });
    }
  };

  const renderNoteTimeLine = () => {
    if (notesList && notesList.length > 0) {
      return (
        <div style={{ height: "500px", overflowY: "auto" }}>
          <VerticalTimeline lineColor="#00000029">{renderNotes()}</VerticalTimeline>
        </div>
      );
    } else {
      return <Empty />;
    }
  };

  useEffect(() => {
    if (initialValue) {
      dispatch(DataGetAction("getNotes", { _id: initialValue._id }, "FetchRecord", "notesList", "", ""));
    }
  }, [View_Common_Notes]);
  const saveNotes = () => {
    if (notesMessage && notesMessage.length > 0 && initialValue) {
      dispatch(
        DataRequestAction(
          "POST",
          "addNote",
          { text: notesMessage, _id: initialValue._id },
          "FetchRecord",
          "notesList",
          "TableSpinner",
          "",
          "",
          language
        )
      );
      setNoteMessage(null);
    }
  };

  if (addRecordSuccess) {
    setNoteMessage("");
    dispatch({ type: "RESET_CRUD_STATE" });
  }

  return (
    <DetailModal modalName="View_Common_Notes" visible={View_Common_Notes} modalTitle="Notes" width="800px">
      {renderNoteTimeLine()}
      <div className="flex items-center gap-3 mt-5">
        <Input
          value={notesMessage}
          onChange={(e) => {
            console.log(e.target.value);
            setNoteMessage(e.target.value);
          }}
          placeholder="Type note here..."
          className="w-full"
        />
        {fetchingSpinner && (
          <Spin size="small">
            <SendOutlined />
          </Spin>
        )}
        {!fetchingSpinner && (
          <SendOutlined
            onClick={() => {
              saveNotes();
            }}
          />
        )}
      </div>
    </DetailModal>
  );
};

export default ViewNotes;
