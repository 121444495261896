import React, { useEffect } from "react";
import { Col, Form, Row, Input } from "antd";
import FormBuilder from "./components/formBuilder";
import Widget from "../../components/Widget";
import { useSelector } from "react-redux";
import Preview from "./components/preview";

const EditTemplate = () => {
  const { language } = useSelector((state) => state.Language);
  const { formDataInitialValue } = useSelector((state) => state.CrudR);
  const [form] = Form.useForm();

  const serInitialValue = () => {
    form.setFieldsValue(formDataInitialValue);
  };

  useEffect(serInitialValue, [formDataInitialValue]);

  return (
    <Widget>
      <div className="flex items-center justify-between pb-4 mb-4" style={{ borderBottom: "1px solid #DBDBDB" }}>
        <h4 className="text-primary-text my-0 text-20 font-roboto-bold">{language["New_Template"]}</h4>
        <div>
          <Preview form={form} editMode={true}/>
        </div>
      </div>
      <Row>
        <Col xl={18} xs={18} lg={18} md={18}>
          <Form form={form}>
            <Form.Item name="title" label={language["TemplateName"]} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col xl={24} xs={24} lg={24} md={24}>
          <FormBuilder />
        </Col>
      </Row>
    </Widget>
  );
};

export default EditTemplate;
