import { Button, Form, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import { convertToObjectStructure } from "../../utils/commonFunction";

const DynamicFormModal = ({
  form,
  children,
  modalTitle,
  cancelButtonName = "Cancel",
  submitButtonName = "Submit",
  addButtonName,
  method = "POST",
  loaderCaseName = "AddFormModalSpin",
  apiName,
  modalName,
  requestType = "AddRecord",
  recordName,
  extraObj,
  width,
  spinnerName,
  showNotification = false,
  showButton = true,
}) => {
  const dispatch = useDispatch();
  const crudProps = useSelector((state) => state.CrudR);
  
  const onSubmit = (value) => {
  
    if (value && value.phoneNumber) {
      value["phoneNumber"] = `+${value.phoneNumber}`;
    }
    let obj = value;

    let transformData = convertToObjectStructure(obj);
    if (extraObj) {
      transformData = { ...transformData, ...extraObj };
    }
    dispatch(
      DataRequestAction(
        method,
        apiName,
        transformData,
        requestType,
        recordName,
        loaderCaseName,
        modalName,
        showNotification
      )
    );
  };
  const closeModal = () => {
    form.resetFields();
    dispatch({ type: "Hide_Modal", payload: null, modalName: modalName });
    
  };

  useEffect(() => {
    if (!crudProps[modalName]) {
      form.resetFields();
    }
  }, [crudProps[modalName]]);
  return (
    <>
      {showButton && (
        <Button type="primary" onClick={() => dispatch({ type: "Show_Modal", modalName: modalName })}>
          {addButtonName}
        </Button>
      )}
      <Modal
        onCancel={() => closeModal()}
        title={modalTitle}
        open={crudProps[modalName]}
        footer={null}
        width={width}
        centered
      >
        <hr className="text-[#D9D9D9] m-0" />

        <Form onFinish={onSubmit} form={form} layout="vertical">
          {children}
          <div className="flex justify-end gap-3 pt-4" style={{ borderTop: "1px solid #DEDEDE" }}>
            <Button className="font-roboto-medium" onClick={() => closeModal()}>
              {cancelButtonName}
            </Button>
            <Button
              loading={spinnerName ? crudProps[spinnerName] : crudProps["addRecordSpin"]}
              disabled={spinnerName ? crudProps[spinnerName] : crudProps["addRecordSpin"]}
              type="primary"
              htmlType="submit"
              className="font-roboto-medium"
            >
              {submitButtonName}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DynamicFormModal;
