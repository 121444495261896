import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
const { confirm } = Modal;
const DeleteModal = ({
  buttonName = "Delete",
  title,
  content,
  okText = "Yes",
  cancelText = "No",
  apiName,
  requestType = "DeleteRecord",
  selectedItem,
  recordName,
  method = "Delete",
  className,
}) => {
  const ROOT_URL = process.env.REACT_APP_ROOT_URL;
  const dispatch = useDispatch();
  const crudRProps = useSelector((state) => state.CrudR);
  const { language } = useSelector((state) => state.Language);
  const { deleteRecordSuccess } = crudRProps;
  const showDeleteConfirm = () => {
    confirm({
      title: `${title}`,
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: "#F55240" }} />,
      content: content,
      okText: language["action_modal_ok_button"],
      cancelText: language["action_modal_cancel_button"],
      width: "450px",
      onOk() {
        return new Promise((resolve, reject) => {
          const token = localStorage.getItem("token");
          fetch(`${ROOT_URL}/${apiName}`, {
            method: method,
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: token,
              APIType: "web",
            }),
            body: JSON.stringify(selectedItem),
          })
            .then((response) => {
              response.json().then((response) => {
                console.log("==== response ====", response);
                if (response.status === 'ERROR') {
                  message.error(response.message);
                  reject();
                  return;
                }
                dispatch({
                  type: `${requestType}_SUCCESS`,
                  payload: response,
                  recordName: recordName,
                });
                resolve();
              });
            })
            .catch((err) => {
              reject();
            });
        }).catch((error) => {
          console.log("=== test ====", error);
        });
      },
      onCancel() { },
    });
  };

  return (
    <span
      className={`cursor-pointer font-roboto-medium ${className}`}
      onClick={showDeleteConfirm}
      style={{ cursor: "pointer" }}
    >
      {buttonName}
    </span>
  );
};

export default DeleteModal;
