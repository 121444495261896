import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "antd";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import DetailModal from "../Modal/DetailModal";
import SingleApprovalAuctionForm from "./SingleApprovalAuctionForm";
// import DetailModal from "../../../../components/Modal/DetailModal";
// import { manualFormReset } from "../../../../utils/commonFunction";
// import { DataGetAction } from "../../../../redux/actions/CommonHttp";

const SingleViewApprovalAuction = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const [CategoryValue, setCategoryValue] = useState(null);
  const [vtaInclude, setVtaInclude] = useState(false);
  const { initialValue, View_Pending_Auction_Modal } = useSelector((state) => state.CrudR);


  useEffect(() => {
    if (initialValue) {
      console.log("===== initialValue =====", initialValue);
      dispatch(DataGetAction("getUsersDropDown", "", "FetchRecord", "dropDownUserList", ""));
      setCategoryValue(initialValue.categoryId);
      dispatch(
        DataGetAction(
          "getSubCategoryByCategory",
          { parentId: initialValue.categoryId },
          "FetchRecord",
          "subCategory",
          "fetchingSubCategory"
        )
      );
      if (initialValue && initialValue.taxInclude) {
        setVtaInclude(initialValue.taxInclude);
      }
    }
    if (!View_Pending_Auction_Modal) {
      setCategoryValue(null);
      setVtaInclude(false);
    }
  }, [View_Pending_Auction_Modal]);

  return (
    <DetailModal
      width={"1020px"}
      visible={View_Pending_Auction_Modal}
      modalTitle={`${language["auctions_forApproval_view_auction"]}`}
      modalName="View_Pending_Auction_Modal"
    >
      {initialValue && (
        <Form form={form} layout="vertical" initialValues={initialValue}>
          <div
            className="py-0"
            style={{
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <SingleApprovalAuctionForm
              readOnly={true}
              CategoryValue={CategoryValue}
              setCategoryValue={setCategoryValue}
              vtaInclude={vtaInclude}
            />
          </div>
          <div className="flex justify-end mt-3">
            <Button
              type="primary"
              onClick={() =>
                dispatch({
                  type: "Hide_Modal",
                  modalName: "View_Pending_Auction_Modal",
                })
              }
            >
              {language["auction_approval_ok"]}
            </Button>
          </div>
        </Form>
      )}
    </DetailModal>
  );
};

export default SingleViewApprovalAuction;
