import { Button, Col, Form, Input, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import Widget from "../../components/Widget";
import UserDummyImage from "../../assets/images/placeholder.jpg";
import { MdEditSquare } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import EditUserForm from "./components/EditUserForm";
import ShowModal from "../../components/Modal/ShowModal";
import ListTable from "../../components/Table";
import AddVATFee from "./components/AddVATFee";
import AddDefaultFee from "./components/AddDefaultFee";
import { useDispatch, useSelector } from "react-redux";
import AddAdministration from "./components/AddAdministration";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { capitalize, formatAsNOK } from "../../utils/commonFunction";
import ChangePassword from "./components/ChangePassword";

const Index = () => {
  const [photoList, setPhotoState] = useState({
    loading: false,
    imageUrl: "",
  });
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { getTaxValues, updateSettingSuccess } = useSelector((state) => state.CrudR);
  const { authUser } = useSelector((state) => state.AuthR);

  const fetchAPI = () => {
    dispatch(DataGetAction("getSetting", "", "FetchRecord", "getTaxValues", ""));
    dispatch(DataGetAction("getProfile", "", "FetchProfile", "", ""));
  };

  useEffect(fetchAPI, []);

  useEffect(() => {
    if (authUser) {
      setPhotoState({ imageUrl: authUser.profileImageUrl });
    }
  }, [authUser]);

  if (updateSettingSuccess) {
    fetchAPI();
    dispatch({ type: "Hide_Modal", modalName: "Add_Administration_Fee" });
    dispatch({ type: "Hide_Modal", modalName: "Add_Default_Fee_Modal" });
    dispatch({ type: "Hide_Modal", modalName: "Add_VAT_Modal" });
    dispatch({ type: "RESET_CRUD_STATE" });
  }
  console.log("==== getTaxValues rtet===",getTaxValues);

  const renderPrice = (price) => {
    return `${formatAsNOK(price)} ${process.env.REACT_APP_CURRENCY}`;
  };

  let columns = [
    {
      title: language["Administration_Fee"],
      render: (record) => <span>{renderPrice(record.min) + "-" + renderPrice(record.max)}</span>,
      key: "administrationFee",
    },
    {
      title: language["Fee_payable"],
      render: (record) => <span>{record.tax}</span>,
      key: "taxPayable",
    },
    {
      title: language["fee_type"],
      render: (record) => {
        return <span>{capitalize(record.feeType)}</span>;
      },
      key: "feeType",
    },
  ];

  let VATColumns = [
    {
      title: language["add_Vat_Fee"],
      render: (record) => <span>{renderPrice(record.min) + "-" + renderPrice(record.max)}</span>,
      key: "vat",
    },
    {
      title: language["Commission_payable"],
      render: (record) => <span>{record.tax}</span>,
      key: "taxPayable",
    },
    {
      title: language["fee_type"],
      render: (record) => {
        return <span>{capitalize(record.feeType)}</span>;
      },
      key: "feeType",
    },
  ];

  let defaultFeeColumns = [
    {
      title: language["default_Increment"],
      render: (record) => <span>{renderPrice(record?.defaultIncrement)}</span>,
      key: "increment",
    },
    {
      title: language["default_AuctionFee"],
      render: (record) => <span>{renderPrice(record?.defaultAuctionFee)}</span>,
      key: "fee",
    },
    {
      title: language["default_AuctionCommission"],
      render: (record) => {
        return <span>{renderPrice(record?.defaultAuctionCommission)}</span>;
      },
      key: "comission",
    },
  ];
  console.log("==== getTaxValues ====", getTaxValues);
  return (
    <Row gutter={[20, 20]}>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <Widget>
          <div className="mb-3">
            <div className="flex justify-between ">
              <img
                className="w-[100px] h-[100px] rounded drop-shadow"
                src={(photoList && photoList.imageUrl) || UserDummyImage}
                alt=""
              />
              <div className=" space-x-1">
                <ShowModal modalName="Change_Password_Modal" record={authUser}>
                  <span className=" text-black text-[14px] font-bold underline">Change Password</span>
                </ShowModal>
                <ShowModal modalName="Edit_User_Modal" record={authUser}>
                  <MdEditSquare size={30} fill="#FFD164" />
                </ShowModal>
              </div>
            </div>
            {authUser && (
              <>
                <p className="font-roboto-bold my-2">{language["first_name_label"]}</p>
                <div className="h-[30px] w-full rounded px-3" style={{ border: "1px solid #CACACA" }}>
                  <p className="mt-1 capitalize">{authUser && authUser.firstName} </p>
                </div>
                <p className="font-roboto-bold my-2">{language["last_name_label"]}</p>
                <div className="h-[30px] w-full rounded px-3" style={{ border: "1px solid #CACACA" }}>
                  <p className="mt-1 capitalize">{authUser && authUser.lastName} </p>
                </div>
                <p className="font-roboto-bold my-2">{language["email_name_label"]}</p>

                <div className="h-[30px] w-full rounded px-3" style={{ border: "1px solid #CACACA" }}>
                  <p className="mt-1 capitalize">{authUser && authUser.email} </p>
                </div>
                <p className="font-roboto-bold my-2">{language["phone_num_label"]}</p>

                <PhoneInput value={authUser && authUser.phoneNumber} disabled inputStyle={{ width: "100%" }} />
              </>
            )}
          </div>
        </Widget>
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <Widget>
          <div className="flex items-center justify-between">
            <h2 className="font-roboto-bold text-[18px] my-0">{language["administration_Fee"]}</h2>
            <ShowModal  modalName="Add_Administration_Fee" record={getTaxValues}>
              <Button type="primary" className="font-roboto-medium">
                {language["Add_Fee"]}
              </Button>
            </ShowModal>
          </div>
          <hr className="mt-4" />
          <div className="h-[350px] overflow-y-auto">
            <Table
              columns={columns}
              pagination={false}
              dataSource={getTaxValues && getTaxValues.administrationFee}
              className="custom-header"
            />
          </div>
        </Widget>
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <Widget>
          <div className="flex items-center justify-between">
            <h2 className="font-roboto-bold text-[18px] my-0">{language["add_Vat_Fee"]}</h2>
            <ShowModal modalName="Add_VAT_Modal" record={getTaxValues}>
              <Button className="font-roboto-medium" type="primary">
                {language["Add_VAT"]}
              </Button>
            </ShowModal>
          </div>

          <hr className="mt-4" />

          <div className="h-[350px] overflow-y-auto">
            <Table
              columns={VATColumns}
              pagination={false}
              dataSource={getTaxValues && getTaxValues.vatFee}
              className="custom-header"
            />
          </div>
        </Widget>
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <Widget>
          <div className="flex items-center justify-between">
            <h2 className="font-roboto-bold text-[18px]">{language["default_fee"]}</h2>
            <ShowModal modalName="Add_Default_Fee_Modal" record={getTaxValues}>
              <Button className="font-roboto-medium" type="primary">
                {language["change_fee"]}
              </Button>
            </ShowModal>
          </div>

          <hr className="mt-4" />

          <div className=" h-[200px] overflow-y-auto">
            <Table
              columns={defaultFeeColumns}
              pagination={false}
              dataSource={getTaxValues && getTaxValues?.defaultFee ? [getTaxValues.defaultFee] : []}
              className="custom-header"
            />
          </div>
        </Widget>
      </Col>
      <EditUserForm setPhotoState={setPhotoState} photoList={photoList} />
      <ChangePassword />
      <AddAdministration />
      <AddVATFee />
      <AddDefaultFee />
    </Row>
  );
};

export default Index;
