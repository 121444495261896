import React, { useEffect, useState } from "react";
import DetailModal from "../../../components/Modal/DetailModal";
import { Button, Form, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGetAction,
  DataRequestAction,
} from "../../../redux/actions/CommonHttp";

const ChangePassword = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { Change_Password_Modal, initialValue } = useSelector((state) => state.CrudR);
  const { updateProfileSpin, resetPass } = useSelector(
    (state) => state.AuthR
  );

  const onFinish = (values) => {
    delete values.confirmPassword
    dispatch(
      DataRequestAction(
        "POST",
        "changeAdminPassword",
        values,
        "resetPassword",
        "",
        "Profile_Spin"
      )
    );
  };
  if (resetPass) {
    form.resetFields()
    dispatch({ type: "Hide_Modal", modalName: "Change_Password_Modal" });
    dispatch({ type: "RESET_AUTH_STATE" });
  }

  // useEffect(() => {
  //   if (initialValue) {
  //     // setPhotoState({ imageUrl: initialValue.profileImageUrl });
  //   }
  // }, [initialValue, Change_Password_Modal]);
  return (
    <DetailModal
      visible={Change_Password_Modal}
      modalName="Change_Password_Modal"
      modalTitle={language["Change_Password"]}
    >
      <hr className="text-[#D9D9D9] mt-[-2px] mb-4" />

      <Form form={form} onFinish={onFinish} layout="vertical">
        <div className="flex items-center gap-8">
          <div className="md:w-[800px]">
            <Form.Item
              label={language["Old_Password"]}
              name="oldPassword"
              rules={[
                { required: true },
                {
                  min: 8,
                  message: 'Password should have minimum 8 characters long'
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                  message: 'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={language["New_Password"]}
              name="password"
              rules={[
                { required: true },
                {
                  min: 8,
                  message: 'Password should have minimum 8 characters long'
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                  message: 'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={language["Confirm_Password"]}
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password and Confirmation Password Should be Same'));
                  },
                }),
                {
                  min: 8,
                  message: 'Password should have minimum 8 characters long'
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                  message: 'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
          </div>

        </div>
        <div className="mt-3 flex justify-end">
          <Button
            loading={updateProfileSpin}
            // disabled={updateProfileSpin}
            type="primary"
            className="font-roboto-medium"
            htmlType="submit"
          >
            {language["change"]}
          </Button>
        </div>
      </Form>
    </DetailModal>
  );
};

export default ChangePassword;
