import React from "react";
import Title from "../../../components/Title";
import Filters from "../../../components/Filters";
import { useSelector } from "react-redux";
import AddSMSSetting from "./AddSMSSetting.js";

const TableHeader = () => {
  const { language } = useSelector((state) => state.Language);

  return (
    <div className="mb-3 flex items-center justify-between flex-wrap">
      <Title text={language["smsSetting_setting_page_title"]} />
      <div className="flex items-center flex-wrap gap-3">
        <Filters
          placeholder={language["smsSetting_setting_search_placeholder"]}
          dateWidth={"220px"}
          searchboxWidth={"250px"}
          customQuery={{ status: "active" }}
          dateFilter={false}
          apiName="getSmsSetting"
          recordName="smsSettingList"
        />
        <AddSMSSetting />
      </div>
    </div>
  );
};

export default TableHeader;
