import React, { useState } from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, dateFormate } from "../../utils/commonFunction";
import DeleteModal from "../../components/Modal/DeleteModal";
import Action from "../../components/Action";
import { ReactFormGenerator } from "react-form-builder2";
import { Button, Modal } from "antd";
import ShowModal from "../../components/Modal/ShowModal";
import { useNavigate } from "react-router";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { language } = useSelector((state) => state.Language);
  const [formData, setFormData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showTemplate = (data) => {
    setFormData(data);
    setIsModalOpen(true);
  };
  const renderActions = (record) => {
    console.log("====== record =====", record);
    return (
      <div className="flex flex-col">
        <span
          onClick={() => {
            dispatch({
              type: "Edit_Form_Template",
              payload: {
                title: record.name,
                _id: record._id,
                formStructure: record.formStructure,
              },
            });
            navigate("/editTemplate");
          }}
          className={`cursor-pointer font-roboto-medium `}
        >
          Edit Template
        </span>
        <DeleteModal
          title={language["delete_action_title"]}
          content={language["delete_form_content"]}
          apiName="deleteFormTemplate"
          method="DELETE"
          recordName="formTemplateRecord"
          buttonName={language["delete_action_title"]}
          selectedItem={record}
        />
      </div>
    );
  };

  const columns = [
    {
      title: language["Id"],
      render: (record) => <span>{record.templateId}</span>,
      key: "formId",
      width: 100,
    },
    {
      title: language["Name"],
      render: (record) => <span>{capitalize(record.name)}</span>,
      key: "name",
    },
    {
      title: language["Template"],
      render: (record) => (
        <Button
          type="link"
          onClick={() => {
            showTemplate(record.formStructure);
          }}
        >
          View
        </Button>
      ),
      key: "createdAt",
    },
    {
      title: language["CreatedAt"],
      render: (record) => <span>{dateFormate(record.createdAt)}</span>,
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
    },
  ];
  return (
    <div>
      <TableHeader />
      <ListTable showScroll={false} apiName="getFormTemplate" recordName="formTemplateRecord" columns={columns} />

      <Modal
        title="Template Preview"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setFormData([]);
        }}
        closable={true}
        footer={null}
      >
        <ReactFormGenerator hide_actions={true} data={formData} />
      </Modal>
    </div>
  );
};

export default Index;
