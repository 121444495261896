import React, { useEffect, useState } from "react";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import UserForm from "./UserForm";

const AddUser = () => {
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const { Add_User_Modal } = useSelector((state) => state.CrudR);
  const [businessCustomer, setBusinessCustomer] = useState(false);
  const [customFee, setCustomFeeCustomer] = useState(false);
  useEffect(() => {
    setBusinessCustomer(false);
  }, [Add_User_Modal]);
  return (
    <AddFormModal
      modalTitle={language["user_active_modalTitle"]}
      modalName="Add_User_Modal"
      apiName="addUser"
      recordName="userList"
      addButtonName={`+ ${language["user_active_add_button"]}`}
      form={form}
      extraObj={{
        businessCustomer: businessCustomer,
      }}
      width={"700px"}
    >
      <UserForm
        form={form}
        customFee={customFee}
        setCustomFeeCustomer={setCustomFeeCustomer}
        setBusinessCustomer={setBusinessCustomer}
        businessCustomer={businessCustomer}
      />
    </AddFormModal>
  );
};

export default AddUser;
