import React from "react";
import { useSelector } from "react-redux";
import ListTable from "../../components/Table";
import DetailModal from "../../components/Modal/DetailModal";
import moment from "moment";
import Countdown from "react-countdown";
import { dateFormate } from "../../utils/commonFunction";

const BidsDetailModel = () => {
  const { initialValue, Bids_Detail_Model, biderDetailList } = useSelector(
    (state) => state.CrudR
  );
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>{language["auction_view_expired"]}</span>;
    } else {
      return (
        <p className="text-primary mt-0">
          {days !== 0 ? days : ""}{" "}
          {days === 1 ? "day" : days === 0 ? "" : "day"} {hours} hours
        </p>
      );
    }
  };
  const { language } = useSelector((state) => state.Language);

  let columns = [
    {
      title: language["bids_detail_model_id"],
      render: (record) => <span>{record?.bidId}</span>,
      key: "",
    },
    {
      title: language["bids_detail_model_biderName"],
      render: (record) => (
        <span className="capitalize">
          {record?.firstName} {record?.lastName}
        </span>
      ),
      key: "",
    },
    {
      title: language["bids_detail_model_email"],
      render: (record) => <span>{record?.email}</span>,
      key: "",
    },
    {
      title: language["bids_detail_model_phone"],
      render: (record) => <span>{record?.phoneNumber}</span>,
      key: "",
    },
    {
      title: language["autobid"],
      render: (record) => <span>{record?.bidType == 'auto' ? 'A' : 'M'}</span>,
      key: "",
    },
    {
      title: language["bids_detail_model_price"],
      render: (record) => <span>{record?.bidPrice}</span>,
      key: "",
    },
    {
      title: language["bids_detail_model_dateAndTime"],
      render: (record) => (
        <span>{dateFormate(record?.createdAt)}</span>
      ),
      key: "",
    },
  ];

  return (
    <DetailModal
      visible={Bids_Detail_Model}
      modalTitle={language["bids_detail_model_title"]}
      modalName="Bids_Detail_Model"
      width={"1500px"}
    >
      <hr />
      <div className="flex justify-between">
        <p>
          {language["opening_bid"]}: {initialValue && initialValue.openingBid}
        </p>
        <p>
          {language["active_bidders"]}:{" "}
          {biderDetailList && biderDetailList.length}
        </p>
        <p className="flex gap-1">
          {language["bide_expiry"]}:{" "}
          <Countdown
            renderer={renderer}
            date={initialValue && initialValue.expiredAt}
          />
        </p>
      </div>
      <hr />
      {initialValue && (
        <ListTable
          columns={columns}
          apiName="getBiderDetail"
          recordName="biderDetailList"
          customQuery={{ _id: initialValue._id }}
        />
      )}
    </DetailModal>
  );
};

export default BidsDetailModel;
