import { Button, Form, Modal } from "antd";
import React, { useEffect } from "react";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";

export const EditFormModal = ({
  modalTitle,
  children,
  form,
  method = "PUT",
  apiName,
  requestType = "EditRecord",
  recordName,
  initialData,
  modalName,
  loaderCase = "editFormModalSpin",
  submitButtonName = "Submit",
  width,
  extraObj,
}) => {
  const dispatch = useDispatch();
  const crudProps = useSelector((state) => state.CrudR);
  const onFinish = (values) => {
    console.log("===== values =====", values);
    values["_id"] = initialData && initialData._id;
    dispatch(
      DataRequestAction(method, apiName, { ...values, ...extraObj }, requestType, recordName, loaderCase, modalName)
    );
  };
  if (crudProps && crudProps.editRecordSuccess) {
    dispatch({ type: "Hide_Modal", modalName: modalName });
    dispatch({ type: "RESET_CRUD_STATE" });
  }
  const closeModal = () => {
    form.resetFields();
    dispatch({ type: "Hide_Modal", modalName: modalName });
  };
  useEffect(() => {
    if ([crudProps[modalName]]) {
      form.setFieldsValue(initialData);
    }
  }, [crudProps[modalName]]);
  return (
    <Modal
      footer={null}
      onCancel={() => closeModal()}
      open={crudProps[modalName]}
      title={modalTitle}
      width={width}
      centered
    >
      <hr className="text-[#D9D9D9]" />
      <Form initialValues={initialData} form={form} layout="vertical" onFinish={onFinish}>
        <div className="my-4">{children}</div>
        <div className="pt-4 gap-2 flex justify-end" style={{ borderTop: "1px solid #DEDEDE" }}>
          <Button className="font-roboto-medium" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            disabled={crudProps && crudProps.editRecordSpin}
            loading={crudProps && crudProps.editRecordSpin}
            htmlType="submit"
            type="primary"
            className="font-roboto-medium"
          >
            {submitButtonName}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
