import React, { useEffect, useState } from "react";
import { EditFormModal } from "../../../../components/Modal/EditFormModal";
import ApprovalAuctionForm from "./ApprovalAuctionForm";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
import DynamicFormModal from "../../../../components/Modal/DynamicFormModal";

const EditApprovalAuction = ({ recordName = "forApprovalAuctionList" }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { language, initialValue, Edit_ForApproval_Modal, editRecordSuccess } = useSelector((state) => ({
    language: state.Language.language,
    initialValue: state.CrudR.initialValue,
    Edit_ForApproval_Modal: state.CrudR.Edit_ForApproval_Modal,
    editRecordSuccess: state.CrudR.editRecordSuccess,
  }));
  const [CategoryValue, setCategoryValue] = useState(null);
  const [formTemplate, setFormTemplate] = useState([]);
  const [taxRate, setTaxRate] = useState(false);
  useEffect(() => {
    console.log("====== initialValue effect start", Edit_ForApproval_Modal);
    if (Edit_ForApproval_Modal && initialValue) {
      setTaxRate(initialValue.taxInclude);
      setFormTemplate(initialValue?.formTemplate);
      setCategoryValue(initialValue.categoryId);
      dispatch(
        DataGetAction(
          "getSubCategoryByCategory",
          { parentId: initialValue.categoryId },
          "FetchRecord",
          "subCategory",
          "fetchingSubCategory"
        )
      );
    }
    if (!Edit_ForApproval_Modal) {
      setTaxRate(false);
    }
  }, [initialValue, Edit_ForApproval_Modal, dispatch]);

  useEffect(() => {
    if (editRecordSuccess) {
      dispatch({ type: "RESET_CRUD_STATE" });
      window.location.reload();
    }
  }, [editRecordSuccess, dispatch]);

  return (
    <DynamicFormModal
      width={"1000px"}
      form={form}
      method="PUT"
      addButtonName={language["auction_view_edit_auction"]}
      modalTitle={language["auctions_forApproval_edit_modal_title"]}
      modalName={"Edit_ForApproval_Modal"}
      apiName={"updateAuction"}
      recordName={"forApprovalAuctionList"}
      extraObj={{ categoryId: CategoryValue, formTemplate: formTemplate, _id: initialValue?._id }}
      requestType="EditRecord"
      loaderCaseName="DeleteFormModalSpin"
      spinnerName="deleteRecordSpin"
      showNotification={true}
      showButton={false}
    >
      <ApprovalAuctionForm
        setFormTemplate={setFormTemplate}
        setCategoryValue={setCategoryValue}
        CategoryValue={CategoryValue}
        setTaxRate={setTaxRate}
        taxRate={taxRate}
      />
    </DynamicFormModal>
  );
};

export default EditApprovalAuction;
