import { Form, Input, Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const SmsSettingForm = () => {
  const { language } = useSelector((state) => state.Language);

  return (
    <>
      <Form.Item
        name="smsType"
        label={language["smsSetting_form_smsSetting_type_label"]}
        rules={[{ required: true }]}
      >
        <Select
          placeholder={language["smsSetting_form_smsSetting_type_placeholder"]}
        >
          <Select.Option value="otp">{language["otp"]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="description"
        label={language["smsSetting_form_smsSetting_message_label"]}
        rules={[{ required: true }]}
      >
        <Input.TextArea
          placeholder={
            language["smsSetting_form_smsSetting_message_placeholder"]
          }
        />
      </Form.Item>
    </>
  );
};

export default SmsSettingForm;
