import React, { useEffect } from "react";
import Widget from "../../../components/Widget";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import moment from "moment";
const UserLineChart = ({ data }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { userLineCartList } = useSelector((state) => state.CrudR);

  const callApi = (value) => {
    dispatch(
      DataGetAction(
        "getDashboardUserAnalytics",
        { graphType: value },
        "FetchRecord",
        "userLineCartList",
        "TableSpinner"
      )
    );
  };

  useEffect(() => {
    callApi("weekly");
  }, []);
  function formatXAxis(tickItem) {
    if (tickItem !== "") {
      if (tickItem && tickItem.length <= 4) {
        return tickItem;
      } else {
        return moment(tickItem).format("MMM-DD");
      }
    } else {
      return "";
    }
  }
  return (
    <>
      {userLineCartList && (
        <Widget className="pt-1">
          <div
            className="flex items-center justify-between font-roboto-bold pb-4 mb-4"
            style={{ borderBottom: "1px solid #DBDBDB" }}
          >
            <h4 className="text-primary-text my-0 text-20">
              {language["Users_Data"]}
            </h4>
            <Select
              defaultValue={"weekly"}
              className="w-[200px]"
              onChange={(e) => callApi(e)}
            >
              <Select.Option value="weekly">{language["Weekly"]}</Select.Option>
              <Select.Option value="monthly">
                {language["Monthly"]}
              </Select.Option>
              <Select.Option value="yearly">{language["Yearly"]}</Select.Option>
            </Select>
          </div>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              data={userLineCartList}
              margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
            >
              <XAxis dataKey="name" tickFormatter={formatXAxis} />
              <YAxis />
              <Tooltip />
              <Line
                name={language["totalActiveUser"]}
                type="monotone"
                dataKey="totalActiveUser"
                stroke="#6FCE41"
                strokeDasharray="5 5"
              />
              <Line
                name={language["totalBlockUser"]}
                type="monotone"
                dataKey="totalBlockUser"
                stroke="#FF2326"
                strokeDasharray="3 4 5 2"
              />
              <Line
                name={language["totalUnVerifiedUser"]}
                type="monotone"
                dataKey="totalUnVerifiedUser"
                stroke="#DF9D5B"
                strokeDasharray="3 4 5 2"
              />
            </LineChart>
          </ResponsiveContainer>
        </Widget>
      )}
    </>
  );
};

export default UserLineChart;
