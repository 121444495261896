import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Radio, Row, Select, Switch, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
import CategoryCard from "../../../../components/categoryCard/categoryCard";
import { capitalize, formElement } from "../../../../utils/commonFunction";
import ImageUpload from "./ImageUpload"; // Import the new ImageUpload component

const ApprovalAuctionForm = ({ readOnly = false, setCategoryValue, CategoryValue, setFormTemplate }) => {
  const form = Form.useFormInstance();

  const [years, setYears] = useState([]);
  const [FormDataValue, setFormData] = useState([]);

  const dispatch = useDispatch();

  const { language, categoryList, subCategory, dropDownUserList, Add_ForApproval_Modal, Edit_ForApproval_Modal, initialValue } = useSelector((state) => ({
    language: state.Language.language,
    categoryList: state.CrudR.categoryList,
    subCategory: state.CrudR.subCategory,
    dropDownUserList: state.CrudR.dropDownUserList,
    Add_ForApproval_Modal: state.CrudR.Add_ForApproval_Modal,
    Edit_ForApproval_Modal: state.CrudR.Edit_ForApproval_Modal,
    initialValue: state.CrudR.initialValue,
  }));

  const setInitialValue = () => {
    console.log("===== initialValue ApprovalAuctionForm", initialValue)
    if (Edit_ForApproval_Modal && initialValue) {
      setFormData(initialValue?.formTemplate);
      form.setFieldsValue(initialValue?.formStructure);
      form.setFieldValue("categoryId", initialValue?.categoryId);
      form.setFieldValue("subCategoryId", initialValue?.subCategoryId);
      form.setFieldValue("userId", initialValue?.userId);
      form.setFieldValue("vatEnable", initialValue?.vatEnable);
    }
  };

  useEffect(setInitialValue, [Edit_ForApproval_Modal, initialValue, form]);

  const onselect = (_id) => {
    setCategoryValue(_id);
    dispatch(
      DataGetAction("getSubCategoryByCategory", { parentId: _id }, "FetchRecord", "subCategory", "fetchingSubCategory")
    );
  };

  const renderCategory = () => {
    if (categoryList && categoryList.length > 0) {
      return categoryList.map((item, index) => (
        <CategoryCard
          key={item._id}
          onClick={() => {
            if (!readOnly) {
              onselect(item._id);
            }
          }}
          imgUrl={item.categoryImage}
          background={CategoryValue === item._id ? "#14526270" : ""}
          text={item.name}
        />
      ));
    }
  };

  const renderOption = () => {
    if (dropDownUserList && dropDownUserList.length > 0) {
      return dropDownUserList.map((item) => {
        const truncatedName = item?.name?.length > 15 ? item?.name?.slice(0, 15) + "..." : item?.name;
        const truncatedCompanyName =
           item?.companyName?.length > 15 ? item?.companyName.slice(0, 15) + "..." : item?.companyName;

        return (
          <Select.Option key={item._id} value={item._id}>
            <span className="flex flex-row gap-10 items-start">
              <span>{item.userId}</span>
              <span className="text-ellipsis">{capitalize(truncatedName)}</span>
              <span className="flex flex-row gap-10">
                <span className="text-ellipsis">{item.email}</span>
                {item.companyName && (
                  <span className="flex text-ellipsis overflow-hidden">{capitalize(truncatedCompanyName)}</span>
                )}
              </span>
            </span>
            <span className="hidden">
              {item.userId}-{item.name}-{item.email}-{item.companyName}
            </span>
          </Select.Option>
        );
      });
    }
  };

  useEffect(() => {
    dispatch(DataGetAction("getUsersDropDown", "", "FetchRecord", "dropDownUserList", ""));
  }, [Add_ForApproval_Modal, Edit_ForApproval_Modal, dispatch]);

  useEffect(() => {
    const populateYearDropdown = () => {
      const currentYear = new Date().getFullYear();
      const startYear = 1900;

      const yearOptions = [];
      for (let year = currentYear; year >= startYear; year--) {
        yearOptions.push(year);
      }
      setYears(yearOptions);
    };

    populateYearDropdown();
  }, []);

  const renderSubCategory = () => {
    if (subCategory && subCategory.length > 0) {
      return subCategory.map((Item) => (
        <Select.Option key={Item._id} value={Item._id}>
          {capitalize(Item.name)}
        </Select.Option>
      ));
    }
  };

  const renderForm = () => {
    if (FormDataValue && FormDataValue.length > 0) {
      return FormDataValue.map((Item) => {
        if (Item.element === "FileUpload") {
          let defaultList = [];
          if (initialValue) {
            let data = initialValue?.formStructure;
            defaultList = data[Item.field_name];
          }

          return <ImageUpload data={Item} field_name={Item.field_name} form={form} defaultList={defaultList} />;
        } else {
          return formElement(Item);
        }
      });
    }
  };

  const filterOption = (input, option) => {
    let filterValue = "";
    if (option.children && option.children.length > 0) {
      filterValue = option.children[1].props.children.join();
    }
    return filterValue.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Row className="my-3 gap-y-4">
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="title"
          label={language["auction_auction_title"]}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="userId"
          label={readOnly ? language["auction_auction_User"] : language["auction_auction_select_User"]}
          rules={[{ required: true }]}
        >
          <Select
            className="h-[40px]"
            placeholder={language["auction_auction_select_User"]}
            allowClear={true}
            showSearch={true}
            filterOption={filterOption}
          >
            {renderOption()}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <div className="flex items-center flex-wrap gap-3">{renderCategory()}</div>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="subCategoryId" label={language["Sub_Category_title"]} rules={[{ required: true }]}>
          <Select
            className="h-[40px]"
            placeholder={language["sub_category_dropdown_placeholder"]}
            onChange={(data) => {
              let formData = subCategory.filter((x) => x._id === data);
              setFormData(formData[0].formStructure);
              setFormTemplate(formData[0].formStructure);
            }}
          >
            {renderSubCategory()}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        {renderForm()}
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="vatEnable" label={language["auction_Vat_Enable"]} valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="showInProfileAdd" label={language["showInProfileAdd"]} valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ApprovalAuctionForm;
