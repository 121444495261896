import React, { useEffect, useRef, useState } from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../../components/Table";
import { useSelector } from "react-redux";
import moment from "moment";
import Action from "../../../components/Action";
import ShowModal from "../../../components/Modal/ShowModal";
import ActiveModal from "../../../components/Modal/DeleteModal";
import Widget from "../../../components/Widget/index.js";
import DetailUserModel from "../DetailUserModel.js";
import BidsDetailModel from "../BidsDetailModel.js";
import ViewNotes from "../../../components/ViewNotes.js";
import EditApprovalAuction from "../ForApproval/components/EditApprovalAuction.js";
import { dateFormate, formatAsNOK } from "../../../utils/commonFunction.js";
import Countdown from "react-countdown";
import SingleViewApprovalAuction from "../../../components/AuctionDetailModal/SingleViewApprovalAuction.js";

const Index = () => {
  const [open, setOpen] = useState(false);

  const targetRef = useRef();
  const { language } = useSelector((state) => state.Language);
  const renderActions = (record) => {
    // record.ModelYear = moment(record.ModelYear).format('YYYY-DD-MM');
    return (
      <div className="flex flex-col">
        <ShowModal
          modalName="View_Pending_Auction_Modal"
          record={record}
          buttonName={language["auctions_rejected_view"]}
        />
        <ShowModal
          modalName="Edit_ForApproval_Modal"
          record={record}
          buttonName={language["auctions_forApproval_edit"]}
        />
        <ActiveModal
          title={language["auctions_rejected_active_title"]}
          content={language["auctions_rejected_active_content"]}
          apiName="updateAuctionStatus"
          method="PUT"
          recordName="rejectedAuctionList"
          buttonName={language["auctions_rejected_active"]}
          selectedItem={{ _id: record._id, status: "live" }}
        />
        <ShowModal modalName="View_Common_Notes" record={record} buttonName={"View Notes"} />
      </div>
    );
  };

  const renderPrice = (price) => {
    let convertPrice = formatAsNOK(price);
    let amount = `${convertPrice} ${process.env.REACT_APP_CURRENCY} `;
    return amount;
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>{language["auction_view_expired"]}</span>;
    } else {
      return (
        <p className="text-primary">
          {days !== 0 ? days : ""}{" "}
          {days === 1 ? "day" : days === 0 ? "" : "days"} {hours} hours
        </p>
      );
    }
  };

  const columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record.auctionId}</span>,
      key: "Id",
    },
    {
      title: language["auctions_rejected_category"],
      render: (record) => <span>{record.categoryName}</span>,
      key: "Category",
    },
    {
      title: language["auctions_live_subcategory"],
      render: (record) => <span>{record.subCategoryName}</span>,
      key: "Category",
    },
  
    {
      title: language["auctions_rejected_opening_price"],
      render: (record) => <span>{renderPrice(record?.formStructure?.price ?? record.price)}</span>,
      key: "Price",
      width: 250,
    },
    {
      title: language["auctions_block_duration"],
      // render: (record) => <span>{record.auctionDuration ? `${record.auctionDuration} days` : "----"}</span>,
      render: (record) => (
        <>
          {record.expiredAt ? (
            <Countdown renderer={renderer} date={record.expiredAt} />
          ) : (
            `${record.auctionDuration} days`
          )}
        </>
      ),
      key: "Duration",
    },
    {
      title: language["auctions_live_noOfBids"],
      render: (record) => (
        <>
          {record.totalBider > 0 ? (
            <ShowModal
              modalName="Bids_Detail_Model"
              record={record}
              className="underline text-blue-500 cursor-pointer"
              buttonName={record.totalBider}
            />
          ) : (
            "----"
          )}
        </>
      ),
      key: "bids",
    },
    {
      title: language["auctions_rejected_createdBy"],
      render: (record) => (
        <div className="py-[10px]">
          <ShowModal
            modalName="User_Detail_Model"
            record={record}
            className="font-roboto-bold text-14 capitalize hover:underline text-blue-500"
            buttonName={`${record.firstName} ${record.lastName}`}
          />
          <p className="font-roboto-regular my-0 text-14">{record.email}</p>
          <p className="font-roboto-regular my-0 text-14">{record.phoneNumber}</p>
        </div>
      ),
      key: "Created By",
    },
    {
      title: language["auctions_rejected_createdAt"],
      render: (record) => <span>{dateFormate(record.createdAt)}</span>,
      key: "Created At",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
      fixed: "right"
    },
  ];
  const allColumnKeys = [
    "Id",
    "Title",
    "Category",
    "Model Year",
    "Brand",
    "Price",
    "Duration",
    "bids",
    "Created By",
    "Created At",
    "actions",
  ];
  const [selectedColumnKeys, setSelectedColumnKeys] = useState(allColumnKeys);
  const [checkedList, setCheckedList] = useState(allColumnKeys);
  const [tableColumns, setTableColumns] = useState(columns);
  function isChecked(value) {
    return checkedList.includes(value);
  }
  function handleSelection(key) {
    const tempList = [...checkedList];
    if (tempList.includes(key)) {
      const index = tempList.indexOf(key);
      tempList.splice(index, 1);
    } else {
      tempList.push(key);
    }
    setCheckedList(tempList);
  }
  function handleConfirmation() {
    setSelectedColumnKeys(checkedList);
  }
  useEffect(() => {
    if (selectedColumnKeys) {
      if (selectedColumnKeys.length) {
        const temp = columns.filter((f) => selectedColumnKeys.includes(f.key));
        setTableColumns(temp);
      } else {
        setTableColumns(columns);
      }
    }
    return () => {};
  }, [selectedColumnKeys]);
  const checkBoxData = columns.map((m) => ({ key: m.key, title: m.title }));
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <Widget>
      <TableHeader
        targetRef={targetRef}
        handleOpenChange={handleOpenChange}
        hide={hide}
        checkBoxData={checkBoxData}
        open={open}
        isChecked={isChecked}
        handleSelection={handleSelection}
        handleConfirmation={handleConfirmation}
      />
      <div ref={targetRef}>
        <ListTable
          customQuery={{ status: "reject" }}
          apiName="getAuctions"
          recordName="rejectedAuctionList"
          columns={tableColumns}
        />
      </div>
      <DetailUserModel />
      <BidsDetailModel />
      <SingleViewApprovalAuction />
      <EditApprovalAuction recordName="rejectedAuctionList" />
      <ViewNotes />
    </Widget>
  );
};

export default Index;
