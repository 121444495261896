import React from "react";
import AddFormModal from "../../../components/Modal/AddFormModal";
import { Form } from "antd";
import SMSSettingForm from "./SMSSettingForm";
import { useSelector } from "react-redux";

const AddSmsSetting = () => {
  const { language } = useSelector((state) => state.Language);
  const [form] = Form.useForm();
  return (
    <AddFormModal
      form={form}
      modalTitle={language["smsSetting_add_email"]}
      modalName="Add_SmsSetting_Temp"
      apiName="addSmsSetting"
      recordName="smsSettingList"
      addButtonName={language["smsSetting_add_name"]}
    >
      <SMSSettingForm />
    </AddFormModal>
  );
};

export default AddSmsSetting;
