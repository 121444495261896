import { LoginSuccess } from "./CommonFn";

const initialState = {
  role: "superAdmin",
  token: localStorage.getItem("token"),
  authUser: JSON.parse(localStorage.getItem("userInfo")),
  loginSuccess: false,
  loginError: false,
  loginSpin: false,
  updateProfile: false,
  updateProfileSpin: false,
  addVatFee: false,
  show_UnAuthorized: false,
  resetPass: false,
};
/* eslint-disable */
export default (state = initialState, action) => {
  switch (action.type) {
    case "Login_SUCCESS": {
      localStorage.setItem("token", action.payload.user.token);
      return {
        ...state,
        token: action.payload.user.token,
        loginSuccess: true,
        loginSpin: false,
      };
    }
    case "Login_ERROR": {
      return {
        ...state,
        loginError: true,
        loginSpin: false,
      };
    }
    case "LoginSpin_START": {
      return {
        ...state,
        loginSpin: true,
      };
    }
    case "Otp_Api_SUCCESS": {
      let Record = LoginSuccess(action.payload.user, state.role);
      // console.log("===Record", Record);
      return {
        ...state,
        authUser: Record,
        otpSuccess: Record !== null ? true : false,
        otpSpin: false,
        show_UnAuthorized: false,
      };
    }
    case "Otp_Api_Error": {
      return {
        ...state,
        otpError: false,
        otpSpin: false,
      };
    }
    case "Otp_Api_Spin_START": {
      return {
        ...state,
        otpSpin: true,
      };
    }
    case "Profile_Update_SUCCESS": {
      let ProfileData = JSON.parse(localStorage.getItem("userInfo"));
      ProfileData["firstName"] = action.payload.Record.firstName;
      ProfileData["lastName"] = action.payload.Record.lastName;
      localStorage.setItem("userInfo", JSON.stringify(ProfileData));
      return {
        ...state,
        authUser: action.payload.Record,
        updateProfile: true,
        updateProfileSpin: false,
      };
    }
    case "Profile_Update_Error": {
      return {
        ...state,
        updateProfileSpin: false,
      };
    }
    case "Profile_Spin_START": {
      return {
        ...state,
        updateProfileSpin: true,
      };
    }
    case "UN_AUTHORIZED": {
      return {
        ...state,
        show_UnAuthorized: true,
      };
    }
    case "Hide_UN_AUTHORIZED": {
      return {
        ...state,
        show_UnAuthorized: false,
      };
    }

    case "resetPassword_Start": {
      return {
        ...state,
        resetPass: false,
        updateProfileSpin: true,
      };
    }
    case "resetPassword_SUCCESS": {
      // let Record = action.payload.Record
      return {
        ...state,
        resetPass: true,
        updateProfileSpin: false,
      };
    }
    case "resetPassword_ERROR": {
      return {
        ...state,
        resetPass: false,
        updateProfileSpin: false,
      };
    }
    // case "ADD_VAT_FEE": {
    //   return {
    //     ...state,
    //     addVatFee: true,
    //     addVatSpinner: false,
    //   };
    // }

    // case "ADD_VAT_FEE_Spin": {
    //   return {
    //     ...state,
    //     addVatSpinner: true,
    //   };
    // }
    // case "ADD_Administration_FEE_SUCCESS": {
    //   return {
    //     ...state,
    //     addAdministrationFee: true,
    //     addAdministrationFeeSpin: false,
    //   };
    // }
    // case "ADD_Administration_FEE_Error": {
    //   return {
    //     ...state,
    //     addAdministrationFeeSpin: false,
    //   };
    // }

    // case "ADD_Administration_FEE_Spin_START": {
    //   return {
    //     ...state,
    //     addAdministrationFeeSpin: true,
    //   };
    // }
    case "FetchProfile_SUCCESS": {
      return {
        ...state,
        authUser: action.payload.Record,
      };
    }

    case "RESET_AUTH_STATE": {
      return {
        ...state,
        loginSuccess: false,
        loginError: false,
        loginSpin: false,
        otpSpin: false,
        otpError: false,
        otpSuccess: false,
        addVatFee: false,
        updateProfile: false,
        show_UnAuthorized: false,
        resetPass: false,
        updateProfileSpin: false,
      };
    }
    default: {
      return state;
    }
  }
};
