import React, { useEffect, useState } from "react";
import AuthLayout from "../AuthLayout";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";
import PhoneInput from "react-phone-input-2";
import { countryList } from "../../../utils/commonFunction";
const Index = () => {
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const { loginSuccess, loginSpin } = useSelector((state) => state.AuthR);
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let countryListData = countryList();

  const onFinish = (values) => {

    console.log("This is the login called:");
    setLoader(true)
    values["phoneNumber"] = `+${values.phoneNumber}`;
    dispatch(DataRequestAction("POST", "signIn", values, "Login", "", "LoginSpin", "", "", language));
    setLoader(false)
  };
  if (loginSuccess) {
    navigate("/otpScreen");
    dispatch({ type: "RESET_AUTH_STATE" });
  }

  useEffect(() => {
    localStorage.clear();
  }, []);

  function handleKeyUp(event) {
    // Enter
    if (event.keyCode === 13) {
      form.submit();
    }
  }

  return (
    <AuthLayout heading="Welcome back!" content="Log in to your account">
      <Form form={form} onFinish={onFinish} onKeyUp={handleKeyUp} className="w-full" layout="vertical">
        <Form.Item
          name="phoneNumber"
          label={language["login_mobile_number"]}
          rules={[
            {
              required: true,
              message: language["login_mobile_number_isRequired"],
            },
          ]}
        >
          <PhoneInput
            inputStyle={{ width: "100%", height: "40px", fontSize: "16px" }}
            country={["no"]}
            onlyCountries={countryListData}
            onEnterKeyPress={handleKeyUp}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={language["login_password"]}
          rules={[
            {
              required: true,
              message: language["login_password_isRequired"],
            },
          ]}
        >
          <Input.Password placeholder={language["login_password"]} />
        </Form.Item>

        <Button
          loading={loginSpin}
          disabled={loginSpin}
          htmlType="submit"
          type="primary"
          className="w-full font-roboto-bold mt-2"
          size="large"
        >
          {language["login_auth_button"]}
        </Button>
      </Form>
    </AuthLayout>
  );
};

export default Index;
