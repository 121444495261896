import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";

const { RangePicker } = DatePicker;
const Filters = ({ placeholder, searchboxWidth, dateWidth, apiName, recordName, customQuery, dateFilter = true }) => {
  const [form] = Form.useForm();
  const [CleanFilter, setCleanFilter] = useState(false);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    if (values && values.date && values.date[0]) {
      values["startDate"] = values && values.date[0];
    }
    if (values && values.date && values.date[1]) {
      values["endDate"] = values && values.date[1];
    }
    let obj = {
      date: values.date,
      name: values.name,
    };
    dispatch(
      DataGetAction(apiName, { query: "search", ...obj, ...customQuery }, "FetchRecord", recordName, "TableSpinner")
    );
    setCleanFilter(true);
  };

  const onReset = () => {
    form.resetFields();
    dispatch(DataGetAction(apiName, { query: "all", ...customQuery }, "FetchRecord", recordName, "TableSpinner"));
    setCleanFilter(false);
  };
  return (
    <Form form={form} onFinish={onFinish} className="flex items-center gap-3 flex-wrap">
      <Form.Item noStyle name="name">
        <Input onChange={() => setCleanFilter(false)} placeholder={placeholder} style={{ width: searchboxWidth }} />
      </Form.Item>
      {dateFilter && (
        <Form.Item noStyle name="date">
          <RangePicker allowClear={false} onChange={() => setCleanFilter(false)} style={{ width: dateWidth }} />
        </Form.Item>
      )}
      <Form.Item noStyle>
        {!CleanFilter && (
          <Button htmlType="submit" style={{ boxShadow: "#0000003b 0px 4px 5px 0px" }} className="font-roboto-medium">
            Search
          </Button>
        )}
      </Form.Item>
      {CleanFilter && (
        <Button
          htmlType="reset"
          onClick={onReset}
          style={{ boxShadow: "#0000003b 0px 4px 5px 0px" }}
          className="font-roboto-medium"
        >
          Clear Filter
        </Button>
      )}
    </Form>
  );
};

export default Filters;
