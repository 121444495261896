import React, { useEffect } from "react";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RiAuctionFill,
  RiChatSettingsLine,
  RiMailSettingsFill,
  RiApps2Line,
  RiMindMap,
  RiStackLine,
} from "react-icons/ri";
import { BiSolidDashboard, BiSolidUserCircle } from "react-icons/bi";
import { GiGearHammer } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { Socket } from "../../../socket";
const CustomMenu = ({ setCollapsed }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { formChange, newAuctions, closeAuctionCounter } = useSelector((state) => state.CrudR);
  const { authUser } = useSelector((state) => state.AuthR);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const selectedKeys = pathname.substr(1);
  let keyArray = selectedKeys.split("/");
  let defaultOpenKeys = [keyArray[0]];
  if (selectedKeys.split("/").length === 3) {
    defaultOpenKeys = [keyArray[0], keyArray[1]];
  }

  const setInitialConnection = () => {
    if (authUser) {
      Socket.getInstance(authUser._id);
    }
  };

  useEffect(setInitialConnection, []);
  const renderBadge = () => {
    if (newAuctions && newAuctions > 0) {
      return <span className="text-red-500">{newAuctions}</span>;
    }
  };
  const renderCloseAuctionBadge = () => {
    if (closeAuctionCounter && closeAuctionCounter > 0) {
      return <span className="text-red-500">{closeAuctionCounter}</span>;
    }
  };

  return (
    <Menu
      defaultOpenKeys={defaultOpenKeys}
      selectedKeys={["/" + selectedKeys]}
      theme="dark"
      mode="inline"
      style={{ fontSize: "14px", fontWeight: "bold" }}
      defaultSelectedKeys={["0"]}
      onClick={(e) => {
        if (formChange) {
          dispatch({
            type: "Open_Confirmation_modal",
            menuClick: e.key,
          });
        } else {
          navigate(e.key);
          setCollapsed(false);
        }
      }}
      items={[
        {
          key: "/dashboard",
          icon: <BiSolidDashboard size={24} className="mt-[-4px]" />,
          label: language["side_menu_dashboard"],
        },
        {
          key: "1",
          icon: <BiSolidUserCircle size={24} className="mt-[-4px]" />,
          label: language["side_menu_user"],
          children: [
            {
              key: "/user/activeUser",
              label: language["side_menu_activeUser"],
            },
            {
              key: "/user/blockUser",
              label: language["side_menu_blockUser"],
            },
            {
              key: "/user/unverifiedUser",
              label: language["side_menu_unverifiedUser"],
            },
          ],
        },
        {
          key: "2",
          icon: <RiAuctionFill size={24} className="mt-[-4px]" />,
          label: language["side_menu_auction"],
          children: [
            {
              key: "/auction/forApproval",
              label: (
                <div className="flex items-center justify-between">
                  <span>{language["side_menu_forApproval"]}</span>
                  {renderBadge()}
                </div>
              ),
              showBadge: true,
            },
            {
              key: "/auction/liveAuctions",
              label: language["side_menu_liveAuctions"],
            },
            {
              key: "/auction/blockedAuctions",
              label: language["side_menu_blockedAuctions"],
            },
            {
              key: "/auction/rejectedAuctions",
              label: language["side_menu_rejectedAuctions"],
            },
            {
              key: "/auction/closedAuctions",
              label: (
                <div className="flex items-center justify-between">
                  <span>{language["side_menu_closedAuctions"]}</span>
                  {renderCloseAuctionBadge()}
                </div>
              ),
              showBadge: true,
            },
          ],
        },
        {
          key: "3",
          icon: <GiGearHammer size={24} className="mt-[-4px]" />,
          label: language["side_menu_processAuction"],
          children: [
            {
              key: "/process/inProgress",
              label: language["side_menu_inProgress"],
            },
            {
              key: "/process/dealClosed",
              label: language["side_menu_dealClosed"],
            },
          ],
        },
        {
          key: "/emailSetting",
          icon: <RiMailSettingsFill size={24} className="mt-[-4px]" />,
          label: language["side_menu_emailSetting"],
        },
        {
          key: "/smsSetting",
          icon: <RiChatSettingsLine size={24} className="mt-[-4px]" />,
          label: language["side_menu_smsSetting"],
        },
        {
          key: "/category",
          icon: <RiApps2Line size={24} className="mt-[-4px]" />,
          label: language["side_menu_category"],
        },
        {
          key: "/subCategory",
          icon: <RiMindMap size={24} className="mt-[-4px]" />,
          label: language["side_menu_subcategory"],
        },
        {
          key: "/formTemplate",
          icon: <RiStackLine size={24} className="mt-[-4px]" />,
          label: language["side_menu_formTemplate"],
        },
      ]}
    />
  );
};

export default CustomMenu;
