import React from "react";
import Title from "../../../../components/Title";
import Filters from "../../../../components/Filters";
import PDF from "../../../../assets/images/pdficon.png";
import XL from "../../../../assets/images/xlicon.png";
import { useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import generatePDF from "react-to-pdf";
import { Button, Checkbox, Popover } from "antd";
import { FaFilter } from "react-icons/fa";
const TableHeader = ({
  targetRef,
  checkBoxData,
  isChecked,
  handleSelection,
  handleConfirmation,
  handleOpenChange,
  hide,
  open,
}) => {
  const { language } = useSelector((state) => state.Language);
  const { liveAuctionList } = useSelector((state) => state.CrudR);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className="mb-3 flex items-center justify-between flex-wrap">
      <Title text={language["auctions_live_page_title"]} />
      <div className="flex items-center flex-wrap gap-3">
        <Filters
          placeholder={language["user_block_search_placeholder"]}
          dateWidth={"220px"}
          searchboxWidth={"250px"}
          customQuery={{ status: "live" }}
          apiName="getAuctions"
          recordName="liveAuctionList"
        />
        <img
          src={PDF}
          alt="PDF"
          className="w-[35px]"
          onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}
        />
        <img
          src={XL}
          alt="XL"
          className="w-[35px]"
          onClick={() => exportToCSV(liveAuctionList, "Users")}
        />
        <Popover
          content={
            <div>
              <div>
                {checkBoxData.map((checkbox) => {
                  if (checkbox.key !== "actions") {
                    return (
                      <div
                        key={checkbox.key}
                        className="modal-body d-flex flex-column"
                      >
                        <Checkbox
                          checked={isChecked(checkbox.key)}
                          onChange={() => handleSelection(checkbox.key)}
                        >
                          {checkbox.title}
                        </Checkbox>
                      </div>
                    );
                  }
                })}
              </div>
              <Button
                type="primary"
                className="w-full mt-2"
                onClick={() => {
                  handleConfirmation();
                  hide();
                }}
              >
                Confirm
              </Button>
            </div>
          }
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <Button type="primary" className="px-2">
            <FaFilter fill="#fff" size={20} className="pt-1" />
          </Button>
        </Popover>
      </div>
    </div>
  );
};

export default TableHeader;
