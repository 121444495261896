import { Checkbox, Col, Form, Input, InputNumber, Row, Space, Switch, Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { countryList } from "../../../../utils/commonFunction";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const UserForm = ({
  setBusinessCustomer,
  businessCustomer,
  customFee,
  setCustomFeeCustomer,
  form,
  disabled,
  readOnly,
}) => {
  const { language } = useSelector((state) => state.Language);
  const [phoneNumber, setPhoneNumber] = useState("");
  let countryListData = countryList();

  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="firstName" label={language["user_active_firstName"]} rules={[{ required: true }]}>
          <Input readOnly />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="lastName" label={language["user_active_lastName"]} rules={[{ required: true }]}>
          <Input readOnly />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="phoneNumber" label={language["user_active_phone"]} rules={[{ required: true }]}>
          <PhoneInput
            country={"no"}
            disabled={disabled}
            disableCountryCode
            disableCountryGuess
            disableDropdown
            disableSearchIcon
            onlyCountries={countryListData}
            value={phoneNumber.phone}
            inputStyle={{ width: "100%" }}
            inputProps={{
              readOnly: true,
            }}
            onChange={(phone) => setPhoneNumber({ phone })}
          />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="email" label={language["user_active_email"]} rules={[{ required: true }, { type: "email" }]}>
          <Input readOnly />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="address" label={language["user_active_address"]} rules={[{ required: true }]}>
          <Input readOnly />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="postalCode" label={language["user_active_postalCode"]} rules={[{ required: true }]}>
          <Input type="number" readOnly />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="place" label={language["user_active_place"]} rules={[{ required: true }]}>
          <Input readOnly />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="country" label={language["user_active_country"]} rules={[{ required: true }]}>
          <Input readOnly />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="businessCustomer" valuePropName="checked">
          <div className="flex gap-3 my-2">
            <Switch disabled defaultChecked={form.getFieldValue("businessCustomer")} />
            <span>{language["Business_Customer"]}</span>
          </div>
        </Form.Item>
      </Col>
      {businessCustomer && (
        <>
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
            <Form.Item name="companyName" label={language["user_active_companyName"]} rules={[{ required: true }]}>
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item name="companyNumber" label={language["user_active_companyNumber"]} rules={[{ required: true }]}>
              <Input readOnly />
            </Form.Item>
          </Col>
        </>
      )}

      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="customFee" valuePropName="checked">
          <div className="flex gap-3 my-2">
            <Switch readOnly defaultChecked={form.getFieldValue("customFee")} disabled />
            <span>{language["Custom_Fee"]}</span>
          </div>
        </Form.Item>
      </Col>
      {customFee && (
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card size="small" title={language["administration_Fee"]}>
                <Form.List name="administrationFee">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "min"]}
                            rules={[
                              {
                                required: true,
                                message: language["missing_min_value"],
                              },
                            ]}
                          >
                            <InputNumber readOnly className="w-full" placeholder={language["enter_min_value"]} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "max"]}
                            dependencies={[name, "min"]}
                            rules={[
                              {
                                required: true,
                                message: language["missing_max_value"],
                              },
                            ]}
                          >
                            <InputNumber readOnly className="w-full" placeholder={language["enter_max_value"]} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "tax"]}
                            rules={[
                              {
                                required: true,
                                message: language["missing_tax_value"],
                              },
                            ]}
                          >
                            <InputNumber
                              readOnly
                              className="w-full"
                              placeholder={language["enter_standard_auction_fee_value"]}
                            />
                          </Form.Item>
                          <MinusCircleOutlined />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" block icon={<PlusOutlined />} className="font-roboto-medium">
                          {language["Add_field"]}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Card>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card size="small" title={language["add_Vat_Fee"]}>
                <Form.List name="vatFee">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "min"]}
                            rules={[
                              {
                                required: true,
                                message: language["missing_min_value"],
                              },
                            ]}
                          >
                            <InputNumber readOnly className="w-full" placeholder={language["enter_min_value"]} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "max"]}
                            dependencies={[name, "min"]}
                            rules={[
                              {
                                required: true,
                                message: language["missing_max_value"],
                              },
                            ]}
                          >
                            <InputNumber readOnly className="w-full" placeholder={language["enter_max_value"]} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "tax"]}
                            rules={[
                              {
                                required: true,
                                message: language["missing_tax_value"],
                              },
                            ]}
                          >
                            <InputNumber readOnly className="w-full" placeholder={language["enter_commission_value"]} />
                          </Form.Item>
                          <MinusCircleOutlined />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" block icon={<PlusOutlined />} className="font-roboto-medium">
                          {language["Add_field"]}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Card>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default UserForm;
