import React, { useEffect } from "react";
import Widget from "../../../components/Widget";
import { GoDotFill } from "react-icons/go";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Notifications = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { notificationList } = useSelector((state) => state.CrudR);
  const renderNotifications = () => {
    if (notificationList && notificationList.length > 0) {
      return notificationList.map((item) => {
        if (item.text && item.text !== "") {
          return (
            <div className="flex items-center justify-between w-full">
              <div className="flex flex-row items-center gap-2 w-[230px]">
                <p>
                  <GoDotFill size={16} fill="#145262" />
                </p>
                <p className="font-roboto-medium font-[18px]">{item.text}</p>
              </div>
              <p className=" text-align-right text-[#9B9B9B] font-roboto-medium">
                {moment(item.createdAt).format("DD-MMM-YYYY")}
              </p>
            </div>
          );
        }
      });
    }
  };
  const callApi = () => {
    dispatch(
      DataGetAction(
        "getNotification",
        "",
        "FetchRecord",
        "notificationList",
        "TableSpinner"
      )
    );
  };

  useEffect(() => {
    callApi();
  }, []);
  return (
    <>
      {notificationList && (
        <Widget className="pt-1 h-full">
          <div
            className="flex items-center justify-between font-roboto-bold pb-4 mb-4"
            style={{ borderBottom: "1px solid #DBDBDB" }}
          >
            <h4 className="text-primary-text my-0 text-20">
              {language["Notifications"]}
            </h4>
          </div>
          <div className="overflow-y-auto h-[562px]">
            {renderNotifications()}
          </div>
        </Widget>
      )}
    </>
  );
};

export default Notifications;
