import React, { useEffect } from "react";
import { Col, Form, Input, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import DetailModal from "../../components/Modal/DetailModal";

const DetailUserModel = () => {
  const dispatch = useDispatch();
  const { initialValue, User_Detail_Model, fetchingSpinner, UserDetail } =
    useSelector((state) => state.CrudR);
  const { language } = useSelector((state) => state.Language);

  useEffect(() => {
    if (initialValue) {
      dispatch(
        DataGetAction(
          "getAuctionsUserDetail",
          { _id: initialValue.createdBy },
          "FetchRecord",
          "UserDetail",
          "TableSpinner"
        )
      );
    }
  }, [User_Detail_Model]);

  return (
    <DetailModal
      visible={User_Detail_Model}
      modalTitle={language["user_detail_model_title"]}
      modalName="User_Detail_Model"
      width='600px'
    >
      <>
        {fetchingSpinner && (
          <div className="h-[200px] flex items-center justify-center">
            <Spin size="large" />
          </div>
        )}
        {!fetchingSpinner && (
          <Form layout="vertical" initialValues={UserDetail}>
            <Row>
              <Col xl={12} lg={12} md={12} xs={24} sm={24} className="md:pe-3">
                <Form.Item
                  name="firstName"
                  label={language["user_detail_model_first_name"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="lastName"
                  label={language["user_detail_model_last_name"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24} className="md:pe-3">
                <Form.Item
                  name="email"
                  label={language["user_detail_model_email"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="phoneNumber"
                  label={language["user_detail_model_phoneNumber"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24} className="md:pe-3">
                <Form.Item
                  name="address"
                  label={language["user_detail_model_address"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="postalCode"
                  label={language["user_detail_model_postalCode"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24} className="md:pe-3">
                <Form.Item
                  name="place"
                  label={language["user_detail_model_place"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="companyName"
                  label={language["user_detail_model_companyName"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24} className="md:pe-3">
                <Form.Item
                  name="country"
                  label={language["user_detail_model_country"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={24}>
                <Form.Item
                  name="companyNumber"
                  label={language["user_detail_model_companyNumber"]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              {/* <Col xl={24} lg={24} md={24} xs={24} sm={24}>
                <Form.Item name="" label="">
                  <Input disabled={true} />
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        )}
      </>
    </DetailModal>
  );
};

export default DetailUserModel;
