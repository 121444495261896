import { Button, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { TiDelete } from "react-icons/ti";
const DetailModal = ({ visible, modalTitle, children, modalName, width, closable = false, type = "Hide_Modal" }) => {
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch({ type: type, modalName: modalName });
  };
  return (
    <Modal  open={visible} width={width} closable={closable} footer={null} centered>
      <div className="px-3 mt-[-14px]  flex justify-between items-center font-lato-extra-bold text-2xl">
        <p className="mb-2 text-[18px] font-roboto-bold font-roboto-medium">{modalTitle}</p>
        <TiDelete onClick={() => handleCancel()} className="cursor-pointer" fill="#145262" />
      </div>
      <div className="px-3">{children}</div>
    </Modal>
  );
};

export default DetailModal;
