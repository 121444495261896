import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
const ReactQuil = ({ Form, name, labelName, restField, value }) => {
  return (
    <Form.Item
      {...restField}
      name={name}
      label={`${labelName}`}
      initialValue=""
    >
      <ReactQuill
        modules={{
          toolbar: [
            [
              "bold",
              "italic",
              "image",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
            ],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "imageBlot",
        ]}
      />
    </Form.Item>
  );
};

export default ReactQuil;
