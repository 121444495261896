import React from "react";
import { Form } from "antd";
import SMSSettingForm from "./SMSSettingForm";
import { EditFormModal } from "../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";

const EditSmsSetting = () => {
  const { language } = useSelector((state) => state.Language);
  const { initialValue } = useSelector((state) => state.CrudR);
  const [form] = Form.useForm();
  return (
    <EditFormModal
      form={form}
      modalTitle={language["smsSetting_edit_email"]}
      modalName="Edit_smsSetting_Temp"
      apiName="editSmsSetting"
      recordName="smsSettingList"
      initialData={initialValue}
    >
      <SMSSettingForm />
    </EditFormModal>
  );
};

export default EditSmsSetting;
