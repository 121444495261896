import React, { useRef, useState, useEffect } from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Action from "../../../components/Action";
import ShowModal from "../../../components/Modal/ShowModal";
import ActiveModal from "../../../components/Modal/DeleteModal";
import ViewApprovalAuction from "./components/ViewApprovalAuction.js";
import Widget from "../../../components/Widget/index.js";
import DetailUserModel from "../DetailUserModel.js";
import Countdown from "react-countdown";
import BidsDetailModel from "../BidsDetailModel.js";
import ViewNotes from "../../../components/ViewNotes.js";
import AuctionApprovel from "../AuctionApprovel.js";
import { dateFormate, formatAsNOK } from "../../../utils/commonFunction.js";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/CommonHttp.js";
import SingleViewApprovalAuction from "../../../components/AuctionDetailModal/SingleViewApprovalAuction.js";
import { Button } from "antd";

const Index = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const targetRef = useRef();
  const { language } = useSelector((state) => state.Language);

  const renderPrice = (price) => {
    let convertPrice = formatAsNOK(price);
    let amount = `${convertPrice} ${process.env.REACT_APP_CURRENCY} `;
    return amount;
  };

  const fetchCategory = () => {
    dispatch(DataGetAction("getCategory", { query: "all" }, "FetchRecord", "categoryList", "categoryLoader"));
  };
  useEffect(fetchCategory, []);

  const handleReduceTimeClick = (record) => {
    const requestData = {
      _id: record._id, // Send the auction ID
    };

    // Dispatch the action to call the reduce expiry time API
    dispatch(
      DataRequestAction(
        "POST",
        "reduceAuctionExpiry", // API endpoint
        requestData, // Request body containing auction ID
        "ReduceExpiry", // Request type (for state management)
        "liveAuctionList", // Record name (for state management)
        "auctionLoader", // Loader case to show a loader
        null, // No specific modal to close
        true, // Show success notification
        language // Pass language object for error messages
      )
    );
  };



  const columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record.auctionId}</span>,
      key: "Id",
      width: 80,
    },

    {
      title: language["auctions_live_category"],
      render: (record) => <span className="capitalize">{record.categoryName}</span>,
      key: "Category",
      width: 200,
    },
    {
      title: language["auctions_live_subcategory"],
      render: (record) => <span className="capitalize">{record.subCategoryName}</span>,
      key: "Category",
      width: 200,
    },

    {
      title: language["auctions_live_opening_price"],
      render: (record) => <span>{renderPrice(record.formStructure.price ?? record.price)}</span>,
      key: "Price",
      width: 250,
    },
    {
      title: language["auctions_live_duration"],
      render: (record) => <Countdown renderer={renderer} date={record.expiredAt} />,
      key: "Duration",
      width: 150,
    },
    {
      title: language["auctions_live_noOfBids"],
      render: (record) => (
        <>
          {record?.totalBider > 0 ? (
            <ShowModal
              modalName="Bids_Detail_Model"
              record={record}
              className="underline text-blue-500 cursor-pointer"
              buttonName={record?.totalBider}
            />
          ) : (
            "----"
          )}
        </>
      ),
      key: "Bids",
      width: 180,
    },
    {
      title: language["auctions_live_sellerBy"],
      render: (record) => (
        <div>
          <ShowModal
            modalName="User_Detail_Model"
            record={record}
            className="font-roboto-bold text-14 capitalize hover:underline text-blue-500"
            buttonName={`${record.firstName} ${record.lastName}`}
          />
          <p className="font-roboto-regular my-0 text-14">{record.email}</p>
          <p className="font-roboto-regular my-0 text-14">{record.phoneNumber}</p>
        </div>
      ),
      key: "Created By",
      width: 200,
    },
    {
      title: language["auctions_live_createdBy"],
      render: (record) => (
        <div>
          <p>{record.createdByName}</p>
        </div>
      ),
      key: "Created By",
      width: 200,
    },
    {
      title: language["auctions_live_createdAt"],
      render: (record) => <span>{dateFormate(record.createdAt)}</span>,
      key: "Created At",
      width: 200,
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
      fixed: "right",
    },
  ];

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>{language["auction_view_expired"]}</span>;
    } else {
      return (
        <p className="text-primary">
          {days !== 0 ? days : ""} {days === 1 ? "day" : days === 0 ? "" : "day"} {hours} hours
        </p>
      );
    }
  };
  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
         <ShowModal
          modalName="View_Pending_Auction_Modal"
          record={record}
          buttonName={language["auctions_live_view"]}
        />
        {/* <ShowModal modalName="View_Live_Auction_Modal" record={record} buttonName={language["auctions_live_view"]} /> */}
        <ActiveModal
          title={language["auctions_live_block_title"]}
          content={language["auctions_live_block_content"]}
          apiName="updateAuctionStatus"
          method="PUT"
          recordName="liveAuctionList"
          buttonName={language["auctions_live_block"]}
          selectedItem={{ _id: record._id, status: "block" }}
        />
        <ActiveModal
          title={language["auctions_live_close_title"]}
          content={language["auctions_live_close_content"]}
          apiName="updateAuctionStatus"
          method="PUT"
          recordName="liveAuctionList"
          buttonName={language["auctions_live_close"]}
          selectedItem={{ _id: record._id, status: "closed" }}
        />
        <ShowModal modalName="View_Common_Notes" record={record} buttonName={language["auction_view_note"]} />
        <span
        onClick={() => {handleReduceTimeClick(record)}}
        className="font-roboto-medium py-0 cursor-pointer"
      >
        Reduce Time
      </span>
      </div>
    );
  };

  const allColumnKeys = [
    "Id",
    "Title",
    "Category",
    "Model Year",
    "Brand",
    "Price",
    "Duration",
    "Bids",
    "Created By",
    "Created At",
    "actions",
  ];
  const [selectedColumnKeys, setSelectedColumnKeys] = useState(allColumnKeys);
  const [checkedList, setCheckedList] = useState(allColumnKeys);
  const [tableColumns, setTableColumns] = useState(columns);
  function isChecked(value) {
    return checkedList.includes(value);
  }
  function handleSelection(key) {
    const tempList = [...checkedList];
    if (tempList.includes(key)) {
      const index = tempList.indexOf(key);
      tempList.splice(index, 1);
    } else {
      tempList.push(key);
    }
    setCheckedList(tempList);
  }
  function handleConfirmation() {
    setSelectedColumnKeys(checkedList);
  }
  useEffect(() => {
    if (selectedColumnKeys) {
      if (selectedColumnKeys.length) {
        const temp = columns.filter((f) => selectedColumnKeys.includes(f.key));
        setTableColumns(temp);
      } else {
        setTableColumns(columns);
      }
    }
    return () => { };
  }, [selectedColumnKeys]);
  const checkBoxData = columns.map((m) => ({ key: m.key, title: m.title }));
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Widget>
      <TableHeader
        targetRef={targetRef}
        handleOpenChange={handleOpenChange}
        hide={hide}
        checkBoxData={checkBoxData}
        open={open}
        isChecked={isChecked}
        handleSelection={handleSelection}
        handleConfirmation={handleConfirmation}
      />

      <div ref={targetRef}>
        <ListTable
          customQuery={{ status: "live" }}
          apiName="getAuctions"
          recordName="liveAuctionList"
          columns={tableColumns}
        />
      </div>
      <SingleViewApprovalAuction />
      <DetailUserModel />
      <BidsDetailModel />
      <ViewNotes />
      <AuctionApprovel />
    </Widget>
  );
};

export default Index;
