import React from "react";
import Title from "../../../../components/Title";
import Filters from "../../../../components/Filters";
import { useSelector } from "react-redux";

const TableHeader = () => {
  const { language } = useSelector((state) => state.Language);

  return (
    <div className="mb-3 flex items-center justify-between flex-wrap">
      <Title text={language["user_unverifiedUser_page_title"]} />
      <Filters
        placeholder={language["user_unverifiedUser_search_placeholder"]}
        dateWidth={"220px"}
          searchboxWidth={"250px"}
          customQuery={{ status: "unVerified" }}
          apiName="getUsers"
          recordName="userList"
      />
    </div>
  );
};

export default TableHeader;
