import React from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../components/Table";
import { useSelector } from "react-redux";
import { Image } from "antd";
import { capitalize, dateFormate } from "../../utils/commonFunction";
import ShowModal from "../../components/Modal/ShowModal";
import DeleteModal from "../../components/Modal/DeleteModal";
import Action from "../../components/Action";
import EditCategory from "./components/EditCategory";

const Index = () => {
  const { language } = useSelector((state) => state.Language);

  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal modalName={"Edit_Category_Modal"} record={record} buttonName={language["edit_action_title"]} />
        <DeleteModal
          title={language["delete_action_title"]}
          content={language["delete_category_content"]}
          apiName="deleteCategory"
          method="DELETE"
          recordName="categoryList"
          buttonName={language["delete_action_title"]}
          selectedItem={record}
        />
      </div>
    );
  };

  const columns = [
    {
      title: language["Id"],
      render: (record) => <span>{record.categoryId}</span>,
      key: "categoryId",
      width: 100,
    },
    {
      title: language["Image"],
      render: (record) => <Image src={record.categoryImage} width={50} />,
      key: "categoryImage",
      width: 200,
    },
    {
      title: language["Name"],
      render: (record) => <span>{capitalize(record.name)}</span>,
      key: "name",
    },
    {
      title: language["CreatedAt"],
      render: (record) => <span>{dateFormate(record.createdAt)}</span>,
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
    },
  ];
  return (
    <div>
      <TableHeader />
      <ListTable showScroll={false} apiName="getCategory" recordName="categoryList" columns={columns} />
      <EditCategory />
    </div>
  );
};

export default Index;
