import { Checkbox, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { countryList } from "../../../../utils/commonFunction";
const DetailForm = ({
  readOnly = false,
  NewPaperState,
  TermAndConditionState,
  setNewPaperState,
  setTermAndConditionState,
}) => {
  const { language } = useSelector((state) => state.Language);
  const [phoneNumber, setPhoneNumber] = useState("");
  let countryListData = countryList();
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="firstName" label={language["user_active_firstName"]} rules={[{ required: true }]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="lastName" label={language["user_active_lastName"]} rules={[{ required: true }]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="phoneNumber" label={language["user_active_phone"]} rules={[{ required: true }]}>
          <PhoneInput
            disabled={readOnly}
            country={"no"}
            onlyCountries={countryListData}
            value={phoneNumber.phone}
            inputStyle={{ width: "100%" }}
            onChange={(phone) => setPhoneNumber({ phone })}
          />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="email" label={language["user_active_email"]} rules={[{ required: true }, { type: "email" }]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="address" label={language["user_active_address"]} rules={[{ required: true }]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="postalCode" label={language["user_active_postalCode"]} rules={[{ required: true }]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="place" label={language["user_active_place"]} rules={[{ required: true }]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="country" label={language["user_active_country"]} rules={[{ required: true }]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item name="companyName" label={language["user_active_companyName"]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item name="companyNumber" label={language["user_active_companyNumber"]}>
          <Input readOnly={readOnly} />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="md:pe-3">
        <div>
          <Checkbox checked={NewPaperState} onChange={(e) => setNewPaperState(e.target.checked)}>
            {language["I_want_news"]}
          </Checkbox>
          <Checkbox checked={TermAndConditionState} onChange={(e) => setTermAndConditionState(e.target.checked)}>
            {language["I_approve_terms"]}
          </Checkbox>
        </div>
      </Col>
    </Row>
  );
};

export default DetailForm;
