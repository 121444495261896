import React, { useEffect, useState } from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { Button, Image, Modal } from "antd";
import { capitalize, dateFormate } from "../../utils/commonFunction";
import ShowModal from "../../components/Modal/ShowModal";
import DeleteModal from "../../components/Modal/DeleteModal";
import Action from "../../components/Action";
import EditSubCategory from "./components/EditSubCategory";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { ReactFormGenerator } from "react-form-builder2";

const Index = () => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const { language } = useSelector((state) => state.Language);
  const dispatch = useDispatch();

  const fetchCategory = () => {
    dispatch(DataGetAction("getCategory", { query: "all" }, "FetchRecord", "categoryDropDown", "categoryLoader"));
    dispatch(DataGetAction("getFormTemplate", { query: "all" }, "FetchRecord", "formTemplates", "formTemplateLoader"));
  };

  useEffect(fetchCategory, []);

  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal modalName={"Edit_Subcategory_Modal"} record={record} buttonName={language["edit_action_title"]} />
        <DeleteModal
          title={language["delete_action_title"]}
          content={language["delete_category_content"]}
          apiName="deleteSubCategory"
          method="DELETE"
          recordName="subCategoryList"
          buttonName={language["delete_action_title"]}
          selectedItem={record}
        />
      </div>
    );
  };

  const openPreview = (data) => {
    setPreviewData(data);
    setOpenTemplate(true);
  };

  const handleOk = () => {
    setPreviewData([]);
    setOpenTemplate(false);
  };

  const renderTemplateName = (record) => {
    if (record && record.templateName) {
      return (
        <Button
          onClick={() => {
            openPreview(record.formStructure);
          }}
        >
          {capitalize(record.templateName)}
        </Button>
      );
    } else {
      return "-----";
    }
  };

  const columns = [
    {
      title: language["Id"],
      render: (record) => <span>{record.categoryId}</span>,
      key: "categoryId",
      width: 100,
    },
    {
      title: language["Name"],
      render: (record) => <span>{record && record.name ? capitalize(record.name) : ""}</span>,
      key: "name",
    },
    {
      title: language["Category_title"],
      render: (record) => <span>{record && record.categoryName ?capitalize(record.categoryName):""}</span>,
      key: "categoryName",
    },
    {
      title: language["Template"],
      render: (record) => renderTemplateName(record),
      key: "templateName",
    },
    {
      title: language["CreatedAt"],
      render: (record) => <span>{dateFormate(record.createdAt)}</span>,
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
    },
  ];
  return (
    <div>
      <TableHeader />
      <ListTable showScroll={false} apiName="getSubCategory" recordName="subCategoryList" columns={columns} />
      <EditSubCategory />
      <Modal title="Basic Modal" open={openTemplate} onCancel={handleOk} closable={true} footer={null}>
        <ReactFormGenerator hide_actions={true} data={previewData} />
      </Modal>
    </div>
  );
};

export default Index;
