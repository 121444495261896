import React from "react";
import AddFormModal from "../../../components/Modal/AddFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import CategoryForm from "./CategoryForm";

const AddCategory = () => {
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
 
  return (
    <AddFormModal
      modalTitle={language["category_add_title"]}
      modalName="Add_Category_Modal"
      apiName="addCategory"
      recordName="categoryList"
      addButtonName={`+ ${language["category_add_title"]}`}
      form={form}
      width={"700px"}
    >
      <CategoryForm />
    </AddFormModal>
  );
};

export default AddCategory;
