import React from "react";
import AddFormModal from "../../../components/Modal/AddFormModal";
import { Form } from "antd";
import EmailForm from "./EmailForm";
import { useSelector } from "react-redux";

const AddEmail = () => {
  const { language } = useSelector((state) => state.Language);
  const [form] = Form.useForm();
  return (
    <AddFormModal
      form={form}
      modalTitle={language["email_add_email"]}
      modalName="Add_Email_Temp"
      apiName="addEmailSetting"
      recordName="emailList"
      addButtonName={language["email_add_name"]}
    >
      <EmailForm />
    </AddFormModal>
  );
};

export default AddEmail;
