import React from "react";
import { Drawer } from "antd";
import CustomMenu from "./CustomMenu";
import { AiOutlineClose } from "react-icons/ai";
import { theme } from "../../../styles/themeColor";

const DrawerSidebar = ({ collapsed, setCollapsed }) => {
  return (
    <Drawer
      title={<div className="h-[50px] mb-3 flex items-center justify-center">
      <span className="text-[20px] font-bol" style={{ color: "white" }}>
        Logo
      </span>
    </div>}
      headerStyle={{ padding: "0px 24px", background: theme.customComponent.Content.primaryColor }}
      className="add-days-modal sideBar"
      bodyStyle={{ padding: 0, background: theme.customComponent.Content.primaryColor }}
      placement="left"
      closeIcon={
        <AiOutlineClose className="absolute right-1 top-2 hover:text-primary-default cursor-pointer" size={20} />
      }
      onClose={() => setCollapsed(false)}
      key="left"
      width="250px"
      maskClosable={true}
      open={collapsed}
    >
      <div className=" mt-5">
      <CustomMenu setCollapsed={setCollapsed} />
      </div>
    </Drawer>
  );
};

export default DrawerSidebar;
