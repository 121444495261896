import React, { useEffect, useState } from "react";
import { Avatar, Popover } from "antd";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoLogOutSharp } from "react-icons/io5";
import { AiFillSetting } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import placeholderImage from "../../../assets/images/placeholder.jpg";
const UserInfo = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { authUser } = useSelector((state) => state.AuthR);
  const logout = () => {
    dispatch({ type: "Logout" });
    localStorage.clear();
    navigate("/login");
  };
  useEffect(() => {
    if (authUser === null) {
      navigate("/login");
    }
  }, [authUser]);
  const content = (
    <div onClick={() => setOpen(false)} className="flex flex-col font-roboto-bold ">
      <span
        onClick={() => navigate("/settings")}
        className="flex gap-1 p-2 rounded-md cursor-pointer text-primary-default hover:bg-[#F49A1466] "
      >
        <IoLogOutSharp size="20px" /> Settings
      </span>
      <span
        className="flex gap-1 p-2 rounded-md cursor-pointer text-primary-default hover:bg-[#F49A1466] "
        onClick={() => logout()}
      >
        <AiFillSetting size="20px" /> Logout
      </span>
    </div>
  );
  return (
    <div className="flex items-center justify-between md:gap-2 text-black capitalize">
      <div className="max-md:hidden">
        <IoLogOutSharp fill="#145262" size={28} className="cursor-pointer" onClick={() => logout()} />
      </div>
      <div className="max-md:hidden">
        <AiFillSetting size={28} fill="#145262" className="cursor-pointer" onClick={() => navigate("/setting")} />
      </div>
      <div className="max-md:hidden flex items-center justify-between md:gap-2 ">
        <Avatar src={(authUser && authUser.profileImageUrl) || placeholderImage} size="large" />
        <span className=" font-roboto-bold text-sm">
          {authUser && authUser.firstName} {authUser && authUser.lastName}
        </span>
      </div>
      <div className="md:hidden">
        <Popover
          open={open}
          onOpenChange={(e) => setOpen(e)}
          content={content}
          placement="bottomRight"
          trigger="click"
          className="cursor-pointer capitalize flex items-center popover"
        >
          <span className="md:flex hidden ">{authUser && authUser.fullName && authUser.fullName.slice(0, 10)}</span>
          <Avatar className="cursor-pointer" src={(authUser && authUser.profileImageUrl) || placeholderImage} />
          <IoMdArrowDropdown size={20} />
        </Popover>
      </div>
    </div>
  );
};

export default UserInfo;
