import React from "react";
import DetailModal from "../../../../components/Modal/DetailModal";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Spin } from "antd";
import { DataGetAction, DataRequestAction } from "../../../../redux/actions/CommonHttp";

const RePublish = () => {
  const { Re_Publish_View_Modal, initialValue, fetchingSpinner } = useSelector((state) => state.CrudR);
  const { language } = useSelector((state) => state.Language);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    var currentDate = new Date();
    initialValue["createdAt"] = new Date();
    initialValue["expiredAt"] = currentDate.setDate(
      currentDate.getDate() + parseInt(initialValue.formStructure.duration)
    );

    let dataForAuction = {
      ...initialValue,
      ...values,
    };

    dispatch(
      DataRequestAction(
        "PUT",
        "rePublishAuction",
        dataForAuction,
        "DeleteRecord",
        "closedAuctionList",
        "TableSpinner",
        "Re_Publish_View_Modal"
      )
    );
    form.resetFields()
  };

  return (
    <DetailModal
      visible={Re_Publish_View_Modal}
      modalName={"Re_Publish_View_Modal"}
      modalTitle={language["re_publish_auction"]}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="message">
          <Input.TextArea placeholder={language["auction_enter_note_here"]} rows={5} />
        </Form.Item>

        <div className="flex justify-end items-center gap-2 pt-3" style={{ borderTop: "1px solid #D4C2C1" }}>
          <Button>{language["auction_republish_cancel"]}</Button>
          <Button htmlType={!fetchingSpinner ? "submit" : "button"} type="primary" className="bg-primary-text">
            {language["auction_republish_save"]} {fetchingSpinner && <Spin />}
          </Button>
        </div>
      </Form>
    </DetailModal>
  );
};

export default RePublish;
