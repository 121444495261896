import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Dashboard from "../screens/Dashboard";
import ActiveUser from "../screens/Users/ActiveUsers";
import BlockUser from "../screens/Users/BlockUsers";
import UnverifiedUser from "../screens/Users/UnverifiedUser";
import ForApproval from "../screens/Auctions/ForApproval";
import LiveAuction from "../screens/Auctions/Live";
import BlockAuction from "../screens/Auctions/Blocked";
import RejectedAuction from "../screens/Auctions/Rejected";
import ClosedAuctions from "../screens/Auctions/Closed";
import InProgress from "../screens/ProcessAuction/InProgress";
import EmailSetting from "../screens/EmailSetting";
import SmsSetting from "../screens/SmsSetting";
import DealClosed from "../screens/ProcessAuction/DealClosed";
import Setting from "../screens/Setting";
import Category from "../screens/Category";
import SubCategory from "../screens/SubCategory";
import FormTemplate from "../screens/FormTemplate";
import CreateTemplate from "../screens/FormTemplate/createTemplate";
import EditTemplate from "../screens/FormTemplate/editTemplate";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user/activeUser" element={<ActiveUser />} />
        <Route path="/user/blockUser" element={<BlockUser />} />
        <Route path="/user/unverifiedUser" element={<UnverifiedUser />} />
        <Route path="/auction/forApproval" element={<ForApproval />} />
        <Route path="/auction/liveAuctions" element={<LiveAuction />} />
        <Route path="/auction/blockedAuctions" element={<BlockAuction />} />
        <Route path="/auction/rejectedAuctions" element={<RejectedAuction />} />
        <Route path="/auction/closedAuctions" element={<ClosedAuctions />} />
        <Route path="/process/inProgress" element={<InProgress />} />
        <Route path="/emailSetting" element={<EmailSetting />} />
        <Route path="/smsSetting" element={<SmsSetting />} />
        <Route path="/process/dealClosed" element={<DealClosed />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/category" element={<Category />} />
        <Route path="/subCategory" element={<SubCategory />} />
        <Route path="/formTemplate" element={<FormTemplate />} />
        <Route path="/createTemplate" element={<CreateTemplate />} />
        <Route path="/editTemplate" element={<EditTemplate />} />
        <Route path="/" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
