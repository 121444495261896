import React from "react";
import Title from "../../../components/Title";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const TableHeader = () => {
  const { language } = useSelector((state) => state.Language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="mb-3 flex items-center justify-between flex-wrap">
      <Title text={language["Template"]} />
      <div className="flex items-center flex-wrap gap-3">
        <Button
          type="primary"
          onClick={() => {
            dispatch({
              type: "Clear_Form_data",
            });
            navigate("/createTemplate");
          }}
        >
          {language["Add_New_Form_Template"]}
        </Button>
      </div>
    </div>
  );
};

export default TableHeader;
