import React, { useEffect, useState } from "react";
import AuthLayout from "../AuthLayout";
import { Button, Form } from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";
import OtpInput from "react-otp-input";
const Index = () => {
  const { language } = useSelector((state) => state.Language);
  const { otpSuccess, otpSpin } = useSelector((state) => state.AuthR);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = () => {
    if (otp && otp.length === 5) {
      dispatch(DataRequestAction("POST", "verifyOtp", { otpCode: otp }, "Otp_Api", "", "Otp_Api_Spin"));
    }
  };

  console.log("======= otpSuccess", otpSuccess);
  if (otpSuccess) {
    navigate("/dashboard");
    dispatch({ type: "RESET_AUTH_STATE" });
  }

  function handleKeyUp(event) {
    // Enter
    if (event.keyCode === 13) {
      onFinish();
    }
  }

  return (
    <AuthLayout heading={language["otp_verify_title"]} content={language["otp_verify_content"]}>
      <Form onKeyUp={handleKeyUp}>
        <div className="flex justify-center mb-3">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            inputStyle={{ width: "30px", height: "30px" }}
            renderSeparator={<span className="mx-2 text-[30px]">-</span>}
            renderInput={(props) => <input {...props} />}
            onPaste={onFinish}
          />
        </div>
        <Button
          loading={otpSpin}
          disabled={otpSpin || otp.length < 5}
          htmlType="submit"
          type="primary"
          className="w-full font-roboto-bold mt-2"
          size="large"
          onClick={() => onFinish()}
        >
          {language["otp_screen_verify"]}
        </Button>
      </Form>
      <p className="underline text-blue-500 cursor-pointer text-center mt-4 mb-0" onClick={() => navigate("/login")}>
        {language["login_back_button"]}
      </p>
    </AuthLayout>
  );
};

export default Index;
