import React from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../../components/Table";
import { useSelector } from "react-redux";
import moment from "moment";
import { dateFormate } from "../../../utils/commonFunction";
import DeleteModal from "../../../components/Modal/DeleteModal";
import Action from "../../../components/Action";
const Index = () => {
  const { language } = useSelector((state) => state.Language);

  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <DeleteModal
          title={language["user_block_delete_title"]}
          content={language["user_block_delete_content"]}
          apiName="deleteUser"
          method="DELETE"
          recordName="userList"
          buttonName={language["user_block_delete_button"]}
          selectedItem={record}
        />
      </div>
    );
  };

  const columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record.userId}</span>,
      key: "userId",
      width: 100,
    },
    {
      title: language["user_unverifiedUser_phone"],
      render: (record) => <span>{record.phoneNumber}</span>,
      key: "phone",
      width: 200,
    },
    {
      title: language["user_unverifiedUser_createdAt"],
      render: (record) => <span>{dateFormate(record.createdAt)}</span>,
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
    },
  ];
  return (
    <div>
      <TableHeader />
      <ListTable
        showScroll={false}
        customQuery={{ status: "unVerified" }}
        apiName="getUsers"
        recordName="userList"
        columns={columns}
      />
    </div>
  );
};

export default Index;
