import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
const ListTable = ({
  columns,
  apiName,
  recordName,
  customQuery = {},
  callApi = true,
  data,
  showScroll = { x: 1300 },
  sendQuery = true,
}) => {
  const dispatch = useDispatch();
  const CurdProps = useSelector((state) => state.CrudR);
  let tableQuery = sendQuery ? { query: "all", ...customQuery } : "";
  const fetchList = () => {
    if (callApi) {
      dispatch(DataGetAction(apiName, tableQuery, "FetchRecord", recordName, "TableSpinner"));
    }
  };
  // eslint-disable-next-line
  useEffect(fetchList, []);
  return (
    <Table
      scroll={showScroll}
      columns={columns}
      dataSource={CurdProps[recordName] || data}
      loading={CurdProps["fetchingSpinner"]}
      
    />
  );
};

export default ListTable;
