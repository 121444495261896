import React, { useEffect, useRef, useState } from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Action from "../../../components/Action";
import ShowModal from "../../../components/Modal/ShowModal";
import ActiveModal from "../../../components/Modal/DeleteModal";

import EditApprovalAuction from "./components/EditApprovalAuction.js";
import Widget from "../../../components/Widget/index.js";
import Countdown from "react-countdown";
import DetailUserModel from "../DetailUserModel.js";
import AuctionApprovel from "../AuctionApprovel.js";
import ViewNotes from "../../../components/ViewNotes.js";
import { dateFormate } from "../../../utils/commonFunction.js";
import { DataGetAction } from "../../../redux/actions/CommonHttp.js";
import SingleViewApprovalAuction from "../../../components/AuctionDetailModal/SingleViewApprovalAuction.js";
const Index = () => {
  
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const { language } = useSelector((state) => state.Language);
  const targetRef = useRef();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>{language["auction_view_expired"]}</span>;
    } else {
      return (
        <p className="text-primary">
          {days !== 0 ? days : ""}{" "}
          {days === 1 ? "day" : days === 0 ? "" : "days"} {hours} hours
        </p>
      );
    }
  };



  const fetchCategory = ()=>{
    dispatch({
      type:'RestApprovalCounter'
    })
    dispatch(DataGetAction("getCategory", { query: "all" }, "FetchRecord", "categoryList", "categoryLoader"));
   
  }
  useEffect(fetchCategory,[])

  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal
          modalName="View_Pending_Auction_Modal"
          record={record}
          buttonName={`${language["auctions_forApproval_view"]}`}
        />
        <ShowModal
          modalName="Edit_ForApproval_Modal"
          record={record}
          buttonName={language["auctions_forApproval_edit"]}
        />
        <ActiveModal
          title={language["auctions_forApproval_accept_title"]}
          content={language["auctions_forApproval_accept_content"]}
          apiName="updateAuctionStatus"
          method="PUT"
          recordName="forApprovalAuctionList"
          buttonName={language["auctions_forApproval_accept"]}
          selectedItem={{ _id: record._id, status: "live" }}
        />
        <ActiveModal
          title={language["auctions_forApproval_reject_title"]}
          content={language["auctions_forApproval_reject_content"]}
          apiName="updateAuctionStatus"
          method="PUT"
          recordName="forApprovalAuctionList"
          buttonName={language["auctions_forApproval_reject"]}
          selectedItem={{ _id: record._id, status: "reject" }}
        />
        <ShowModal
          modalName="View_Common_Notes"
          record={record}
          buttonName={language["auction_view_note"]}
        />
      </div>
    );
  };

  const columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record.auctionId}</span>,
      key: "Id",
    },
    // {
    //   title: language["auctions_forApproval_title"],
    //   render: (record) => (
    //     <ShowModal
    //       className={"text-blue-500 underline cursor-pointer capitalize"}
    //       modalName="View_Auction_CRUD_Detail"
    //       record={record}
    //     >
    //       {record.title}
    //     </ShowModal>
    //   ),
    //   key: "Title",
    // },
    {
      title: language["auctions_forApproval_category"],
      render: (record) => (
        <span className="capitalize">{record.categoryName}</span>
      ),
      key: "Category",
    },
    {
      title: language["auctions_live_subcategory"],
      render: (record) => (
        <span className="capitalize">{record.subCategoryName}</span>
      ),
      key: "Category",
    },
    {
      title: language["auctions_forApproval_opening_price"],
      render: (record) => <span>{record?.formStructure?.price ?? record.price}</span>,
      key: "Price",
    },
    {
      title: language["auctions_forApproval_duration"],
      render: (record) => (
        <>
          {record.expiredAt ? (
            <Countdown renderer={renderer} date={record.expiredAt} />
          ) : (
            `${record.auctionDuration} days`
          )}
        </>
      ),
      key: "Duration",
    },
    {
      title: language["auctions_forApproval_createdBy"],
      render: (record) => (
        <div className="py-[10px]">
          <ShowModal
            modalName="User_Detail_Model"
            record={record}
            className="font-roboto-bold text-14 capitalize hover:underline text-blue-500"
            buttonName={`${record.firstName} ${record.lastName}`}
          />
          <p className="font-roboto-regular my-0 text-14">{record.email}</p>
          <p className="font-roboto-regular my-0 text-14">
            {record.phoneNumber}
          </p>
        </div>
      ),
      key: "Created By",
    },
    {
      title: language["auctions_forApproval_createdAt"],
      render: (record) => (
        <span>{dateFormate(record.createdAt)}</span>
      ),
      key: "Created At",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
      fixed: "right",
    },
  ];
  const allColumnKeys = [
    "Id",
    "Title",
    "Category",
    "Model Year",
    "Brand",
    "Price",
    "Duration",
    "Created By",
    "Created At",
    "actions",
  ];
  const [selectedColumnKeys, setSelectedColumnKeys] = useState(allColumnKeys);
  const [checkedList, setCheckedList] = useState(allColumnKeys);
  const [tableColumns, setTableColumns] = useState(columns);
  function isChecked(value) {
    return checkedList.includes(value);
  }
  function handleSelection(key) {
    const tempList = [...checkedList];
    if (tempList.includes(key)) {
      const index = tempList.indexOf(key);
      tempList.splice(index, 1);
    } else {
      tempList.push(key);
    }
    setCheckedList(tempList);
  }
  function handleConfirmation() {
    setSelectedColumnKeys(checkedList);
  }
  useEffect(() => {
    if (selectedColumnKeys) {
      if (selectedColumnKeys.length) {
        const temp = columns.filter((f) => selectedColumnKeys.includes(f.key));
        setTableColumns(temp);
      } else {
        setTableColumns(columns);
      }
    }
    return () => {};
  }, [selectedColumnKeys]);
  const checkBoxData = columns.map((m) => ({ key: m.key, title: m.title }));
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <Widget>
      <TableHeader
        targetRef={targetRef}
        handleOpenChange={handleOpenChange}
        hide={hide}
        checkBoxData={checkBoxData}
        open={open}
        isChecked={isChecked}
        handleSelection={handleSelection}
        handleConfirmation={handleConfirmation}
      />
      <div ref={targetRef}>
        <ListTable
          customQuery={{ status: "pending" }}
          apiName="getAuctions"
          recordName="forApprovalAuctionList"
          columns={tableColumns}
        />
      </div>
      <EditApprovalAuction />
     
      <SingleViewApprovalAuction/>
      <DetailUserModel />
      <AuctionApprovel recordName={"forApprovalAuctionList"} />
      <ViewNotes />
    </Widget>
  );
};

export default Index;
