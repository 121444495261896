import React, { useState } from "react";
import { theme } from "../../styles/themeColor";
import { Layout } from "antd";
import UserInfo from "./components/UserInfo";
import CustomMenu from "./components/CustomMenu";
import DrawerSidebar from "./components/DrawerSidebar";
import { HiMenu } from "react-icons/hi";
import LogoImg from "../../assets/images/Logo.png";
// import { useSelector } from "react-redux";
// import { Socket } from "../../socket";
import UNAUTHORIZEDModal from "../Modal/UNAUTHORIZEDModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const { Header, Sider, Content } = Layout;
const Index = ({ children }) => {
  const navigate = useNavigate()
  const { language } = useSelector((state) => state.Language);
  const [collapsed, setCollapsed] = useState(false);
  const [responsive, setResponsive] = useState(false);
  // const { authUser } = useSelector((state) => state.AuthR);

  // useEffect(() => {
  //   if (authUser) {
  //     Socket.getInstance(authUser._id);
  //   }
  // }, []);
  return (
    <Layout className="h-screen w-screen">
      <Sider
        style={{
          display: responsive ? "none" : "block",
          boxShadow: "#00000012 0px 0px 10px 4px",
        }}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          setResponsive(broken);
          setCollapsed(broken);
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="bg-primary-800 h-[80px] mb-3 flex items-center justify-center mt-3">
          <img className="cursor-pointer" onClick={() => navigate('/dashboard')} src={LogoImg} alt="" />
        </div>
        <CustomMenu setCollapsed={setCollapsed} />
      </Sider>
      <Layout className="h-full">
        <Header className="flex items-center min-h-[70px] justify-between">
          <div className="flex items-center gap-2">
            {responsive && (
              <HiMenu size={20} onClick={() => setCollapsed(!collapsed)} />
            )}
            <h2 className="text-primary-800 text-[25px] font-roboto-bold text-[#145262]">
              {language["header_title"]}
            </h2>
          </div>
          <UserInfo />
        </Header>
        <div
          style={{ boxShadow: "inset #00000012 0px 3px 6px 0px" }}
          className="w-full h-[10px] bg-[#F4F5FA]"
        ></div>
        <div className="bg-white overflow-y-auto h-full">
          <Content
            className="h-full overflow-x-hidden overflow-y-auto"
            style={{
              padding: "10px 20px",
              background: theme.customComponent.Content.background,
            }}
          >
            {children}
          </Content>
        </div>
      </Layout>
      {responsive && (
        <DrawerSidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          responsive={responsive}
        />
      )}
      <UNAUTHORIZEDModal />
    </Layout>
  );
};
export default Index;
