import React, { useEffect } from "react";
import Widget from "../../../components/Widget";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import { Select } from "antd";
import moment from "moment";
const AuctionLineChart = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { auctionLineCartList } = useSelector((state) => state.CrudR);

  // const data = [
  //   { name: "Page A", uv: 4000, price: 2400, amt: 2400,ppm:1233,ccm:1233 },
  //   { name: "Page B", uv: 3000, price: 1398, amt: 2210,ppm:133,ccm:133 },
  //   { name: "Page C", uv: 2000, price: 9800, amt: 2290,ppm:1353,ccm:1353 },
  //   { name: "Page D", uv: 2780, price: 3908, amt: 2000,ppm:1333,ccm:1333 },
  //   { name: "Page E", uv: 1890, price: 4800, amt: 2181,ppm:1663,ccm:1663 },
  //   { name: "Page F", uv: 2390, price: 3800, amt: 2500,ppm:1133,ccm:1133 },
  //   { name: "Page G", uv: 3490, price: 4300, amt: 2100,ppm:1873,ccm:1873 },
  // ];
  const callApi = (value) => {
    dispatch(
      DataGetAction(
        "getDashboardAuctionAnalytics",
        { graphType: value },
        "FetchRecord",
        "auctionLineCartList",
        "TableSpinner"
      )
    );
  };

  useEffect(() => {
    callApi("weekly");
  }, []);
  function formatXAxis(tickItem) {
    if (tickItem !== "") {
      if (tickItem && tickItem.length <= 4) {
        return tickItem;
      } else {
        return moment(tickItem).format("MMM-DD");
      }
    } else {
      return "";
    }
  }
  return (
    <>
      {auctionLineCartList && (
        <Widget className="pt-1">
          <div
            className="flex items-center justify-between font-roboto-bold pb-4 mb-4"
            style={{ borderBottom: "1px solid #DBDBDB" }}
          >
            <h4 className="text-primary-text my-0 text-20">
              {language["Auctions_Analysis"]}
            </h4>
            <Select
              defaultValue={"weekly"}
              className="w-[200px]"
              onChange={(e) => callApi(e)}
            >
              <Select.Option value="weekly">{language["Weekly"]}</Select.Option>
              <Select.Option value="lastWeek">{language["LastWeekly"]}</Select.Option>
              <Select.Option value="monthly">
                {language["Monthly"]}
              </Select.Option>
              <Select.Option value="lastMonth">
                {language["LastMonth"]}
              </Select.Option>
              <Select.Option value="yearly">{language["Yearly"]}</Select.Option>
            </Select>
          </div>
          <ResponsiveContainer width="100%" height={250} className={"pb-5"}>
            <LineChart
              data={auctionLineCartList}
              margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
            >
              <XAxis dataKey="name" tickFormatter={formatXAxis} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                name={language["Auction_For_Approval"]}
                type="monotone"
                dataKey="totalApproval"
                stroke="#DD9956"
              />
              <Line
                name={language["Live_Auction"]}
                type="monotone"
                dataKey="totalLive"
                stroke="#00CBFF"
              />
              <Line
                name={language["Blocked_Auction"]}
                type="monotone"
                dataKey="totalBlock"
                stroke="#FF2B2D"
              />
              <Line
                name={language["Rejected_Auction"]}
                type="monotone"
                dataKey="totalReject"
                stroke="#9304FF"
              />
              <Line
                name={language["Closed_Auction"]}
                type="monotone"
                dataKey="totalClosed"
                stroke="#85D55D"
              />
            </LineChart>
          </ResponsiveContainer>
        </Widget>
      )}
    </>
  );
};

export default AuctionLineChart;
