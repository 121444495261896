const overAllPrimaryColor = "#145262";
const ButtonPrimarybg = "#145262";
const TableHeaderColor = "#ffffff";
const TableHeaderBgColor = "#145262";
const TableRowHoverBg = "#F4F4F4";
const SidebarItemSelectedBg = "#FFD164";
const MenuItemColor = '#55818F'
const SidebarItemBgHover = "#F8E8C6";
const ButtonPrimaryTextColor = "#ffffff";
const layoutBackground = "#FB9600";
const containerBackground = "#F4F5FA";
const ButtonPrimaryHover = `${
  ButtonPrimarybg ? ButtonPrimarybg : overAllPrimaryColor
}98`;
export const theme = {
  customComponent: {
    Content: {
      background: containerBackground,
      primaryColor:overAllPrimaryColor
    },
  },
  token: {
    colorPrimary: ButtonPrimarybg,
    fontFamily: "Roboto-Regular",
    fontSize: 16,
  },
  components: {
    Button: {
      colorPrimary: ButtonPrimarybg ? ButtonPrimarybg : overAllPrimaryColor,
      primaryColor: ButtonPrimaryTextColor,
      colorPrimaryHover: ButtonPrimaryHover,
      algorithm: true,
    },
    Input: {
      colorPrimary: overAllPrimaryColor,
      algorithm: true,
    },
    Layout: {
      siderBg: overAllPrimaryColor,
      bodyBg: layoutBackground,
      headerBg: '#F4F5FA',
      headerPadding: "0 24px",
      algorithm: true,
    },
    Menu: {
      algorithm: true,
      itemHoverBg: SidebarItemBgHover,
      itemActiveBg: overAllPrimaryColor,
      itemActiveBg: overAllPrimaryColor,
      itemSelectedBg: overAllPrimaryColor,
      darkItemBg: overAllPrimaryColor,
      darkItemSelectedBg: SidebarItemSelectedBg,
      darkItemColor:'#fff',
      darkItemHoverBg: '#667E63',
      subMenuItemBg: overAllPrimaryColor,
      darkSubMenuItemBg: overAllPrimaryColor,
      darkItemSelectedColor:'#fff',
      darkItemHoverColor: "",
      iconSize: 20,
      itemBorderRadius: "0px 0px",
      itemMarginInline: "0px 0px",
     
    },
    Table: {
      headerBg: TableHeaderBgColor,
      headerColor: TableHeaderColor,
      rowHoverBg: TableRowHoverBg,
      headerBorderRadius: 0,
    },
    Pagination: {
      itemBg: "red",
      itemLinkBg: "red",
      itemActiveColor: "red",
      itemActiveColorDisabled: "red",
      itemInputBg: "red",
    },
  },
};
