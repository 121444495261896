import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { EditFormModal } from "../../../../components/Modal/EditFormModal";
import DetailForm from "./DetailForm";
import UserForm from "./UserForm";

const EditUser = () => {
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const { initialValue, Edit_User_Modal } = useSelector((state) => state.CrudR);
  const [NewPaperState, setNewPaperState] = useState(false);
  const [TermAndConditionState, setTermAndConditionState] = useState(false);
  const [businessCustomer, setBusinessCustomer] = useState(false);
  const [customFee, setCustomFeeCustomer] = useState(false);

  const setInitialValue = () => {
    if (initialValue) {
      console.log("====== customFee ===== initialValue =====", initialValue);
      setCustomFeeCustomer(initialValue.customFee);
      if (initialValue.businessCustomer) {
        setBusinessCustomer(initialValue.businessCustomer);
      }
      if (initialValue.newsLetter) {
        setNewPaperState(initialValue.newsLetter);
      }
      if (initialValue.termsAndCondition) {
        setTermAndConditionState(initialValue.termsAndCondition);
      }
    }
    if (!Edit_User_Modal) {
      form.resetFields();
      setNewPaperState(false);
      setTermAndConditionState(false);
    } else {
      form.setFieldsValue(initialValue);
    }
  };

  useEffect(setInitialValue, [Edit_User_Modal, initialValue]);
  return (
    <EditFormModal
      initialData={initialValue}
      form={form}
      modalTitle={language["user_active_edit_modaltitle"]}
      modalName="Edit_User_Modal"
      apiName="editUser"
      recordName="userList"
      extraObj={{
        newsLetter: NewPaperState,
        termsAndCondition: TermAndConditionState,
      }}
      width={"700px"}
    >
      <UserForm
        customFee={customFee}
        setCustomFeeCustomer={setCustomFeeCustomer}
        form={form}
        setBusinessCustomer={setBusinessCustomer}
        businessCustomer={businessCustomer}
        disabled={true}
      />
    </EditFormModal>
  );
};

export default EditUser;
