import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "antd";
import UserForm from "./UserForm";
import DetailModal from "../../../../components/Modal/DetailModal";

const ViewUser = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [NewPaperState, setNewPaperState] = useState(false);
  const [TermAndConditionState, setTermAndConditionState] = useState(false);
  const { language } = useSelector((state) => state.Language);
  const { initialValue, View_Block_User_Modal } = useSelector(
    (state) => state.CrudR
  );

  useEffect(() => {
    if (initialValue) {
      form.setFieldsValue(initialValue);
      if (initialValue.newsLetter) {
        setNewPaperState(initialValue.newsLetter);
      }
      if (initialValue.termsAndCondition) {
        setTermAndConditionState(initialValue.termsAndCondition);
      }
    }
    if (!View_Block_User_Modal) {
      form.resetFields();
      setNewPaperState(false);
      setTermAndConditionState(false);
    }
  }, [View_Block_User_Modal, initialValue]);
  return (
    <DetailModal
      visible={View_Block_User_Modal}
      modalTitle={language["user_active_view_modaltitle"]}
      modalName="View_Block_User_Modal"
      width={"700px"}
    >
      {initialValue && (
        <Form form={form} layout="vertical" initialValues={initialValue}>
          <div
            className="py-5"
            style={{
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <UserForm
              NewPaperState={NewPaperState}
              TermAndConditionState={TermAndConditionState}
              setNewPaperState={setNewPaperState}
              setTermAndConditionState={setTermAndConditionState}
              formType="edit"
              readOnly={true}
            />
          </div>
          <div className="flex justify-end mt-3">
            <Button
              type="primary"
              onClick={() =>
                dispatch({
                  type: "Hide_Modal",
                  modalName: "View_Block_User_Modal",
                })
              }
            >
              {language["ok"]}
            </Button>
          </div>
        </Form>
      )}
    </DetailModal>
  );
};

export default ViewUser;
