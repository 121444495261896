import React, { useEffect, useState } from "react";
import { Upload, Button, message, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const ImageUpload = ({ data, field_name, form, defaultList }) => {
  const token = localStorage.getItem("token");
  const [defaultImage, setDefaultImage] = useState({});

  console.log("===== initialValue ====", defaultList);

  const handleChange = (info) => {
    if (info.file.status === "done") {
      let fileUrl = {
        uid: info.file.uid,
        name: info.file.name,
        status: "done",
        field_name: field_name,
        defaultImage: false,
        url: info.file.response.url,
      };

      if (info.fileList && info.fileList.length > 0) {
        fileUrl = info.fileList.map((item) => {
          return {
            uid: item.uid,
            name: item.name,
            field_name: field_name,
            status: "done",
            defaultImage: false,
            url: item.url || item.response.url,
          };
        });
      }

      form.setFieldValue(field_name, fileUrl);
    }
    if (info.file.status === "removed") {
      if (info.fileList) {
        form.setFieldValue(field_name, info.fileList);
      }
    }
  };

  const itemRender = (originNode, file) => {
    let field_name = file?.field_name || file?.response?.fieldName || "";

    let isDefaultImage = false;

    if (defaultImage?.[field_name]) {
      if (file?.uid) {
        if (file.uid === defaultImage[field_name]) {
          isDefaultImage = true;
        }
      } else if (file?.response?.uid) {
        if (file?.response?.uid === defaultImage[field_name]) {
          isDefaultImage = true;
        }
      }
    } else {
      if (file.defaultImage) {
        isDefaultImage = true;
      }
    }

    return (
      <div
        className="custom-upload-item"
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "5px",
          alignItems: "center",
          border: "1px solid #cccc",
          borderRadius: "5px",
          paddingRight: "10px",
        }}
      >
        <div className="flex flex-1 w-[75%] border-1 custom-upload-picture">{originNode}</div>
        <Button
          type={isDefaultImage ? "primary" : "default"}
          onClick={() => handleMakeDefault(file)}
          style={{ marginTop: 8, marginRight: 9 }}
        >
          Make Default
        </Button>
      </div>
    );
  };

  const handleMakeDefault = (info) => {
    let field_name = info?.field_name || info?.response?.fieldName || "";

    let filedList = form.getFieldValue(field_name);
    if (filedList && filedList.length > 0) {
      filedList.forEach((item) => {
        console.log("==== item ====", item, info);
        if (info?.response && item.uid === info?.response?.uid) {
          item["defaultImage"] = true;
        } else if (item.uid === info.uid) {
          item["defaultImage"] = true;
        } else {
          item["defaultImage"] = false;
        }
      });

      form.setFieldValue(field_name, [...filedList]);

      setDefaultImage({ ...defaultImage, [field_name]: info.uid });
      message.success(`${info.name} is now the default image.`);
    }
  };

  return (
    <div className="mt-3 mb-3">
      <Form.Item name={data.field_name} label={data.label} rules={[{ required: data.required }]}>
        <Upload
          action={`${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`}
          method="POST"
          listType="picture"
          data={{ fieldName: field_name }}
          headers={{ Authorization: token }}
          defaultFileList={defaultList}
          onChange={handleChange}
          itemRender={itemRender}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item>
    </div>
  );
};

export default ImageUpload;
