import React, { useEffect, useRef, useState } from "react";
import TableHeader from "./components/TableHeader";
import ListTable from "../../../components/Table";
import { useSelector } from "react-redux";
import moment from "moment";
import Action from "../../../components/Action";
import ShowModal from "../../../components/Modal/ShowModal";
import ActiveModal from "../../../components/Modal/DeleteModal";
import ViewUser from "./components/ViewUser";
import ShowAuctions from "./components/ShowAuctions";
import { dateFormate } from "../../../utils/commonFunction";

const Index = () => {
  const [open, setOpen] = useState(false);

  const targetRef = useRef();
  const { language } = useSelector((state) => state.Language);
  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal
          modalName="View_Block_User_Modal"
          record={record}
          buttonName={language["user_block_view"]}
        />
        <ActiveModal
          title={language["user_block_activeModel_title"]}
          content={language["user_block_activeModel_content"]}
          apiName="updateStatus"
          method="PUT"
          recordName="userList"
          buttonName={language["user_block_active_button"]}
          selectedItem={{ _id: record._id, status: "active" }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record.userId}</span>,
      key: "Id",
    },
    {
      title: language["user_block_fullName"],
      render: (record) => (
        <span className="capitalize">{`${record.firstName} ${record.lastName}`}</span>
      ),
      key: "fullName",
    },
    {
      title: language["user_block_email"],
      render: (record) => <span>{record.email}</span>,
      key: "email",
    },
    {
      title: language["user_block_phone"],
      render: (record) => <span>{record.phoneNumber}</span>,
      key: "phone",
    },
    {
      title: language["user_active_noOfAuctions"],
      render: (record) => (record.totalAuctions ? record.totalAuctions : 0),
      key: "noOfAuctions",
    },
    {
      title: language["user_block_createdAt"],
      render: (record) => (
        <span>{dateFormate(record.createdAt)}</span>
      ),
      key: "createdAt",
    },
    {
      title: language["user_block_lastLogin"],
      render: (record) => (
        <span>
          {record.lastLogin
            ? moment(record.lastLogin).format("DD MMM, YYYY")
            : "----"}
        </span>
      ),
      key: "lastLogin",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "actions",
      width: "50px",
    },
  ];
  const allColumnKeys = [
    "Id",
    "fullName",
    "email",
    "phone",
    "noOfAuctions",
    "createdAt",
    "lastLogin",
    "actions",
  ];
  const [selectedColumnKeys, setSelectedColumnKeys] = useState(allColumnKeys);
  const [checkedList, setCheckedList] = useState(allColumnKeys);
  const [tableColumns, setTableColumns] = useState(columns);
  function isChecked(value) {
    return checkedList.includes(value);
  }
  function handleSelection(key) {
    const tempList = [...checkedList];
    if (tempList.includes(key)) {
      const index = tempList.indexOf(key);
      tempList.splice(index, 1);
    } else {
      tempList.push(key);
    }
    setCheckedList(tempList);
  }
  function handleConfirmation() {
    setSelectedColumnKeys(checkedList);
  }
  useEffect(() => {
    if (selectedColumnKeys) {
      if (selectedColumnKeys.length) {
        const temp = columns.filter((f) => selectedColumnKeys.includes(f.key));
        setTableColumns(temp);
      } else {
        setTableColumns(columns);
      }
    }
    return () => {};
  }, [selectedColumnKeys]);
  const checkBoxData = columns.map((m) => ({ key: m.key, title: m.title }));
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <div>
      <TableHeader
        targetRef={targetRef}
        handleOpenChange={handleOpenChange}
        hide={hide}
        checkBoxData={checkBoxData}
        open={open}
        isChecked={isChecked}
        handleSelection={handleSelection}
        handleConfirmation={handleConfirmation}
      />
      <div ref={targetRef}>
        <ListTable
          customQuery={{ status: "block" }}
          apiName="getUsers"
          recordName="userList"
          columns={tableColumns}
        />
      </div>
      <ViewUser />
      <ShowAuctions />
    </div>
  );
};

export default Index;
