import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "antd";
import DetailModal from "../../../../components/Modal/DetailModal";
import ApprovalAuctionForm from "./ApprovalAuctionForm";
import { manualFormReset } from "../../../../utils/commonFunction";

const ViewApprovalAuction = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const [CategoryValue, setCategoryValue] = useState(null);
  const { initialValue, View_Closed_Auction_Modal } = useSelector(
    (state) => state.CrudR
  );

  useEffect(() => {
    if (initialValue) {
      manualFormReset(initialValue, form);
      setCategoryValue(initialValue.categoryId);
    }
  }, [View_Closed_Auction_Modal]);

  return (
    <DetailModal
      width={"1020px"}
      visible={View_Closed_Auction_Modal}
      modalTitle={language["auctions_closed_view_auction"]}
      modalName="View_Closed_Auction_Modal"
    >
      {initialValue && (
        <Form form={form} layout="vertical" initialValues={initialValue}>
          <div
            className="py-5"
            style={{
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <ApprovalAuctionForm
              readOnly={true}
              CategoryValue={CategoryValue}
              setCategoryValue={setCategoryValue}
            />
          </div>
          <div className="flex justify-end mt-3">
            <Button
              type="primary"
              className="font-roboto-medium"
              onClick={() =>
                dispatch({
                  type: "Hide_Modal",
                  modalName: "View_Closed_Auction_Modal",
                })
              }
            >
              {language["ok"]}
            </Button>
          </div>
        </Form>
      )}
    </DetailModal>
  );
};

export default ViewApprovalAuction;
