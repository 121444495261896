import { Col, DatePicker, Form, Input, Radio, Row, Select, Switch } from "antd";
import React, { useEffect } from "react";
import Agriculture from "../../../../assets/images/agriculture.png";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
import CategoryCard from "../../../../components/categoryCard/categoryCard";
const ApprovalAuctionForm = ({
  readOnly = false,
  setCategoryValue,
  CategoryValue,
}) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { categoryList } = useSelector((state) => state.CrudR);

  const onselect = (_id) => {
    setCategoryValue(_id);
  };
  const renderCategory = () => {
    if (categoryList && categoryList.length > 0) {
      return categoryList.map((item) => {
        return (
          <CategoryCard
            onClick={() => {
              if (!readOnly) {
                onselect(item._id);
              }
            }}
            imgUrl={Agriculture}
            background={CategoryValue === item._id ? "#14526270" : ""}
            text={item.name}
          />
        );
      });
    }
  };

  useEffect(() => {
    dispatch(
      DataGetAction("getCategory", "", "FetchRecord", "categoryList", "")
    );
  }, []);

  return (
    <div>
      <div className="flex items-center flex-wrap gap-3">
        {renderCategory()}
      </div>
      <Row className="my-5">
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
          <Form.Item
            name="title"
            label={language["auctions_closed_edit_label_title"]}
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_title"]
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          {/* <Form.Item
            name="modelYear"
            label={language["auctions_closed_edit_label_modelYear"]}
          >
            <DatePicker
              style={{ width: "100%" }}
              picker="year"
              placeholder={
                language["auctions_closed_edit_placeholder_modelYear"]
              }
            />
          </Form.Item> */}
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
          <Form.Item
            name="brand"
            label={language["auctions_closed_edit_label_brand"]}
          >
            <Select
              disabled={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_brand"]
              }
            >
              <Select.Option value="suzaki">Suzaki</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="model"
            label={language["auctions_closed_edit_label_model"]}
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_model"]
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
          <Form.Item
            name="cylinderVolumeInLiter"
            label={language["auctions_closed_edit_label_Cylinder_volume"]}
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language[
                  "auctions_closed_edit_placeholder_cylinder_volume"
                ]
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="HorsePower"
            label={
              language["auctions_closed_edit_label_number_horsepower"]
            }
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language[
                  "auctions_closed_edit_placeholder_number_horsepower"
                ]
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
          <Form.Item
            name="weight"
            label={language["auctions_closed_edit_label_weight_kg"]}
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_weight_kg"]
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="workingHours"
            label={language["auctions_closed_edit_label_working_hourse"]}
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_working_hourse"]
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
          <Form.Item
            name="frontTire"
            label={language["auctions_closed_edit_label_front_tire"]}
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_front_tire"]
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="rearTire"
            label={language["auctions_closed_edit_label_rear_tire"]}
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_rear_tire"]
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
          <Form.Item
            name="videoUrl"
            label={language["auctions_closed_edit_label_video_link"]}
          >
            <Input
              readOnly={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_video_link"]
              }
            />
          </Form.Item>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className="flex items-end gap-2"
        >
          <Form.Item name="taxInclude" className="mb-0">
            <Switch disabled={readOnly} />
          </Form.Item>
          <span className="font-regular text-18 mb-2">
            {language["auctions_closed_edit_label_switch_label"]}
          </span>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item name="auctionDuration" className="mb-0">
            <Radio.Group disabled={readOnly}>
              <Radio value={"threeDays"}>
                3 {language["auctions_closed_edit_label_days"]}
              </Radio>
              <Radio value={"sevenDays"}>
                7 {language["auctions_closed_edit_label_days"]}
              </Radio>
              <Radio value={"tenDays"}>
                10 {language["auctions_closed_edit_label_days"]}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="description"
            label={language["auctions_closed_edit_label_description"]}
          >
            <Input.TextArea
              readOnly={readOnly}
              placeholder={
                language["auctions_closed_edit_placeholder_description"]
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ApprovalAuctionForm;
