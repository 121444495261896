import { Col, Row, Select, Spin, Tag } from "antd";
import React, { useEffect } from "react";
import Widget from "../../components/Widget";
import PieChartComp from "./components/PieChartComp";
import UserLineChart from "./components/UserLineChart";
import AuctionLineChart from "./components/AuctionLineChart";
import Notifications from "./components/Notifications";
import ListTable from "../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import LineChartGraph from "./components/LineChartGraph";
import BarGraph from "./components/BarGraph";

const Index = () => {
  const dispatch = useDispatch();
  const { dashboardUserData, dashboardAuctionData, fetchingSpinner,refreshDashboard,closeAuctionCounter,newAuctions } = useSelector((state) => state.CrudR);
  const { language } = useSelector((state) => state.Language);
  const graphDetail = (text, num, total, color, topMargin = 0) => {
    let percentage = ((parseInt(num) / parseInt(total)) * 100).toFixed(0);

    return (
      <div
        className={`flex items-center justify-between pt-${topMargin}`}
        style={{ borderBottom: "1px dashed #DDE2F0" }}
      >
        <p className="text-[#555556] font-roboto-medium">{text}</p>
        <div className="flex items-center gap-2">
          <p className="font-roboto-bold font-roboto-medium">{num}</p>
          <Tag color={color}>{percentage}%</Tag>
        </div>
      </div>
    );
  };
  const callApi = () => {
    dispatch(DataGetAction("getDashboardUserOverView", "", "FetchRecord", "dashboardUserData", "TableSpinner"));
    dispatch(DataGetAction("getDashboardAuctionOverView", "", "FetchRecord", "dashboardAuctionData", "TableSpinner"));
  };

  useEffect(callApi, [refreshDashboard,closeAuctionCounter,newAuctions]);

  const renderUserProgressBarDetail = () => {
    if (dashboardUserData) {
      const { totalUser, totalActiveUser, totalBlockUser, totalUnVerifiedUser } = dashboardUserData;
      let activeUserPercentage = ((parseInt(totalActiveUser) / parseInt(totalUser)) * 100).toFixed(2);

      let unVerifiedUserPercentage = ((parseInt(totalUnVerifiedUser) / parseInt(totalUser)) * 100).toFixed(2);

      return (
        <Widget className="pt-1">
          <div className="flex items-center justify-between pb-4" style={{ borderBottom: "1px solid #DDE2F0" }}>
            <h4 className="text-primary-text my-0 text-20 font-roboto-bold">{language["Users_dashboard"]}</h4>
            <p className="my-0 font-roboto-bold">{totalUser}</p>
          </div>
          <div className="mt-10 pb-5" style={{ borderBottom: "1px dashed #DDE2F0" }}>
            <p className="text-14 font-roboto-regular">
              {language["Active_Users_dashboard"]} | {totalActiveUser}
            </p>
            <div className="flex items-center gap-2">
              <div
                style={{
                  width: `${activeUserPercentage}%`,
                }}
                className="rounded-full h-[5px] bg-[#52C41A]"
              ></div>
              <span>{activeUserPercentage}%</span>
            </div>
          </div>
          <div className="mb-10 pb-5" style={{ borderBottom: "1px dashed #DDE2F0" }}>
            <p className="text-14 font-roboto-regular">
              {language["unverified_Users_dashboard"]} | {totalUnVerifiedUser}
            </p>
            <div className="flex items-center gap-2">
              <div
                style={{
                  width: `${unVerifiedUserPercentage}%`,
                }}
                className="rounded-full h-[5px] bg-[#DA8F44]"
              ></div>
              <span>{unVerifiedUserPercentage}%</span>
            </div>
          </div>
        </Widget>
      );
    }
  };

  const renderAuctionDetail = () => {
    return (
      <Widget className="pt-1">
        <div className="flex items-center justify-between pb-4 " style={{ borderBottom: "1px solid #DBDBDB" }}>
          <h4 className="text-primary-text my-0 text-20 font-roboto-bold">{language["action_title_dashboard"]}</h4>
          <p className="my-0 font-roboto-bold">{dashboardAuctionData && dashboardAuctionData.totalAuctions}</p>
        </div>
        <div className="md:flex justify-between">
          <PieChartComp data={dashboardAuctionData && dashboardAuctionData.graphData} />
          {dashboardAuctionData && (
            <div className="md:w-[55%] w-full">
              {graphDetail(
                "Auction For Approval",
                `${dashboardAuctionData.totalAuctionForApproval}`,
                `${dashboardAuctionData.totalAuctions}`,
                "yellow",
                5
              )}
              {graphDetail(
                "Live Auction",
                `${dashboardAuctionData.totalLiveAuctions}`,
                `${dashboardAuctionData.totalAuctions}`,
                "blue"
              )}
              {graphDetail(
                "Closed Auction",
                `${dashboardAuctionData.totalClosedAuctions}`,
                `${dashboardAuctionData.totalAuctions}`,
                "pink"
              )}
            </div>
          )}
        </div>
      </Widget>
    );
  };

  let columns1 = [
    { title: language["ID"], render: (record) => <span></span>, key: "" },
    { title: language["Title"], render: (record) => <span></span>, key: "" },
    {
      title: language["Opening_Price"],
      render: (record) => <span></span>,
      key: "",
    },
    { title: language["Duration"], render: (record) => <span></span>, key: "" },
    {
      title: language["Created_By"],
      render: (record) => <span></span>,
      key: "",
    },
    {
      title: language["Created_At"],
      render: (record) => <span></span>,
      key: "",
    },
  ];
  let columns2 = [
    { title: language["ID"], render: (record) => <span></span>, key: "" },
    {
      title: language["Full_Name"],
      render: (record) => <span></span>,
      key: "",
    },
    {
      title: language["No._Of_Auction"],
      render: (record) => <span></span>,
      key: "",
    },
  ];
  return (
    <div>
      {fetchingSpinner && (
        <div className="w-full h-[200px] flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}

      <Row gutter={[20, 20]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="mb-3">
            <LineChartGraph
              titleKey="new_auctions_graph_title"
              apiName="getNewAuctionAnalytics"
              recordName="newAuctionData"
              graphDataKey="totalAuction"
              toolTipLabel="New Auctions"
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="mb-3">
            <LineChartGraph
              titleKey="live_auctions_graph_title"
              apiName="getLiveAuctionAnalytics"
              recordName="liveAuctionData"
              graphDataKey="totalAuction"
              toolTipLabel="Live Auctions"
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="mb-3">
            <BarGraph
              titleKey="incoming_bids_title"
              apiName="getBidAnalytics"
              recordName="bidData"
              graphDataKey="totalBid"
              toolTipLabel="Total Bid"
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="mb-3">
            <LineChartGraph
              titleKey="user_visitor_data"
              apiName="getVisitorAndUserAnalytics"
              recordName="visitorUserData"
              graphDataKey="user"
              toolTipLabel="Visitor"
              showTwoLine={true}
              SecondLineKey="visitor"
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="mb-3">
            <BarGraph
              titleKey="Finance_graph_title"
              apiName="getFinanceAnalytics"
              recordName="financeData"
              graphDataKey="sok"
              toolTipLabel="Sok"
              showTwoLine={true}
              SecondLineKey="open"
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        {dashboardUserData && (
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            {renderUserProgressBarDetail()}
          </Col>
        )}
        {dashboardAuctionData && (
          <Col xl={16} lg={16} md={16} sm={24} xs={24}>
            {renderAuctionDetail()}
          </Col>
        )}
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <div className="mb-3">
            <UserLineChart />
          </div>
          <div>
            <AuctionLineChart />
          </div>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Notifications />
        </Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Widget>
            <div className="flex items-center justify-between pb-4 mb-4" style={{ borderBottom: "1px solid #DBDBDB" }}>
              <h4 className="text-primary-text my-0 text-20 font-roboto-bold">{language["Auction_For_Approval"]}</h4>
            </div>
            <ListTable
              showScroll={false}
              apiName=""
              recordName="latestAuctionForApprovalList"
              columns={columns1}
              callApi={false}
            />
          </Widget>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Widget>
            <div className="flex items-center justify-between pb-4 mb-4" style={{ borderBottom: "1px solid #DBDBDB" }}>
              <h4 className="text-primary-text my-0 text-20 font-roboto-bold">{language["List_of_users"]}</h4>
              <Select defaultValue={"weekly"} className="w-[200px]" onChange={(e) => console.log(e)}>
                <Select.Option value="today">{language["today"]}</Select.Option>
                <Select.Option value="yesterday">{language["yesterday"]}</Select.Option>
                <Select.Option value="weekly">{language["Weekly"]}</Select.Option>
                <Select.Option value="lastWeek">{language["lastWeek"]}</Select.Option>
                <Select.Option value="monthly">{language["Monthly"]}</Select.Option>
                <Select.Option value="lastMonth">{language["lastMonth"]}</Select.Option>
                <Select.Option value="yearly">{language["Yearly"]}</Select.Option>
                <Select.Option value="lastYear">{language["lastYear"]}</Select.Option>
              </Select>
            </div>
            <ListTable showScroll={false} callApi={false} recordName="regularUserList" columns={columns2} />
          </Widget>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
