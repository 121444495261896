import React from "react";
import { useDispatch } from "react-redux";
const ShowModal = ({ record, type = "Show_Modal", modalName, className, buttonName, children }) => {
  const dispatch = useDispatch();
  console.log("====== == getTaxValues = ===record", modalName, record);
  return (
    <span
      onClick={() =>
        dispatch({
          type: type,
          payload: record,
          modalName: modalName,
        })
      }
      className={`cursor-pointer font-roboto-medium ${className}`}
    >
      {buttonName}
      {children}
    </span>
  );
};

export default ShowModal;
