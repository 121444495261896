import React, { useEffect } from "react";
import AddFormModal from "../../../components/Modal/AddFormModal";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import SubCategoryForm from "./SubCategoryForm";
import { DataGetAction } from "../../../redux/actions/CommonHttp";

const AddSubCategory = () => {
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  
  
  return (
    <AddFormModal
      modalTitle={language["sub_category_add_title"]}
      modalName="Add_Category_Modal"
      apiName="addSubCategory"
      recordName="subCategoryList"
      addButtonName={`+ ${language["sub_category_add_title"]}`}
      form={form}
      width={"700px"}
    >
      <SubCategoryForm />
    </AddFormModal>
  );
};

export default AddSubCategory;
