import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router";
const UNAUTHORIZEDModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authProps = useSelector((state) => state.AuthR);
  const { show_UnAuthorized } = authProps;
  console.log("======= show_UnAuthorized ========", show_UnAuthorized);
  const logout = () => {
    dispatch({ type: "Logout" });
    localStorage.clear();
    navigate("/login");
  };
  return (
    <Modal closeIcon={false} closable={false} open={show_UnAuthorized} footer={false} centered>
      <div>
        <h1 className="text-center text-[46px] font-roboto-bold">Session Expire</h1>
        <p className="text-center text-[20px] font-roboto-bold">
          Your session has expired. Please login again to continue
        </p>
      </div>
      <div className="flex items-center justify-center">
        <Button
          style={{ height: "54px" }}
          className="text-[18px] font-roboto-bold bg-[#f55240] hover:bg-[#f55240] text-white hover:text-white"
          onClick={() => {
            logout();
            dispatch({ type: "Hide_UN_AUTHORIZED" });
          }}
        >
          Logout
        </Button>
      </div>
    </Modal>
  );
};

export default UNAUTHORIZEDModal;
