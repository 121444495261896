import React, { useEffect } from "react";
import DetailModal from "../../../../components/Modal/DetailModal";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../../components/Table";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
import moment from "moment";
import Countdown from "react-countdown";

const ShowAuctions = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { initialValue, View_Block_User_Auction } = useSelector(
    (state) => state.CrudR
  );

  useEffect(() => {
    if (initialValue) {
      dispatch(
        DataGetAction(
          "getAuctionByUserId",
          { userId: initialValue._id },
          "FetchRecord",
          "userDetailList"
        )
      );
    }
  }, [View_Block_User_Auction]);
  const renderStatus = (status) => {
    if (status === "complete") {
      return <span className="text-green-600 text-[14px] font-roboto-regular">{status}</span>;
    }
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span className="font-roboto-regular">Expired</span>;
    } else {
      return (
        <p className="text-primary font-roboto-regular">
          {days !== 0 ? days : ""}{" "}
          {days === 1 ? "day" : days === 0 ? "" : "day"} {hours} hours
        </p>
      );
    }
  };
  let columns = [
    {
      title: language["user_id"],
      render: (record) => <span>{record.auctionId}</span>,
      key: "userId",
    },
    {
      title: language["user_auction_title"],
      render: (record) => <span>{record.title}</span>,
      key: "title",
    },
    {
      title: language["user_auction_category"],
      render: (record) => (
        <span className="capitalize">{record.categoryName}</span>
      ),
      key: "category",
    },
    {
      title: language["user_auction_modelYear"],
      render: (record) => (
        <span>{moment(record.modelYear).format("YYYY")}</span>
      ),
      key: "modelYear",
    },
    {
      title: language["user_auction_brand"],
      render: (record) => <span>{record.brand}</span>,
      key: "brand",
    },
    {
      title: language["user_auction_opeaning_price"],
      render: (record) => <span>{record.price}</span>,
      key: "openingPrice",
    },
    {
      title: language["user_auction_duration"],
      render: (record) =><> {record.expiredAt ? <Countdown renderer={renderer} date={record.expiredAt} />:"---"}</>,
      key: "duration",
    },
    {
      title: language["user_auction_createdAt"],
      render: (record) => <span>{moment(record.createdAt).format('DD-MMM-YYYY')}</span>,
      key: "createdAt",
    },
    {
      title: language["user_auction_status"],
      render: (record) => (
        <span className="capitalize">{renderStatus(record.status)}</span>
      ),
      key: "status",
    },
  ];

  return (
    <DetailModal
      width={"800px"}
      modalName="View_Block_User_Auction"
      visible={View_Block_User_Auction}
      modalTitle={
        <span className="capitalize">{`${language["user_Auction_detail"]}: ${
          initialValue && initialValue.firstName
        } ${initialValue && initialValue.lastName}`}</span>
      }
    >
      {initialValue && (
        <ListTable
          columns={columns}
          callApi={false}
          recordName={"userDetailList"}
          customQuery={{ userId: initialValue._id }}
        />
      )}
    </DetailModal>
  );
};

export default ShowAuctions;
