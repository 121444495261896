import React, { useEffect } from "react";
import DetailModal from "../../../../components/Modal/DetailModal";
import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../../redux/actions/CommonHttp";

const ChangeStatus = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { initialValue, Change_Status_Modal } = useSelector(
    (state) => state.CrudR
  );
  const onFinish = (values) => {
    if (initialValue) {
      dispatch(
        DataRequestAction(
          "PUT",
          "updateProcessAuctionStatus",
          { _id: initialValue._id, ...values },
          "EditRecord",
          "progressAuction",
          "EditFormModalSpin",
          "Change_Status_Modal"
        )
      );
    }
  };

  useEffect(() => {
    if (!Change_Status_Modal) {
      form.resetFields();
    }
  }, [Change_Status_Modal]);

  return (
    <DetailModal
      visible={Change_Status_Modal}
      modalName={"Change_Status_Modal"}
      modalTitle={"Change Status"}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="status"
          rules={[{ required: true }]}
          label="Change Status"
        >
          <Select>
            <Select.Option value="invoiceSent">Send Invoice</Select.Option>
            <Select.Option value="invoicePaid">Invoice Paid</Select.Option>
            <Select.Option value="readyToDeliver">
              Ready To Deliver
            </Select.Option>
            <Select.Option value="dealClosed">Deal Closed</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="message" rules={[{ required: true }]} label="Message">
          <Input.TextArea rows={4} />
        </Form.Item>
        <div
          style={{ borderTop: "1px solid #C1CDE2" }}
          className="pt-3 flex justify-end items-center gap-3"
        >
          <Button className="font-roboto-medium">Cancel</Button>
          <Button htmlType="submit" className="bg-primary-text font-roboto-medium" type="primary">
            Update
          </Button>
        </div>
      </Form>
    </DetailModal>
  );
};

export default ChangeStatus;
