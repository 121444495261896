import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { EditFormModal } from "../../../components/Modal/EditFormModal";
import SubCategoryForm from "./SubCategoryForm";
import { DataGetAction } from "../../../redux/actions/CommonHttp";

const EditSubCategory = () => {
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const { initialValue, Edit_Subcategory_Modal } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (!Edit_Subcategory_Modal) {
      form.resetFields();
    }
  }, [Edit_Subcategory_Modal, initialValue]);

  const getImageName = () => {
    if (initialValue) {
      const lastIndex = initialValue.imageUrl.lastIndexOf("/");
      const imageName = initialValue.imageUrl.substring(lastIndex + 1);
      return imageName;
    }
  };

  let defaultFileList = [];

  return (
    <EditFormModal
      initialData={initialValue}
      form={form}
      modalTitle={language["subcategory_edit_title"]}
      modalName="Edit_Subcategory_Modal"
      apiName="editSubCategory"
      recordName="subCategoryList"
      width={"700px"}
    >
      <SubCategoryForm
        defaultFileList={
          initialValue && initialValue.imageUrl
            ? [
                {
                  uid: "1",
                  name: getImageName(),
                  status: "done",
                  url: initialValue && initialValue.imageUrl,
                },
              ]
            : defaultFileList
        }
      />
    </EditFormModal>
  );
};

export default EditSubCategory;
