import React, { useEffect, useState } from "react";
import DetailModal from "../../../components/Modal/DetailModal";
import { Button, Form, Input, InputNumber, Space, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";
const AddVATFee = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { Add_VAT_Modal, updateSettingSpin, initialValue } = useSelector((state) => state.CrudR);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values["_id"] = initialValue && initialValue._id;
    dispatch(DataRequestAction("PUT", "updateSetting", values, "updateSetting", "updateSettingSpin", ""));
  };
  // useEffect(() => {
  //   if (!Add_VAT_Modal) {
  //     form.resetFields();
  //   }
  // }, [Add_VAT_Modal]);

  return (
    <DetailModal width={'800px'} modalName="Add_VAT_Modal" modalTitle={language["Add_VAT_Fee"]} visible={Add_VAT_Modal}>
      <hr className="text-[#D9D9D9] mt-[-2px] mb-4" />

      {!initialValue && (
        <Form onFinish={onFinish} form={form} initialValues={initialValue} layout="vertical">
          <Form.List name="vatFee">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "min"]}
                      rules={[
                        {
                          required: true,
                          message: language["missing_min_value"],
                        },
                      ]}
                    >
                      <InputNumber className="w-full" placeholder={language["enter_min_value"]} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "max"]}
                      dependencies={[name, "min"]}
                      rules={[
                        {
                          required: true,
                          message: language["missing_max_value"],
                        },
                        // ({ getFieldValue }) => ({
                        //   validator(_, value) {
                        //     if (!value || getFieldValue([name, "min"]) < value) {
                        //       return Promise.resolve();
                        //     }
                        //     return Promise.reject(
                        //       new Error("Enter grater value")
                        //     );
                        //   },
                        // }),
                      ]}
                    >
                      <InputNumber className="w-full" placeholder={language["enter_max_value"]} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "tax"]}
                      rules={[
                        {
                          required: true,
                          message: language["missing_tax_value"],
                        },
                      ]}
                    >
                      <InputNumber className="w-full" placeholder={language["enter_tax_value"]} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "type"]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        defaultValue="percentage"
                        style={{
                          width: 120,
                        }}
                        options={[
                          {
                            value: "percentage",
                            label: "Percentage",
                          },
                          {
                            value: "fixed",
                            label: "Fixed",
                          },
                        ]}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    className="font-roboto-medium"
                  >
                    {language["Add_field"]}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div className="mt-3 flex justify-end">
            <Button
              loading={updateSettingSpin}
              disabled={updateSettingSpin}
              type="primary"
              className="font-roboto-medium"
              htmlType="submit"
            >
              {language["submit"]}
            </Button>
          </div>
        </Form>
      )}
      {initialValue && (
        <Form onFinish={onFinish} form={form} initialValues={initialValue} layout="vertical">
          <Form.List name="vatFee">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      label="Min"
                      name={[name, "min"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing min value",
                        },
                      ]}
                    >
                      <InputNumber className="w-full" placeholder="Min Value" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Max"
                      name={[name, "max"]}
                      dependencies={[name, "min"]}
                      rules={[
                        {
                          required: true,
                          message: language["missing_max_value"],
                        },
                      ]}
                    >
                      <InputNumber className="w-full" placeholder={language["enter_max_value"]} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Commission"
                      name={[name, "tax"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing tax value",
                        },
                      ]}
                    >
                      <InputNumber className="w-full" placeholder="Tax Value" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Type"
                      name={[name, "feeType"]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            value: "percentage",
                            label: "Percentage",
                          },
                          {
                            value: "fixed",
                            label: "Fixed",
                          },
                        ]}
                        style={{
                          width: "200px",
                        }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    className="font-roboto-medium"
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div className="mt-3 flex justify-end">
            <Button
              loading={updateSettingSpin}
              disabled={updateSettingSpin}
              type="primary"
              className="font-roboto-medium"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </DetailModal>
  );
};

export default AddVATFee;
