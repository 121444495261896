import React from "react";
import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const ProfileImageUpload = ({ name, setPhotoState, photoList }) => {
  const token = localStorage.getItem("token");
  const { loading, imageUrl } = photoList;
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setPhotoState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      console.log("====info", info);
      setPhotoState({
        imageUrl: info.file.response.url,
        loading: false,
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const ImageProps = {
    action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: { name },
    onChange: handleChange,
    className: "avatar-uploader",
    multiple: false,
    listType: "picture-card",
    showUploadList: false,
  };

  return (
    <Upload
      style={{
        width: "200px !important",
        height: "200px !important",
        backgroundColor: "white !important",
        border: "none !important",
      }}
      {...ImageProps}
    >
      {imageUrl ? (
        <div className="gx-d-flex gx-align-items-center gx-justify-content-center">
          <img
            style={{
              borderRadius: "10px",
              objectFit: "contain",
              border: "0.3px solid #FAF1C5",
            }}
            src={imageUrl}
            alt="avatar"
            width="150px"
            height="150px"
          />
        </div>
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default ProfileImageUpload;
