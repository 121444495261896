import React, { useEffect } from "react";
import Widget from "../../../components/Widget";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import moment from "moment";
const LineChartGraph = ({ titleKey, apiName, recordName, graphDataKey, toolTipLabel, isFormateDate = false, showTwoLine = false, SecondLineKey }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const fetchProps = useSelector((state) => state.CrudR);
  const data = fetchProps[recordName];
  const callApi = (value) => {
    dispatch(DataGetAction(apiName, { graphType: value }, "FetchRecord", recordName, "TableSpinner"));
  };

  useEffect(() => {
    callApi("weekly");
  }, []);
  function formatXAxis(tickItem) {
    if (isFormateDate) {
      if (tickItem !== "") {
        if (tickItem && tickItem.length <= 4) {
          return tickItem;
        } else {
          return moment(tickItem).format("MMM-DD");
        }
      } else {
        return "";
      }
    } else {
      return tickItem;
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${toolTipLabel} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {fetchProps && fetchProps[recordName] && (
        <Widget className="pt-1">
          <div
            className="flex items-center justify-between font-roboto-bold pb-4 mb-4"
            style={{ borderBottom: "1px solid #DBDBDB" }}
          >
            <h4 className="text-primary-text my-0 text-20">{language[titleKey]}</h4>
            <Select defaultValue={"weekly"} className="w-[200px]" onChange={(e) => callApi(e)}>
              <Select.Option value="today">{language["today"]}</Select.Option>
              <Select.Option value="yesterday">{language["yesterday"]}</Select.Option>
              <Select.Option value="weekly">{language["Weekly"]}</Select.Option>
              <Select.Option value="lastWeek">{language["lastWeek"]}</Select.Option>
              <Select.Option value="monthly">{language["Monthly"]}</Select.Option>
              <Select.Option value="lastMonth">{language["lastMonth"]}</Select.Option>
              <Select.Option value="yearly">{language["Yearly"]}</Select.Option>
              <Select.Option value="lastYear">{language["lastYear"]}</Select.Option>
            </Select>
          </div>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={fetchProps[recordName]} margin={{ top: 10, right: 0, left: -15, bottom: 0 }}>
              <XAxis
                minTickGap={0}
                interval={0}
                dataKey="name"
                tickFormatter={formatXAxis}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Line type="monotone" dataKey={`${graphDataKey}`} stroke="#8884d8" fill="#8884d8" />
              {showTwoLine && <Line type="monotone" dataKey={`${SecondLineKey}`} stroke="#FEC16E" fill="#FEC16E" />}
            </LineChart>
          </ResponsiveContainer>
        </Widget>
      )}
    </>
  );
};

export default LineChartGraph;
