import React from "react";
import DetailModal from "../../../../components/Modal/DetailModal";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../../redux/actions/CommonHttp";
import { Button, Carousel } from "antd";

const AuctionApprovel = ({ recordName }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.Language);
  const { View_Auction_Detail_initialValue, View_Auction_Detail } = useSelector(
    (state) => state.CrudR
  );

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const changeStatus = (status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "",
        { _id: View_Auction_Detail_initialValue._id, status: status },
        "EditRecord",
        recordName
      )
    );
  };

  return (
    <DetailModal
      width={"800px"}
      modalName="View_Auction_Detail"
      visible={View_Auction_Detail}
      modalTitle={language["Detail_Auction"]}
      type="View_Auction_Detail_Hide"
    >
      <Carousel autoplay>
        <div>
          <h3 style={contentStyle}>1</h3>
        </div>
        <div>
          <h3 style={contentStyle}>2</h3>
        </div>
        <div>
          <h3 style={contentStyle}>3</h3>
        </div>
        <div>
          <h3 style={contentStyle}>4</h3>
        </div>
      </Carousel>
      <hr />
      <h4>{language["title_description"]}</h4>
      <p>
        {(View_Auction_Detail_initialValue &&
          View_Auction_Detail_initialValue.description) ||
          language["Description_NO_found"]}
      </p>
      <hr />
      <div className="flex justify-end items-center gap-2">
        <Button
          onClick={() =>
            dispatch({
              type: "View_Auction_Detail_Hide",
              modalName: "View_Auction_Detail",
            })
          }
        >
          {language["button_Cancel"]}
        </Button>
        {View_Auction_Detail_initialValue &&
          View_Auction_Detail_initialValue.status === "pending" && (
            <>
              <Button onClick={() => changeStatus("")} type="primary">
                {language["button_Accept"]}
              </Button>
              <Button
                onClick={() => changeStatus("")}
                className="bg-red-500 text-white"
              >
                {language["button_Reject"]}
              </Button>
            </>
          )}
      </div>
    </DetailModal>
  );
};

export default AuctionApprovel;
