import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "antd";
import UserForm from "./ViewUserForm";
import DetailModal from "../../../../components/Modal/DetailModal";

const ViewUser = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { language } = useSelector((state) => state.Language);
  const [NewPaperState, setNewPaperState] = useState(false);
  const [TermAndConditionState, setTermAndConditionState] = useState(false);
  const [businessCustomer, setBusinessCustomer] = useState(false);
  const [customFee, setCustomFeeCustomer] = useState(false);
  const { initialValue, View_Active_User_Modal } = useSelector(
    (state) => state.CrudR
  );

  const setInitialValue = () => {
    if (initialValue) {
      console.log("====== customFee ===== initialValue =====", initialValue);
      setCustomFeeCustomer(initialValue.customFee);
      if (initialValue.businessCustomer) {
        setBusinessCustomer(initialValue.businessCustomer);
      }
      if (initialValue.newsLetter) {
        setNewPaperState(initialValue.newsLetter);
      }
      if (initialValue.termsAndCondition) {
        setTermAndConditionState(initialValue.termsAndCondition);
      }
    }
    if (!View_Active_User_Modal) {
      form.resetFields();
      setNewPaperState(false);
      setTermAndConditionState(false);
    } else {
      form.setFieldsValue(initialValue);
    }
  };

  useEffect(setInitialValue, [View_Active_User_Modal, initialValue]);
  return (
    <DetailModal
      visible={View_Active_User_Modal}
      modalTitle={language["user_active_view_modaltitle"]}
      modalName="View_Active_User_Modal"
      width={"700px"}
    >
      {initialValue && (
        <Form form={form}  layout="vertical" initialValues={initialValue}>
          <div
            className="py-5"
            style={{
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
            }}
          >
            <UserForm
              customFee={customFee}
              setCustomFeeCustomer={setCustomFeeCustomer}
              form={form}
              setBusinessCustomer={setBusinessCustomer}
              businessCustomer={businessCustomer}
              
            />
          </div>
        </Form>
      )}
      <div className="flex justify-end mt-3">
        <Button
          type="primary"
          onClick={() =>
            dispatch({
              type: "Hide_Modal",
              modalName: "View_Active_User_Modal",
            })
          }
        >
          {language["ok"]}
        </Button>
      </div>
    </DetailModal>
  );
};

export default ViewUser;
